import { BoardDimensions } from '../common/BoardDimensions';
import { Piece } from '../common/Piece';
import { PieceColor } from '../common/PieceColor';
import { PieceType } from '../common/PieceType';
import { Square } from '../common/Square';
import { CastlingRights } from '../position/CastlingRights';
import { CastlingSide } from '../position/CastlingSide';
import { IdentifiablePiece } from '../position/IdentifiablePiece';
import { Position } from '../position/Position';

import { BasicMove } from './BasicMove';
import { MoveDbKey } from './MoveDbKey';
import { MoveId } from './MoveId';
import { Movement } from './Movement';
import { MoveNotation } from './MoveNotation';
import {
  MoveSegment,
  MoveSegmentAdjustCastlingRights,
  MoveSegmentAdjustEnPassantTargetSquare,
  MoveSegmentCapture,
  MoveSegmentMovement,
  MoveSegmentPromotion,
} from './MoveSegment';
import { PawnPromotion } from './PawnPromotion';

export class RenderedMove {
  constructor(
    public readonly position: Position,
    public readonly basicMove: BasicMove,
    public readonly isCapture: boolean,
    public readonly isEnPassantCapture: boolean,
    public readonly castlingSide: CastlingSide | null
  ) {}

  //region BASICS
  /** ID. */
  get id(): MoveId {
    return this.dbKey;
  }

  /** Checks equality of two `RenderedMove`s.
   *  Two `RenderedMove`s are considered equal if they have the same properties. */
  equals(other: RenderedMove | null): boolean {
    return (
      !!other &&
      other.position.equals(this.position, true) &&
      other.basicMove.equals(this.basicMove)
    );
  }

  /** Returns a copy of this `RenderedMove`. */
  clone(): RenderedMove {
    return new RenderedMove(
      this.position.clone(),
      new BasicMove(new Movement(this.from, this.to), this.pawnPromotion),
      this.isCapture,
      this.isEnPassantCapture,
      this.castlingSide
    );
  }
  //endregion

  //region CONVENIENCE GETTERS
  /** Underlying Piece movement.
   *  Note: For castles the King's movement is relevant. */
  get movement(): Movement {
    return this.basicMove.movement;
  }

  /** Old square.
   *  Note: For castles the King's movement is relevant. */
  get from(): Square {
    return this.basicMove.from;
  }

  /** New square.
   *  Note: For castles the King's movement is relevant. */
  get to(): Square {
    return this.basicMove.to;
  }

  /** Moving `Piece`.
   *  Note: For castles the King's movement is relevant. */
  get piece(): IdentifiablePiece {
    const piece = this.position.board.get(this.from);
    if (!piece) {
      // This should never happen because the move is rendered.
      throw new Error(
        `No piece at square '${this.from.algebraic}' in position '${this.position.getFen().long}'.`
      );
    }
    return piece;
  }

  /** Pawn promotion (if applicable). */
  get pawnPromotion(): PawnPromotion | null {
    return this.basicMove.pawnPromotion;
  }

  /** Indicates if the Move checks the opponent's King. */
  get isCheck(): boolean {
    return this.position.executed(this).isInCheck();
  }

  /** Indicates if the Move checkmates the opponent's King. */
  get isCheckmate(): boolean {
    return this.position.executed(this).isCheckmate();
  }

  /** Resulting position after the Move has been played. */
  get nextPosition(): Position {
    return this.position.executed(this);
  }
  //endregion

  //region NOTATION
  notation(): MoveNotation {
    const notation = MoveNotation.ifValid(
      this.piece.type,
      this.castlingSide ? null : this.from,
      this.castlingSide ? null : this.to,
      this.isCapture,
      this.isEnPassantCapture,
      this.castlingSide,
      this.pawnPromotion,
      this.isCheck,
      this.isCheckmate
    );
    if (!notation) {
      throw new Error(`Invalid move notation.`);
    }
    return notation;
  }
  //endregion

  get dbKey(): MoveDbKey {
    return new MoveDbKey(this.position.dbKey, this.nextPosition.dbKey);
  }

  /**
   * Returns the `MoveSegments`, i.e. the atomic changes to the position when this Move is executed.
   * Note: The array is ordered, e.g. for a capture, the captured Piece is first captured and then the capturing Piece is being moved.
   */
  segments(): MoveSegment[] {
    const getSegmentsForStandardMove = (): MoveSegment[] => {
      const isDoublePawnPush = (): boolean => {
        return (
          this.piece.type === PieceType.pawn &&
          this.from.rank === this.piece.color.player.pawnRank &&
          (this.to.rank === BoardDimensions.ranks.four ||
            this.to.rank === BoardDimensions.ranks.five)
        );
      };

      const segments: MoveSegment[] = [];

      // A. CAPTURE
      if (this.isCapture) {
        if (this.isEnPassantCapture) {
          // EN PASSANT CAPTURE
          // Remove the Pawn which has been captured en passant
          const enPassantTargetSquare = Square.ifValid(this.to.file, this.from.rank);
          if (!enPassantTargetSquare) {
            throw new Error(`Invalid square coordinates: '${this.to.file}x${this.to.rank}'.`);
          }
          const capturedPiece = this.position.board.get(enPassantTargetSquare);
          if (!capturedPiece) {
            throw new Error(`No piece at ${enPassantTargetSquare.algebraic}.`);
          }

          segments.push(new MoveSegmentCapture(capturedPiece, enPassantTargetSquare));
        } else {
          // STANDARD CAPTURE
          // Remove the captured Piece
          const capturedPiece = this.position.board.get(this.to);
          if (!capturedPiece) throw new Error('should not happen');

          segments.push(new MoveSegmentCapture(capturedPiece, this.to));
        }
      }

      // B. PIECE MOVEMENT
      segments.push(new MoveSegmentMovement(this.from, this.to));

      // C. PAWN PROMOTION
      if (this.pawnPromotion) {
        const promotingPawn = this.position.board.get(this.from);
        if (!promotingPawn) throw new Error('should not happen');

        const promotedPiece = IdentifiablePiece.track(
          Piece.from(this.position.sideToMove.pieceColor, this.pawnPromotion.pieceType),
          this.to
        );
        segments.push(new MoveSegmentPromotion(promotingPawn, this.to, this.from, promotedPiece));
      }

      // D. ADJUST CASTLING RIGHTS
      const newCastlingRights = this.position.castlingRights
        .revokedBecauseOfActivityOnSquare(this.from)
        .revokedBecauseOfActivityOnSquare(this.to);
      if (!newCastlingRights.equals(this.position.castlingRights)) {
        segments.push(
          new MoveSegmentAdjustCastlingRights(this.position.castlingRights, newCastlingRights)
        );
      }

      // E. ADJUST EN PASSANT TARGET SQUARE
      let newEnPassantTargetSquare: Square | null = null;
      if (isDoublePawnPush()) {
        const opponentPawn = Piece.from(this.piece.color.flipped, PieceType.pawn);
        const squareBehindPawn = this.piece.color === PieceColor.white ? this.to.down : this.to.up;

        // Check if on the side there is an opponent's Pawn (we just double-stepped next to)
        if (
          this.position.board.get(this.to.left)?.piece === opponentPawn ||
          this.position.board.get(this.to.right)?.piece === opponentPawn
        ) {
          newEnPassantTargetSquare = squareBehindPawn;
        }
      }
      if (this.position.enPassantTargetSquare !== newEnPassantTargetSquare) {
        segments.push(
          new MoveSegmentAdjustEnPassantTargetSquare(
            this.position.enPassantTargetSquare,
            newEnPassantTargetSquare
          )
        );
      }

      return segments;
    };

    const getSegmentsForCastling = (): MoveSegment[] => {
      const segments: MoveSegment[] = [];

      // A. KING'S MOVEMENT
      segments.push(new MoveSegmentMovement(this.from, this.to));

      // B. ROOK'S MOVEMENT
      const rookMovement = this.castlingSide?.getRookMovement(this.position.sideToMove);
      if (rookMovement) {
        segments.push(new MoveSegmentMovement(rookMovement.from, rookMovement.to));
      }

      // C. ADJUSTMENT OF CASTLING RIGHTS
      const newCastlingRights = this.position.castlingRights.revoked(
        CastlingRights.ofPlayer(this.position.sideToMove)
      );
      segments.push(
        new MoveSegmentAdjustCastlingRights(this.position.castlingRights, newCastlingRights)
      );

      // D. ADJUST EN PASSANT TARGET SQUARE
      if (this.position.enPassantTargetSquare) {
        segments.push(
          new MoveSegmentAdjustEnPassantTargetSquare(this.position.enPassantTargetSquare, null)
        );
      }

      return segments;
    };

    return this.castlingSide ? getSegmentsForCastling() : getSegmentsForStandardMove();
  }

  /** Constructs a `RenderedMove` from a `MoveId`. */
  static fromMoveId(moveId: MoveId | null): RenderedMove | null {
    if (!moveId) return null;
    const positionFrom = Position.fromDbKey(moveId.fromPositionDbKey);
    const positionTo = Position.fromDbKey(moveId.toPositionDbKey);
    if (!positionFrom || !positionTo) return null;

    return positionFrom.renderToPosition(positionTo);
  }
}
