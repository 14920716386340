import { Comment } from '../common/Comment';

import { MoveAnnotation } from './MoveAnnotation';

/** Data of the `Move`. */
export class MoveData {
  constructor(
    public readonly annotation: MoveAnnotation,
    public readonly comment: Comment | null
  ) {}

  //region BASICS
  /** Checks equality of two `MoveData`s.
   *  Two `MoveData`s are considered equal if they have the same properties. */
  equals(other: MoveData | null): boolean {
    return !!other && this.annotation === other.annotation && this.comment === other.comment;
  }
  //endregion
}
