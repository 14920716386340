import { motion } from 'framer-motion';

import kingside from '../../../../../../assets/destination_squares/kingside@ipad_2.png';
import promoting_downwards from '../../../../../../assets/destination_squares/promoting_downwards@ipad_2.png';
import promoting_upwards from '../../../../../../assets/destination_squares/promoting_upwards@ipad_2.png';
import queenside from '../../../../../../assets/destination_squares/queenside@ipad_2.png';
import standard from '../../../../../../assets/destination_squares/standard@ipad_2.png';
import { Square } from '../../../models/common/Square';
import { useChessboardDimensionsContext } from '../ChessboardDimensionsContext';

export type LegalDestinationSquareType =
  | 'standard'
  | 'castlesKingside'
  | 'castlesQueenside'
  | 'promoteUpwards'
  | 'promoteDownwards';

export type LegalDestinationSquareViewProps = {
  type: LegalDestinationSquareType;
  square: Square;
  originSquare: Square | null;
};

const assetName = (legalDestinationSquareType: LegalDestinationSquareType): string => {
  switch (legalDestinationSquareType) {
    case 'castlesKingside':
      return kingside;
    case 'castlesQueenside':
      return queenside;
    case 'promoteUpwards':
      return promoting_upwards;
    case 'promoteDownwards':
      return promoting_downwards;
    default:
      return standard;
  }
};

export const LegalDestinationSquareView = ({
  type,
  square,
  originSquare,
}: LegalDestinationSquareViewProps) => {
  const { squareLength, isResizing, getCoordinatesOfSquare } = useChessboardDimensionsContext();
  const { x, y } = getCoordinatesOfSquare(square);
  const { x: originX, y: originY } = originSquare
    ? getCoordinatesOfSquare(originSquare)
    : getCoordinatesOfSquare(square);

  return (
    <motion.image
      initial={{ opacity: 0, x: originX, y: originY }}
      animate={{ opacity: 1, x, y }}
      exit={{ opacity: 0 }}
      transition={{ duration: !isResizing ? 16 / 48 : 0 }}
      key={originSquare?.id + square.id}
      width={squareLength}
      height={squareLength}
      xlinkHref={assetName(type)}
    />
  );
};
