import React, { useRef } from 'react';

import { signIn, signOut } from '../../services/AccountService/AccountService';

export function SignIn() {
  // subscribeToAuthStateChanged((user) => console.log({user}), () => console.log('SIGNED OUT'));
  const refEmail = useRef<HTMLInputElement>(null);
  const refPassword = useRef<HTMLInputElement>(null);

  return (
    <div>
      <h1>SIGN IN</h1>
      HUHUHUHU, the DISPLAY NAME provided by the props is props.email If you have already an
      account, sign in with your email address and password to access your opening repertoire.
      <form onSubmit={(event) => event.preventDefault()}>
        <label htmlFor="email">
          Email
          <input type="email" ref={refEmail} id="email" placeholder="test2@andreaskienle.de" />
        </label>
        <label htmlFor="password">
          Password
          <input type="password" ref={refPassword} id="password" placeholder="12345678" />
        </label>
        <button
          onClick={() => {
            signIn('test2@andreaskienle.de', '12345678');
            //signIn('andreaskienle@gmx.de', 'y9WmjitnZYzwPvURLDY@');
          }}
        >
          SIGN IN WITH AUTO CREDENTIALS{' '}
        </button>
        <button
          onClick={() => {
            signOut();
          }}
        >
          SIGN OUT{' '}
        </button>
      </form>
    </div>
  );
}
