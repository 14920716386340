import kosal_bb from '../../../../../assets/pieces/kosal_bb@ipad_2.png';
import kosal_bk from '../../../../../assets/pieces/kosal_bk@ipad_2.png';
import kosal_bn from '../../../../../assets/pieces/kosal_bn@ipad_2.png';
import kosal_bp from '../../../../../assets/pieces/kosal_bp@ipad_2.png';
import kosal_bq from '../../../../../assets/pieces/kosal_bq@ipad_2.png';
import kosal_br from '../../../../../assets/pieces/kosal_br@ipad_2.png';
import kosal_wb from '../../../../../assets/pieces/kosal_wb@ipad_2.png';
import kosal_wk from '../../../../../assets/pieces/kosal_wk@ipad_2.png';
import kosal_wn from '../../../../../assets/pieces/kosal_wn@ipad_2.png';
import kosal_wp from '../../../../../assets/pieces/kosal_wp@ipad_2.png';
import kosal_wq from '../../../../../assets/pieces/kosal_wq@ipad_2.png';
import kosal_wr from '../../../../../assets/pieces/kosal_wr@ipad_2.png';

import { PieceColor } from './PieceColor';
import { PieceType } from './PieceType';

export class Piece {
  private constructor(readonly color: PieceColor, readonly type: PieceType) {}

  //region VALUES
  /** A White Pawn. */
  static whitePawn = new Piece(PieceColor.white, PieceType.pawn);
  /** A White Rook. */
  static whiteRook = new Piece(PieceColor.white, PieceType.rook);
  /** A White Knight. */
  static whiteKnight = new Piece(PieceColor.white, PieceType.knight);
  /** A White Bishop. */
  static whiteBishop = new Piece(PieceColor.white, PieceType.bishop);
  /** A White Queen. */
  static whiteQueen = new Piece(PieceColor.white, PieceType.queen);
  /** A White King. */
  static whiteKing = new Piece(PieceColor.white, PieceType.king);
  /** A Black Pawn. */
  static blackPawn = new Piece(PieceColor.black, PieceType.pawn);
  /** A Black Rook. */
  static blackRook = new Piece(PieceColor.black, PieceType.rook);
  /** A Black Knight. */
  static blackKnight = new Piece(PieceColor.black, PieceType.knight);
  /** A Black Bishop. */
  static blackBishop = new Piece(PieceColor.black, PieceType.bishop);
  /** A Black Queen. */
  static blackQueen = new Piece(PieceColor.black, PieceType.queen);
  /** A Black King. */
  static blackKing = new Piece(PieceColor.black, PieceType.king);
  //endregion

  //region BASICS
  /** Returns the string representation of this `Piece`. */
  toString() {
    return this.fenSymbol;
  }

  /** Returns the `Piece` with the given color and type. */
  static from(color: PieceColor, type: PieceType): Piece {
    switch (color) {
      case PieceColor.white:
        switch (type) {
          case PieceType.pawn:
            return Piece.whitePawn;
          case PieceType.rook:
            return Piece.whiteRook;
          case PieceType.knight:
            return Piece.whiteKnight;
          case PieceType.bishop:
            return Piece.whiteBishop;
          case PieceType.queen:
            return Piece.whiteQueen;
          case PieceType.king:
            return Piece.whiteKing;
          default:
            throw new Error(`Invalid piece type: '${type}'.`);
        }

      case PieceColor.black:
        switch (type) {
          case PieceType.pawn:
            return Piece.blackPawn;
          case PieceType.rook:
            return Piece.blackRook;
          case PieceType.knight:
            return Piece.blackKnight;
          case PieceType.bishop:
            return Piece.blackBishop;
          case PieceType.queen:
            return Piece.blackQueen;
          case PieceType.king:
            return Piece.blackKing;
          default:
            throw new Error(`Invalid piece type: '${type}'.`);
        }
      default:
        throw new Error(`Invalid piece color: '${color}'.`);
    }
  }
  //endregion

  //region NOTATION
  //region FEN REPRESENTATION
  /**
   * Initializes a `Piece` from its FEN representation.
   * @returns `null` if the FEN symbol is invalid.
   */
  static fromFenSymbol = (fenSymbol: string): Piece | null => {
    switch (fenSymbol) {
      case 'P':
        return Piece.whitePawn;
      case 'R':
        return Piece.whiteRook;
      case 'N':
        return Piece.whiteKnight;
      case 'B':
        return Piece.whiteBishop;
      case 'Q':
        return Piece.whiteQueen;
      case 'K':
        return Piece.whiteKing;
      case 'p':
        return Piece.blackPawn;
      case 'r':
        return Piece.blackRook;
      case 'n':
        return Piece.blackKnight;
      case 'b':
        return Piece.blackBishop;
      case 'q':
        return Piece.blackQueen;
      case 'k':
        return Piece.blackKing;
      default:
        return null;
    }
  };

  /**
   * FEN representation of the `Piece`.
   * @example Black Queen = `q`, White King = `K`
   */
  get fenSymbol(): string {
    return this.type.fenSymbol(this.color);
  }
  //endregion

  /**
   * Returns the figurine symbol.
   * @example Black Queen = `♛`, White King = `♔`
   */
  get figurineSymbol(): string {
    return this.type.figurine(this.color);
  }
  //endregion

  get assetName() {
    switch (this.color) {
      case PieceColor.white:
        switch (this.type) {
          case PieceType.pawn:
            return kosal_wp;
          case PieceType.rook:
            return kosal_wr;
          case PieceType.knight:
            return kosal_wn;
          case PieceType.bishop:
            return kosal_wb;
          case PieceType.queen:
            return kosal_wq;
          case PieceType.king:
            return kosal_wk;
          default:
            throw new Error(`Invalid piece type: '${this.type}'`);
        }

      case PieceColor.black:
        switch (this.type) {
          case PieceType.pawn:
            return kosal_bp;
          case PieceType.rook:
            return kosal_br;
          case PieceType.knight:
            return kosal_bn;
          case PieceType.bishop:
            return kosal_bb;
          case PieceType.queen:
            return kosal_bq;
          case PieceType.king:
            return kosal_bk;
          default:
            throw new Error(`Invalid piece type: '${this.type}'`);
        }

      default:
        throw new Error(`Invalid piece color: '${this.color}'`);
    }
  }
}
