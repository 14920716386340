enum CheckSymbolType {
  check = 'Check',
  checkmate = 'Checkmate',
  none = 'None',
}

export class CheckSymbol {
  private constructor(private readonly type: CheckSymbolType) {}

  //region VALUES
  static check = new CheckSymbol(CheckSymbolType.check);
  static checkmate = new CheckSymbol(CheckSymbolType.checkmate);
  static none = new CheckSymbol(CheckSymbolType.none);
  //endregion

  //region BASICS
  static from(isCheck: boolean, isCheckmate: boolean): CheckSymbol {
    if (isCheckmate) {
      return CheckSymbol.checkmate;
    } else if (isCheck) {
      return CheckSymbol.check;
    } else {
      return CheckSymbol.none;
    }
  }

  /**
   * Indicates if the move checks the enemy King.
   * Note that we are stricter than the `CheckSymbol.from` constructor
   * in that `CheckSymbol.checkmate` also has `isCheck=true` whereas the `CheckSymbol.from`
   * constructor does not require this.
   */
  get isCheck(): boolean {
    return this.type !== CheckSymbolType.none;
  }

  /**
   * Indicates if the move checkmates the enemy King.
   * Note that we are stricter than the `CheckSymbol.from` constructor
   * in that `CheckSymbol.checkmate` also has `isCheck=true` whereas the `CheckSymbol.from`
   * constructor does not require this.
   */
  get isCheckmate(): boolean {
    return this.type === CheckSymbolType.checkmate;
  }
  //endregion

  //region NOTATION
  static fromMoveNotationString(moveNotationString: string): CheckSymbol {
    if (moveNotationString.includes('#')) {
      return CheckSymbol.checkmate;
    }
    if (moveNotationString.includes('+')) {
      return CheckSymbol.check;
    }
    return CheckSymbol.none;
  }

  get moveNotationSymbol(): string {
    switch (this.type) {
      case CheckSymbolType.check:
        return '+';
      case CheckSymbolType.checkmate:
        return '#';
      default:
        return '';
    }
  }
  //endregion
}
