import { Typography } from '@mui/material';
import React from 'react';

import { Link } from '../../../../components/Link/Link';
import { useSetDocumentTitle } from '../../../../hooks/useSetDocumentTitle/useSetDocumentTitle';
import { MainContainer } from '../components/Container/MainContainer';

export const Faq = () => {
  useSetDocumentTitle('Frequently Asked Questions | King’s Cross');

  return (
    <MainContainer>
      <article>
        <Typography variant="h3" component="h1">
          Frequently Asked Questions
        </Typography>

        <section>
          <Typography variant="h4" component="h2">
            Why should I create an account?
          </Typography>

          <Typography variant="body1">
            Sync and backup: Your repertoire and training progress are always synced between your
            devices. You can log into your King’s Cross account from a new or restored device and
            everything’s right where you left it.
          </Typography>

          <Typography variant="body1">
            Simpler solutions such as iCloud could do this as well, but I wanted to be flexible:
            Although King’s Cross does not support other operating systems like Android, I wanted to
            keep the option open and stay away from an Apple-only cloud service.
          </Typography>

          <Typography variant="body1">
            You can use King’s Cross without creating an account, but it still uses an account
            behind the scenes — it just assigns you a random number instead of an email address. You
            can always add an email address to such an account later.
          </Typography>
        </section>

        <section>
          <Typography variant="h4" component="h2">
            Why an email address instead of a username?
          </Typography>

          <Typography variant="body1">
            Password resets: With an email address you can always request a reset if you have
            forgotten your password.
          </Typography>

          <Typography variant="body1">
            Don’t worry, I have no interest in spamming you. You can also enter a fake address, but
            if you forget your password, I won’t be able to help.
          </Typography>
        </section>

        <section>
          <Typography variant="h4" component="h2">
            Not all moves are covered during training – why?
          </Typography>

          <Typography variant="body1">
            If some move was not covered during training but you expected it to be part of it, check
            the following:
          </Typography>
          <ul>
            <li>
              <b>Is the training depth restricted?</b> Let’s say the move in question (which is not
              part of the training) is the sixth move, e.g. 6.♖e1. Make sure that the training depth
              is not restricted or that it is restricted to a depth greater than or equal to 6. You
              can view and change the training depth in the opening info by tapping the navigation
              bar when the opening is open and then going to Training &gt; Depth. You will also see
              the training depth in brackets if it is restricted, e.g. Train (5).
            </li>
            <li>
              <b>
                Is the move excluded from training – either manually or because of its annotation?
              </b>{' '}
              Make sure the arrow of the move is yellow. This indicates that the move is part of the
              training. If the move is not yellow, check the following:
              <ul>
                <li>
                  <b>
                    Is the training relevance set to automatic and the move annotated as dubious or
                    worse?
                  </b>{' '}
                  Let’s say you are training an opening for White and the move in question (which is
                  not part of the training) is also a move for White. Make sure that this move is
                  not annotated as dubious (?!), mistake (?) or blunder (??); otherwise, the move
                  will not be included in your training. (What’s the point of learning a bad move?)
                  Note that this rule only applies to your own moves, i.e. Black moves will be
                  trained regardless of their annotations. This rule also only applies if the
                  training relevance of the move is set to automatic. You can change the move
                  annotation by tapping the three dots in the move table and then going to Move &gt;
                  Annotation Symbol. Make also sure the training relevance is set to automatic
                  (Training &gt; Training Relevance). You can also force the move to be part of the
                  training by setting its training relevance to include.
                </li>
                <li>
                  <b>Are there moves with better annotations?</b> Let’s say you are training an
                  opening for White and the move in question (which is not part of the training) is
                  also a move for White. Make sure that there are no moves with better annotations
                  in the same position in this opening. For example, if there is a brilliant move
                  (!!), other good moves (!) are considered incorrect. (You only want to learn the
                  best move for the side you’re playing, don’t you?) Note that this rule only
                  applies to your own moves, i.e. all Black moves will be trained regardless of
                  their annotations. This rule also only applies if the training relevance of the
                  move is set to automatic. You can change the move annotation by tapping the three
                  dots in the move table and then going to Move &gt; Annotation Symbol. Make also
                  sure the training relevance is set to automatic (Training &gt; Training
                  Relevance). You can also force the move to be part of the training by setting its
                  training relevance to include.
                </li>
              </ul>
            </li>
            <li>
              <b>Is there a follow-up move?</b> Let’s say you are training an opening for White and
              the move in question (which is not part of the training) is a move for Black. Make
              sure that there is another move for White after this move, otherwise the move will not
              be included in your training. (What’s the point of showing a move for Black to which
              White has no answer?)
            </li>
          </ul>
        </section>

        <section>
          <Typography variant="h4" component="h2">
            Why should I subscribe to King’s Cross Premium?
          </Typography>

          <Typography variant="body1">
            With King’s Cross you can manage and train your chess openings. However the following
            limits apply to the free version:
          </Typography>
          <ul>
            <li>You can only create up to three openings</li>
            <li>You will see occasional ads.</li>
          </ul>
          <Typography variant="body1">
            You can subscribe to King’s Cross Premium to remove these limits and to support future
            development.
          </Typography>
        </section>

        <section>
          <Typography variant="h4" component="h2">
            What happens after my subscription expires?
          </Typography>

          <Typography variant="body1">
            After your subscription expires, King’s Cross will return to free mode with its outlined
            limitations.
          </Typography>

          <Typography variant="body1">
            If you have already created more than three openings, you will only be able to make
            changes to your first three ones – all other openings will become read-only, i.e. you
            cannot add additional moves to them.
          </Typography>

          <Typography variant="body1">
            You will still be able to browse all your openings, train them, export them and so on.
          </Typography>
        </section>

        <section>
          <Typography variant="h4" component="h2">
            Other question?
          </Typography>

          <Typography variant="body1">
            If your question is not answered here, feel free to write an email to{' '}
            <Link href="mailto:support@kingscross-app.com">support@kingscross-app.com</Link> or
            tweet me at{' '}
            <Link href="https://twitter.com/KingsCrossApp" target="_blank" rel="noreferrer">
              @KingsCrossApp
            </Link>
            .
          </Typography>
        </section>
      </article>
    </MainContainer>
  );
};
