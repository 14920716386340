import { PieceColor } from '../common/PieceColor';
import { PieceType } from '../common/PieceType';
import { Square } from '../common/Square';
import { Direction } from '../move/Direction';
import { Repeat } from '../move/Repeat';

import { CastlingSide } from './CastlingSide';
import { Position } from './Position';

export class DestinationSquares {
  constructor(
    /** Destination squares of standard Moves (i.e. no capture, no castling moves). */
    public readonly quietMoves: Set<Square> = new Set(),
    /** Destination squares of captures (without en passant captures). */
    public readonly captures: Set<Square> = new Set(),
    /** Destination squares of castling kingside (i.e. the King's movement). */
    public readonly castlesKingside: Set<Square> = new Set(),
    /** Destination squares of castling queenside (i.e. the King's movement). */
    public readonly castlesQueenside: Set<Square> = new Set(),
    /** Destination squares of en passant captures. */
    public readonly enPassantCaptures: Set<Square> = new Set(),
    /** Destination squares of pawn promotions.
     *  Note that this set can be a subset of captures and quietMoves. */
    public readonly promotions: Set<Square> = new Set()
  ) {}

  //region API
  /** Returns empty destination squares. */
  static empty(): DestinationSquares {
    return new DestinationSquares();
  }

  /** Removes the given squares from all destinations.*/
  remove(squares: Set<Square>) {
    squares.forEach((square) => {
      this.quietMoves.delete(square);
      this.captures.delete(square);
      this.castlesKingside.delete(square);
      this.castlesQueenside.delete(square);
      this.enPassantCaptures.delete(square);
      this.promotions.delete(square);
    });
  }

  /** Destination squares of castling (i.e. the King's movement). */
  castles(): Set<Square> {
    return new Set([...Array.from(this.castlesKingside), ...Array.from(this.castlesQueenside)]);
  }

  /** Destination squares of all moves which do not promote a pawn. */
  nonPromotingMoves(): Set<Square> {
    return new Set([
      ...Array.from(this.quietMoves),
      ...Array.from(this.captures),
      ...Array.from(this.castlesKingside),
      ...Array.from(this.castlesQueenside),
      ...Array.from(this.enPassantCaptures),
    ]);
  }

  /** All destination squares. */
  all(): Set<Square> {
    return new Set([
      ...Array.from(this.quietMoves),
      ...Array.from(this.captures),
      ...Array.from(this.castlesKingside),
      ...Array.from(this.castlesQueenside),
      ...Array.from(this.enPassantCaptures),
      ...Array.from(this.promotions),
    ]);
  }

  /** All destination squares except castles. */
  nonCastles(): Set<Square> {
    return new Set([
      ...Array.from(this.quietMoves),
      ...Array.from(this.captures),
      ...Array.from(this.enPassantCaptures),
      ...Array.from(this.promotions),
    ]);
  }

  /** All destination squares except castles and promotions. */
  nonCastlesNonPromoting(): Set<Square> {
    return new Set([
      ...Array.from(this.quietMoves),
      ...Array.from(this.captures),
      ...Array.from(this.enPassantCaptures),
    ]);
  }
  //endregion

  //region MOVE RENDERING
  /** Determines the destination squares for a given `Piece` in the given position at the given square. */
  static fromPiece(position: Position, origin: Square): DestinationSquares {
    const destinationSquares = new DestinationSquares();
    destinationSquares.insertPseudoLegalDestinationSquaresForPiece(position, origin);
    destinationSquares.filterIllegalDestinationSquares(position, origin);
    return destinationSquares;
  }

  /** Determines the destination squares for a given `Piece` in the given position at the given square. */
  static fromSuperPiece(
    position: Position,
    origin: Square,
    pieceColor: PieceColor,
    xRaySquares: Square[]
  ): DestinationSquares {
    const destinationSquares = new DestinationSquares();
    destinationSquares.insertPseudoLegalDestinationSquaresForSuperPiece(
      position,
      origin,
      pieceColor,
      xRaySquares
    );
    return destinationSquares;
  }

  /**
   * Adds the pseudo-legal destination squares of a given `Piece`.
   * A move is considered *pseudo*-legal if it adheres to the chess rules for Piece movement and captures
   *
   * **However, the following conditions are not checked**:
   *  - if the move leaves the own king in check
   *
   *  **⇒ A pseudo-legal move might still be illegal.**
   */
  private insertPseudoLegalDestinationSquaresForPiece(position: Position, origin: Square) {
    const piece = position.board.get(origin)?.piece;
    switch (piece?.type) {
      case PieceType.rook:
        this.insertPseudoLegalDestinationSquaresForRook(position, origin, piece.color, []);
        return;
      case PieceType.knight:
        this.insertPseudoLegalDestinationSquaresForKnight(position, origin, piece.color, []);
        return;
      case PieceType.bishop:
        this.insertPseudoLegalDestinationSquaresForBishop(position, origin, piece.color, []);
        return;
      case PieceType.queen:
        this.insertPseudoLegalDestinationSquaresForQueen(position, origin, piece.color, []);
        return;
      case PieceType.king:
        this.insertPseudoLegalDestinationSquaresForKing(position, origin, piece.color);
        return;
      case PieceType.pawn:
        this.insertPseudoLegalDestinationSquaresForPawn(position, origin, piece.color);
        return;
      default:
        return;
    }
  }

  private insertPseudoLegalDestinationSquaresForSuperPiece(
    position: Position,
    origin: Square,
    superPieceColor: PieceColor,
    xRaySquares: Square[]
  ) {
    this.insertPseudoLegalDestinationSquaresForQueen(
      position,
      origin,
      superPieceColor,
      xRaySquares
    );
    this.insertPseudoLegalDestinationSquaresForKnight(
      position,
      origin,
      superPieceColor,
      xRaySquares
    );
  }

  private insertPseudoLegalDestinationSquaresForQueen(
    position: Position,
    origin: Square,
    queenColor: PieceColor,
    xRaySquares: Square[]
  ) {
    this.insertPseudoLegalDestinationSquaresForRook(position, origin, queenColor, xRaySquares);
    this.insertPseudoLegalDestinationSquaresForBishop(position, origin, queenColor, xRaySquares);
  }

  private insertPseudoLegalDestinationSquaresForRook(
    position: Position,
    origin: Square,
    rookColor: PieceColor,
    xRaySquares: Square[]
  ) {
    [Direction.up, Direction.down, Direction.left, Direction.right].forEach((direction) => {
      this.insertPseudoLegalDestinationSquares(
        position,
        origin,
        rookColor,
        direction,
        Repeat.toEdge,
        xRaySquares
      );
    });
  }

  private insertPseudoLegalDestinationSquaresForBishop(
    position: Position,
    origin: Square,
    bishopColor: PieceColor,
    xRaySquares: Square[]
  ) {
    [Direction.upLeft, Direction.upRight, Direction.downLeft, Direction.downRight].forEach(
      (direction) => {
        this.insertPseudoLegalDestinationSquares(
          position,
          origin,
          bishopColor,
          direction,
          Repeat.toEdge,
          xRaySquares
        );
      }
    );
  }

  private insertPseudoLegalDestinationSquaresForKnight(
    position: Position,
    origin: Square,
    knightColor: PieceColor,
    xRaySquares: Square[]
  ) {
    [
      Direction.upUpLeft,
      Direction.upUpRight,
      Direction.rightRightUp,
      Direction.rightRightDown,
      Direction.downDownLeft,
      Direction.downDownRight,
      Direction.leftLeftUp,
      Direction.leftLeftDown,
    ].forEach((direction) => {
      this.insertPseudoLegalDestinationSquares(
        position,
        origin,
        knightColor,
        direction,
        Repeat.once,
        xRaySquares
      );
    });
  }

  private insertPseudoLegalDestinationSquaresForPawn(
    position: Position,
    origin: Square,
    pawnColor: PieceColor
  ) {
    // QUIET MOVES
    const moveDirection = pawnColor === PieceColor.white ? Direction.up : Direction.down;
    const repeat = origin.rank === pawnColor.player.pawnRank ? Repeat.twice : Repeat.once;

    // Note: We create a new DestinationSquares which we only use to get the quiet moves because the pawn does not capture in its movement direction
    const quietMovesHelper = new DestinationSquares();
    quietMovesHelper.insertPseudoLegalDestinationSquares(
      position,
      origin,
      pawnColor,
      moveDirection,
      repeat,
      []
    );
    quietMovesHelper.quietMoves.forEach((square) => this.quietMoves.add(square));

    // CAPTURES
    const captureDirection =
      pawnColor === PieceColor.white
        ? [Direction.upLeft, Direction.upRight]
        : [Direction.downLeft, Direction.downRight];

    // Note: We create a new DestinationSquares which we only use to get the captures because the pawn does not capture in its movement direction
    const capturesHelper = new DestinationSquares();
    captureDirection.forEach((captureDirection) => {
      capturesHelper.insertPseudoLegalDestinationSquares(
        position,
        origin,
        pawnColor,
        captureDirection,
        Repeat.once,
        []
      );
    });
    capturesHelper.captures.forEach((square) => this.captures.add(square));

    // PROMOTIONS
    // Temporary set so that the loop does not get mixed up
    const promotions: Set<Square> = new Set();
    Array.from(this.all())
      .filter((square) => square.rank === pawnColor.player.flipped().backRank)
      .forEach((square) => promotions.add(square));
    promotions.forEach((square) => this.promotions.add(square));

    // EN PASSANT CAPTURES
    if (position.enPassantTargetSquare) {
      captureDirection.forEach((captureDirection) => {
        const enPassantTargetSquare = Square.ifValid(
          origin.file + captureDirection.stepFile,
          origin.rank + captureDirection.stepRank
        );

        if (enPassantTargetSquare && position.enPassantTargetSquare === enPassantTargetSquare) {
          this.enPassantCaptures.add(enPassantTargetSquare);
        }
      });
    }
  }

  private insertPseudoLegalDestinationSquaresForKing(
    position: Position,
    origin: Square,
    pieceColor: PieceColor
  ) {
    this.insertPseudoLegalDestinationSquaresForKingWithoutCastling(position, origin, pieceColor);
    this.insertPseudoLegalDestinationSquaresForKingCastling(position, pieceColor);
  }

  private insertPseudoLegalDestinationSquaresForKingCastling(
    position: Position,
    pieceColor: PieceColor
  ) {
    position.getCastlingAbilities(pieceColor.player).forEach((castlingSide) => {
      const movement = castlingSide.getKingMovement(pieceColor.player).to;
      if (castlingSide === CastlingSide.kingside) {
        this.castlesKingside.add(movement);
      } else {
        this.castlesQueenside.add(movement);
      }
    });
  }

  private insertPseudoLegalDestinationSquaresForKingWithoutCastling(
    position: Position,
    origin: Square,
    kingColor: PieceColor
  ) {
    this.insertPseudoLegalDestinationSquares(
      position,
      origin,
      kingColor,
      Direction.up,
      Repeat.once,
      []
    );
    this.insertPseudoLegalDestinationSquares(
      position,
      origin,
      kingColor,
      Direction.right,
      Repeat.once,
      []
    );
    this.insertPseudoLegalDestinationSquares(
      position,
      origin,
      kingColor,
      Direction.down,
      Repeat.once,
      []
    );
    this.insertPseudoLegalDestinationSquares(
      position,
      origin,
      kingColor,
      Direction.left,
      Repeat.once,
      []
    );
    this.insertPseudoLegalDestinationSquares(
      position,
      origin,
      kingColor,
      Direction.upLeft,
      Repeat.once,
      []
    );
    this.insertPseudoLegalDestinationSquares(
      position,
      origin,
      kingColor,
      Direction.upRight,
      Repeat.once,
      []
    );
    this.insertPseudoLegalDestinationSquares(
      position,
      origin,
      kingColor,
      Direction.downLeft,
      Repeat.once,
      []
    );
    this.insertPseudoLegalDestinationSquares(
      position,
      origin,
      kingColor,
      Direction.downRight,
      Repeat.once,
      []
    );
  }

  /**
   * Returns the pseudo-legal destination squares of a piece of `pieceColor` in a straight line from `origin` into the `direction`.
   * the given number of times (`repeating`). If `xRaySquare` is given, it is considered to be an empty square.
   */
  private insertPseudoLegalDestinationSquares(
    position: Position,
    origin: Square,
    pieceColor: PieceColor,
    direction: Direction,
    repeating: Repeat,
    xRaySquares: Square[]
  ) {
    // We do not consider the origin square
    let rank = origin.rank + direction.stepRank;
    let file = origin.file + direction.stepFile;
    let repetitionsRemaining = repeating.steps;

    while (repetitionsRemaining > 0 && Square.isValid(file, rank)) {
      const destination = Square.ifValid(file, rank);
      if (!destination) return; // should not happen

      const targetPiece = position.board.get(destination);
      if (targetPiece) {
        if (targetPiece.color !== pieceColor) {
          if (xRaySquares.includes(destination)) {
            // "Pass" the xRaySquare and continue
            this.quietMoves.add(destination);
          } else {
            // Capture, after this we need to stop here
            this.captures.add(destination);
            return;
          }
        } else {
          if (xRaySquares.includes(destination)) {
            // "Pass" the xRaySquare and continue
            this.quietMoves.add(destination);
          } else {
            // blocked by own Piece, we need to stop here
            return;
          }
        }
      } else {
        // empty square
        this.quietMoves.add(destination);
      }

      rank += direction.stepRank;
      file += direction.stepFile;
      repetitionsRemaining--;
    }
  }

  /** Filters out all destination squares which will leave the own king in check. */
  private filterIllegalDestinationSquares(position: Position, origin: Square) {
    const piece = position.board.get(origin)?.piece;
    if (!piece) return;

    const squaresToDelete: Square[] = [];

    // Finding the King will only lead to the expected result if the king does not move right now (pieceType != .king)
    // We call this here for performance reasons
    const oldKingPosition = position.board.findKing(piece.color);

    Array.from(this.all())
      .filter((square) => !this.enPassantCaptures.has(square))
      .forEach((square) => {
        // Find the king in the new positions
        const kingPosition = piece.type === PieceType.king ? square : oldKingPosition;

        if (position.isAttacked(kingPosition, piece.color.player, [origin], square)) {
          squaresToDelete.push(square);
        }
      });

    // For EnPassant captures we have to consider this special case where capturing will leave the own King in check because both Pawns are removed from the board
    // "8/2p5/3p4/KP5r/1R2Pp1k/8/6P1/8 b - e3"
    // To solve this, we add two xRaySquares for both Pawns which will be removed
    Array.from(this.enPassantCaptures).forEach((square) => {
      const kingPosition = oldKingPosition; // since we are moving a Pawn, this has not changed
      const capturedPawnSquare = Square.ifValid(square.file, origin.rank);
      if (!capturedPawnSquare) throw new Error(`Invalid square: ${square.file}x${origin.rank}`);

      if (
        position.isAttacked(kingPosition, piece.color.player, [origin, capturedPawnSquare], square)
      ) {
        squaresToDelete.push(square);
      }
    });

    this.remove(new Set(squaresToDelete));
  }
  //endregion
}
