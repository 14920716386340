import { createTheme, Theme, ThemeProvider, useTheme } from '@mui/material';
import { ReactNode } from 'react';

export const useAppTheme = (): Theme => {
  const baseTheme = useTheme();

  const headerFontFamily = [
    'Raleway',
    '"Helvetica Neue"',
    'Helvetica',
    '"Segoe UI"',
    'Tahoma',
    'Arial',
    'sans-serif',
  ].join(',');

  return createTheme({
    palette: {
      primary: {
        main: '#458967',
      },
      secondary: {
        light: '#0066ff',
        main: '#0044ff',
        contrastText: '#ffcc00',
      },
      contrastThreshold: 3,
      tonalOffset: 0.2,
    },
    typography: {
      fontFamily: [
        'Lato',
        '"Helvetica Neue"',
        'Helvetica',
        '"Segoe UI"',
        'Tahoma',
        'Arial',
        'sans-serif',
      ].join(','),
      body1: {
        lineHeight: '1.7',
        fontSize: '1.125rem',
        marginTop: '1rem',
      },
      h1: {
        fontFamily: headerFontFamily,
        fontSize: '2rem',
        marginBottom: '1.375rem',
        [baseTheme.breakpoints.up('lg')]: {
          fontSize: '3rem',
          marginBottom: '2rem',
        },
      },
      h2: {
        fontFamily: headerFontFamily,
        fontSize: '1.6rem',
        marginBottom: '1.375rem',
        [baseTheme.breakpoints.up('lg')]: {
          fontSize: '2.375rem',
          marginBottom: '2rem',
        },
      },
      h3: {
        fontFamily: headerFontFamily,
        fontSize: '2rem',
        marginBottom: '1.375rem',
        [baseTheme.breakpoints.up('lg')]: {
          marginBottom: '2rem',
        },
      },
      h4: {
        fontFamily: headerFontFamily,
        fontSize: '1.5rem',
        marginTop: '3rem',
        marginBottom: '1rem',
        [baseTheme.breakpoints.up('lg')]: {
          marginBottom: '2rem',
        },
      },
      h5: {
        fontFamily: headerFontFamily,
        fontSize: '1.17rem',
        marginTop: '1.17rem',
        marginBottom: '1.17rem',
      },
      h6: {
        fontFamily: headerFontFamily,
        fontSize: '1rem',
        marginTop: '1.17rem',
        marginBottom: '1.17rem',
      },
    },
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          body: {
            // Override the marginTop set in typography.body1
            marginTop: '0',
          },
        },
      },
    },
  });
};

export const AppThemeProvider = ({ children }: { children: ReactNode }) => {
  const theme = useAppTheme();

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};
