import chessboard from '../../../../../../assets/chessboard/chessboard.png';
import { useChessboardDimensionsContext } from '../ChessboardDimensionsContext';

export const BoardLayer = () => {
  const { boardLength, topLeft } = useChessboardDimensionsContext();

  return boardLength ? (
    <image
      x={topLeft.x}
      y={topLeft.y}
      width={boardLength}
      height={boardLength}
      xlinkHref={chessboard}
    />
  ) : null;
};
