/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Divider, List, ListItem, ListItemText, Typography } from '@mui/material';
import React, { useState } from 'react';

import { ChessboardContainer } from '../../../game/components/Chessboard/ChessboardContainer';
import { Square } from '../../../game/models/common/Square';
import { BasicMove } from '../../../game/models/move/BasicMove';
import { Movement } from '../../../game/models/move/Movement';
import { Position } from '../../../game/models/position/Position';

const styles = () => ({
  container: css`
    display: grid;
    grid-template-columns: minmax(calc(70vmin), calc(100vh - calc(var(--header-height)))) auto;
    grid-template-rows: fit-content(0);

    @media (orientation: portrait) {
      grid-template-columns: auto;
      grid-template-rows: fit-content(0) auto;
    }
  `,

  chessboardContainer: css`
    width: 100%;
    padding-bottom: 100%;
    position: relative; // to provide an anchor for the chessboard
  `,

  moveList: css`
    background-color: white;
    height: 100%;
  `,
});

export const Opening = () => {
  const [position, setPosition] = useState(() => Position.start());
  const classes = styles();
  // const { openingId } = useParams();

  const onMoveRequest = (from: Square, to: Square): void => {
    // TODO: PROMOTION..
    const basicMove = new BasicMove(new Movement(from, to), null);
    // eslint-disable-next-line testing-library/render-result-naming-convention
    const renderedMove = position.renderMove(basicMove);
    if (renderedMove) {
      console.log('NEW MOVE');
      const newPosition = position.executed(renderedMove);
      setPosition(newPosition);
    } else {
      console.log('ILLEGAL MOVE');
      // TODO: THIS IS A HACK TO ENFORCE A RERENDER OF THE CHESSBOARD. MAYBE THERE ARE BETTER WAYS!?
      // SCENARIO: Drag a piece and drop it at an illegal position.
      setPosition((position) => position.clone());
    }
  };

  return (
    <div css={classes.container}>
      <div css={classes.chessboardContainer}>
        <ChessboardContainer position={position} onMoveRequest={onMoveRequest} />
      </div>

      <div css={classes.moveList}>
        <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
          <ListItem alignItems="flex-start">
            <ListItemText
              primary="Brunch this weekend?"
              secondary={
                <React.Fragment>
                  <Typography
                    sx={{ display: 'inline' }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                  >
                    Ali Connors
                  </Typography>
                  {" — I'll be in your neighborhood doing errands this…"}
                </React.Fragment>
              }
            />
          </ListItem>
          <Divider variant="inset" component="li" />
          <ListItem alignItems="flex-start">
            <ListItemText
              primary="Summer BBQ"
              secondary={
                <React.Fragment>
                  <Typography
                    sx={{ display: 'inline' }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                  >
                    to Scott, Alex, Jennifer
                  </Typography>
                  {" — Wish I could come, but I'm out of town this…"}
                </React.Fragment>
              }
            />
          </ListItem>
          <Divider variant="inset" component="li" />
          <ListItem alignItems="flex-start">
            <ListItemText
              primary="Oui Oui"
              secondary={
                <React.Fragment>
                  <Typography
                    sx={{ display: 'inline' }}
                    component="span"
                    variant="body2"
                    color="text.primary"
                  >
                    Sandra Adams
                  </Typography>
                  {' — Do you have Paris recommendations? Have you ever…'}
                </React.Fragment>
              }
            />
          </ListItem>
        </List>
      </div>
    </div>
  );
};
