import { OpeningColor } from '../../../repertoires/opening/model/OpeningColor';
import { BoardDimensions } from '../common/BoardDimensions';
import { PieceColor } from '../common/PieceColor';

enum PlayerType {
  White = 'White',
  Black = 'Black',
}

/**
 * A playing side (`black` or `white`).
 *
 * Note: There is a strong relation to `PieceColor` but `Player` is a position property (as side to move) whereas `PieceColor` is a `Piece` property.
 */
export class Player {
  private constructor(private readonly type: PlayerType) {}

  //region VALUES
  /** White. */
  static white = new Player(PlayerType.White);
  /** Black. */
  static black = new Player(PlayerType.Black);
  //endregion

  //region BASICS
  toString() {
    return this.type;
  }
  //endregion

  //region FEN REPRESENTATION
  private static fenSymbolWhite = 'w';
  private static fenSymbolBlack = 'b';

  /**
   * Converts a FEN representation of a player to a `Player`.
   * @returns `null` if the FEN representation is invalid.
   */
  static fromFenSymbol(fenSymbol: string): Player | null {
    switch (fenSymbol.toLocaleLowerCase()) {
      case Player.fenSymbolWhite:
        return Player.white;
      case Player.fenSymbolBlack:
        return Player.black;
      default:
        return null;
    }
  }

  /** FEN representation of the `Player`. */
  get fenSymbol(): string {
    switch (this.type) {
      case PlayerType.White:
        return Player.fenSymbolWhite;
      case PlayerType.Black:
        return Player.fenSymbolBlack;
      default:
        throw new Error(`Invalid player type: '${this.type}'.`);
    }
  }
  //endregion

  //region GAME-RELATED
  /** Corresponding `PieceColor`. */
  get pieceColor(): PieceColor {
    switch (this.type) {
      case PlayerType.White:
        return PieceColor.white;
      case PlayerType.Black:
        return PieceColor.black;
      default:
        throw new Error(`Invalid player type: '${this.type}'.`);
    }
  }

  /** Corresponding `OpeningColor`. */
  get openingColor(): OpeningColor {
    switch (this.type) {
      case PlayerType.White:
        return OpeningColor.White;
      case PlayerType.Black:
        return OpeningColor.Black;
      default:
        throw new Error(`Invalid player type: '${this.type}'.`);
    }
  }

  flipped(): Player {
    switch (this.type) {
      case PlayerType.White:
        return Player.black;
      case PlayerType.Black:
        return Player.white;
      default:
        throw new Error(`Invalid player type: '${this.type}'.`);
    }
  }

  /** Returns the rank of the Pawns in their starting position. */
  get pawnRank(): number {
    switch (this.type) {
      case PlayerType.White:
        return BoardDimensions.ranks.whitePawn;
      case PlayerType.Black:
        return BoardDimensions.ranks.blackPawn;
      default:
        throw new Error(`Invalid player type: '${this.type}'.`);
    }
  }

  /** Returns the back rank. */
  get backRank(): number {
    switch (this.type) {
      case PlayerType.White:
        return BoardDimensions.ranks.whiteBack;
      case PlayerType.Black:
        return BoardDimensions.ranks.blackBack;
      default:
        throw new Error(`Invalid player type: '${this.type}'.`);
    }
  }
  //endregion
}
