import { Typography } from '@mui/material';
import React, { useEffect } from 'react';

import { Link } from '../../../../components/Link/Link';
import { useSetDocumentTitle } from '../../../../hooks/useSetDocumentTitle/useSetDocumentTitle';
import { MainContainer } from '../components/Container/MainContainer';

/**
 * Temporary placeholder which redirects the user to the Firebase account activation page.
 *
 * Firebase sends an email to the user with a link to activate their account.
 * This link points to the kingscross-app.com domain and has some URL parameters that need to be redirected to Firebase.
 *
 * Sample link:
 *   https://www.kingscross-app.com/account?mode=verifyEmail&oobCode=RKaIqMx6zrvSzEKhPAYJcPMrScFZOBBX8fAiydPzY14AAAF95pEwJA&apiKey=AIzaSyATXTFdvVVScUjngDopTq6PMh9bjcrQbYg&lang=en
 * should be redirected to:
 *   https://project-4166017698763095973.firebaseapp.com/__/auth/action?mode=verifyEmail&oobCode=RKaIqMx6zrvSzEKhPAYJcPMrScFZOBBX8fAiydPzY14AAAF95pEwJA&apiKey=AIzaSyATXTFdvVVScUjngDopTq6PMh9bjcrQbYg&lang=en
 */
export const Account = () => {
  useSetDocumentTitle('Account Activation | King’s Cross');

  const queryParams = globalThis.location.search;
  const forwardUrl =
    'https://project-4166017698763095973.firebaseapp.com/__/auth/action' + queryParams;

  // Forward to the correct URL
  useEffect(() => {
    globalThis.location.replace(forwardUrl);
  }, [forwardUrl]);

  return (
    <MainContainer>
      <article>
        <section>
          <Typography variant="h3" component="h1">
            Hold On
          </Typography>

          <Typography variant="body1">
            You are being <Link href={forwardUrl}>forwarded</Link>.
          </Typography>

          <Typography variant="body1">
            If you encounter any problems, please <Link to="/contact">get in touch</Link>.
          </Typography>
        </section>
      </article>
    </MainContainer>
  );
};
