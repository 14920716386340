import { Typography } from '@mui/material';
import React from 'react';

import { Link } from '../../../../components/Link/Link';
import { useSetDocumentTitle } from '../../../../hooks/useSetDocumentTitle/useSetDocumentTitle';
import { MainContainer } from '../components/Container/MainContainer';

export const Contact = () => {
  useSetDocumentTitle('Contact | King’s Cross');

  return (
    <MainContainer>
      <article>
        <section>
          <Typography variant="h3" component="h1">
            Contact
          </Typography>
          <Typography variant="body1">
            Hi! I’m Andi{' '}
            <span role="img" aria-label="vulcan salute">
              🖖🏻
            </span>{' '}
            – I’ve started to develop King’s Cross as a personal project of mine to keep track of my
            chess openings and to learn more about iOS development. If this app turns out to be
            useful for other people, I’m happy to hear your feedback. If you think it’s a total
            waste of storage space, you can also drop me a line:
          </Typography>
          <ul>
            <li>
              <Link href="https://twitter.com/KingsCrossApp" target="_blank" rel="noreferrer">
                @KingsCrossApp
              </Link>{' '}
              on Twitter for quick comments
            </li>
            <li>
              Email <Link href="mailto:support@kingscross-app.com">support@kingscross-app.com</Link>{' '}
              for feedback and suggestions
            </li>
          </ul>
          <Typography variant="body1">
            In my real job I’m a{' '}
            <Link href="https://www.sap.com/" target="_blank" rel="noreferrer">
              software engineer
            </Link>{' '}
            and in my leisure time I enjoy playing chess (obviously 😉), running, reading and
            playing the piano. Besides, I’m aiming for a sustainable work-life-balance, so I can
            spend enough time with my family ❤️.
          </Typography>
        </section>
      </article>
    </MainContainer>
  );
};
