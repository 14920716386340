import SearchIcon from '@mui/icons-material/Search';
import {
  alpha,
  Box,
  Collapse,
  Divider,
  InputBase,
  List,
  ListItem,
  ListItemText,
  styled,
  Toolbar,
} from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { TransitionGroup } from 'react-transition-group';

import { BackdropLoadingIndicator } from '../../../../../components/BackdropLoadingIndicator/BackdropLoadingIndicator';
import { useRepertoire } from '../../../../../services/RepertoireService/RepertoireService';
import { TrainingProgress } from '../../../pages/components/TrainingProgress/TrainingProgress';
import { OpeningColor } from '../../opening/model/OpeningColor'; // TODO: IMPORT

import { RepertoireSection } from './RepertoireSection/RepertoireSection';

/*
<Box overflow="auto" sx={{ height: '100%', display: 'flex', justifyContent: 'center' }}>
      <Box sx={{ position: 'absolute', display: 'flex', alignItems: 'center', top: '38.197%', bottom: '61.803%' }}>
        {true && <CircularProgress />}
      </Box>
    </Box>
 */

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}));

export const Repertoire = () => {
  console.log('HELLO REPERTOIRE');
  const { openings, isLoading } = useRepertoire();
  const navigate = useNavigate();

  return (
    <>
      <BackdropLoadingIndicator isLoading={isLoading} />

      <Toolbar>
        <Search>
          <SearchIconWrapper>
            <SearchIcon />
          </SearchIconWrapper>

          <StyledInputBase placeholder="Search…" inputProps={{ 'aria-label': 'search' }} />
        </Search>
      </Toolbar>

      <Box overflow="auto">
        <List
          sx={{
            bgcolor: 'background.paper',
            height: '100%',
            overflow: 'auto',
            '& ul': { padding: 0 },
          }}
          subheader={<li />}
        >
          {[OpeningColor.White, OpeningColor.Black].map((openingColor) => (
            <li key={`section-${openingColor}`}>
              <ul>
                <RepertoireSection openingColor={openingColor} />
                <TransitionGroup>
                  {openings
                    .filter((opening) => opening.data.color === openingColor)
                    .map((opening, index) => (
                      <Collapse key={`opening-${opening.id}`}>
                        {
                          index > 0 && (
                            <Divider variant="middle" />
                          ) /** TODO: BRAUCHE ICH DEN INDEX?*/
                        }
                        <ListItem
                          sx={{ height: 54 }}
                          key={`opening-${opening.id}`}
                          onClick={() => navigate('openings/' + opening.id)}
                          secondaryAction={
                            <TrainingProgress
                              trainingResult={opening.lastTraining}
                              trainedOn={opening.lastTraining?.trainedOn ?? null}
                            />
                          }
                        >
                          <ListItemText
                            primary={opening.data.name}
                            secondary={opening.data.subtitle}
                          />
                        </ListItem>
                      </Collapse>
                    ))}
                </TransitionGroup>
              </ul>
            </li>
          ))}
        </List>
        )
      </Box>
    </>
  );
};
