import React from 'react';

export type WebPProps = {
  filePrefix: string;
  alt: string;
};

export const WebP = ({ filePrefix, alt }: WebPProps) => {
  const filePng = `${filePrefix}.png`;
  const fileWebp = `${filePrefix}.webp`;

  return (
    <>
      <picture>
        <source srcSet={filePng} type="image/webp" />
        <source srcSet={fileWebp} type="image/png" />
        <img src={filePng} alt={alt} />
      </picture>
    </>
  );
};
