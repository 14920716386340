import { ListSubheader, SvgIcon, Typography } from '@mui/material';
import React from 'react';

import { cssColorFromOpeningColor, OpeningColor } from '../../../opening/model/OpeningColor';

export type RepertoireSectionProps = {
  openingColor: OpeningColor;
};

export const RepertoireSection = ({ openingColor }: RepertoireSectionProps) => {
  return (
    <ListSubheader
      sx={{
        bgcolor: '#D1D1D6',
        height: 40,
        display: 'flex',
        alignItems: 'center',
        color: 'text.primary',
      }}
    >
      <SvgIcon fontSize="small" sx={{ mr: 1 }}>
        <rect
          stroke="#555555"
          strokeWidth="0.5"
          fill={cssColorFromOpeningColor(openingColor)}
          x="2"
          y="2"
          width="20"
          height="20"
          rx="2"
        />
      </SvgIcon>
      <Typography variant="subtitle1">{`Openings for ${openingColor}`}</Typography>
    </ListSubheader>
  );
};
