/**
 * Indicates how far an opening should be indented in the repertoires list.
 */
export enum OpeningIndentation {
  /** No indentation. */
  None = 0,
  /** Indented one level. */
  Level1 = 1,
  /** Indented two levels. */
  Level2 = 2,
  /** Indented three levels. */
  Level3 = 3,
  /** Indented four levels. */
  Level4 = 4,
  /** Indented five levels. */
  Level5 = 5,
  /** Indented six levels. */
  Level6 = 6,
}

/**
 * Converts a Firebase number to an `OpeningIndentation`.
 * Defaults to `OpeningIndentation.None`.
 */
export const openingIndentationFromFbNumber = (fbNumber: number): OpeningIndentation => {
  switch (fbNumber) {
    case 1:
      return OpeningIndentation.Level1;
    case 2:
      return OpeningIndentation.Level2;
    case 3:
      return OpeningIndentation.Level3;
    case 4:
      return OpeningIndentation.Level4;
    case 5:
      return OpeningIndentation.Level5;
    case 6:
      return OpeningIndentation.Level6;
    default:
      return OpeningIndentation.None;
  }
};
