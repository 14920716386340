import { createTheme, ThemeProvider } from '@mui/material';
import { ReactNode } from 'react';

import { useAppTheme } from '../../../components/AppThemeProvider/AppThemeProvider';

export const PrivateThemeProvider = ({ children }: { children: ReactNode }) => {
  const baseTheme = useAppTheme();

  const theme = createTheme(baseTheme, {
    components: {
      MuiCssBaseline: {
        styleOverrides: {
          '#root': {},
        },
      },
    },
  });

  return <ThemeProvider theme={theme}>{children}</ThemeProvider>;
};
