enum CaptureSymbolType {
  standard = 'Standard',
  enPassant = 'EnPassant',
  none = 'None',
}

export class CaptureSymbol {
  private constructor(private readonly type: CaptureSymbolType) {}

  //region VALUES
  static standard = new CaptureSymbol(CaptureSymbolType.standard);
  static enPassant = new CaptureSymbol(CaptureSymbolType.enPassant);
  static none = new CaptureSymbol(CaptureSymbolType.none);
  //endregion

  //region BASICS
  static from(isCapture: boolean, isEnPassantCapture: boolean): CaptureSymbol {
    if (isEnPassantCapture) {
      return CaptureSymbol.enPassant;
    } else if (isCapture) {
      return CaptureSymbol.standard;
    } else {
      return CaptureSymbol.none;
    }
  }

  /**
   * Indicates if the move is a capture.
   * Note that we are stricter than the `CaptureSymbol.from` constructor
   * in that `CaptureSymbol.enPassant` also has `isCapture=true` whereas the `CaptureSymbol.from`
   * constructor does not require this.
   */
  get isCapture(): boolean {
    return this.type !== CaptureSymbolType.none;
  }

  /**
   * Indicates if the move is an en passant capture.
   * Note that we are stricter than the `CaptureSymbol.from` constructor
   * in that `CaptureSymbol.enPassant` also has `isCapture=true` whereas the `CaptureSymbol.from`
   * constructor does not require this.
   */
  get isEnPassantCapture(): boolean {
    return this.type === CaptureSymbolType.enPassant;
  }
  //endregion

  //region NOTATION
  static fromMoveNotationString(moveNotationString: string): CaptureSymbol {
    if (moveNotationString.includes('e.p.')) {
      return CaptureSymbol.enPassant;
    }
    if (moveNotationString.includes('x')) {
      return CaptureSymbol.standard;
    }
    return CaptureSymbol.none;
  }

  get moveNotationInfixSymbol(): string {
    return this.type === CaptureSymbolType.none ? '-' : 'x';
  }

  get moveNotationSuffixSymbol(): string {
    return this.type === CaptureSymbolType.enPassant ? ' e.p.' : '';
  }
  //endregion
}
