/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import React from 'react';
import { Outlet } from 'react-router-dom';

import { Footer } from './components/Footer/Footer';
import { Header } from './components/Header/Header';
import { ScrollToTop } from './components/ScrollToTop/ScrollToTop';

// Align the footer properly at the bottom of the page: https://www.freecodecamp.org/news/how-to-keep-your-footer-where-it-belongs-59c6aa05c59c/
const styles = () => ({
  container: css`
    position: relative;
    min-height: 100vh;
  `,

  mainContainer: css`
    padding-bottom: 16.5rem;
  `,

  footerContainer: css`
    position: absolute;
    bottom: 0;
    height: 16.5rem;
    width: 100%;
  `,
});

export const Public = () => {
  const classes = styles();

  return (
    <>
      <ScrollToTop />
      <div css={classes.container}>
        <Header />
        <div css={classes.mainContainer}>
          <Outlet />
        </div>
        <div css={classes.footerContainer}>
          <Footer />
        </div>
      </div>
    </>
  );
};
