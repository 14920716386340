import { Square } from './Square';

export interface SquareContent {
  equals(other: SquareContent | null, compareIds: boolean): boolean;
}

/** Offers access to an 8x8 grid of type <T> via file/rank and square access. */
export class BoardGrid<T extends SquareContent> {
  private grid = new Map<Square, T>();

  /** Returns the content of the board at the given square. */
  get(square: Square | null): T | null;
  /** Returns the content of the board at the given coordinates. */
  get(file: number, rank: number): T | null;
  get<A extends Square | null | number, B extends A extends number ? number : undefined>(
    a: A,
    b: B
  ): T | null;
  get(squareOrFile: Square | null | number, rank?: number): T | null {
    const square = Square.fromSignatureOverload(squareOrFile, rank);
    if (!square) {
      return null;
    }
    return this.grid.get(square) ?? null;
  }

  /** Sets the content of the board at the given square. */
  set(content: T, square: Square): void;
  /** Sets the content of the board at the given coordinates. */
  set(content: T, file: number, rank: number): void;
  set<A extends Square | number, B extends A extends number ? number : undefined>(
    content: T,
    a: A,
    b: B
  ): void;
  set(content: T, squareOrFile: Square | number, rank?: number): void {
    const square = Square.fromSignatureOverload(squareOrFile, rank);
    if (!square) {
      return;
    }
    this.grid.set(square, content);
  }

  /** Removes an element from the given square. */
  remove(square: Square): void;
  /** Removes an element from the given coordinates. */
  remove(file: number, rank: number): void;
  remove<A extends Square | number, B extends A extends number ? number : undefined>(
    a: A,
    b: B
  ): void;
  remove(squareOrFile: Square | number, rank?: number): void {
    const square = Square.fromSignatureOverload(squareOrFile, rank);
    if (!square) {
      return;
    }
    this.grid.delete(square);
  }

  /** Returns a map with all the occupied squares and their content. */
  content = () => {
    return new Map(Array.from(this.grid.entries()).filter(([, v]) => v !== null));
  };

  /** Returns all squares which contain `element`. */
  find(content: T): Set<Square> {
    return new Set(
      Array.from(this.grid.entries())
        .filter(([, v]) => v === content)
        .map(([square]) => square)
    );
  }

  /** Returns the number of elements in the grid. */
  get count(): number {
    return this.grid.size;
  }

  /** Checks equality of two `BoardGrid`s.
   *  Two `BoardGrid`s are considered equal if they have the identical (===) content at the same squares. */
  equals(other: BoardGrid<T> | null, compareIds: boolean): boolean {
    return (
      !!other &&
      this.grid.size === other.grid.size &&
      Array.from(this.grid).every(([square, content]) => {
        const otherContent = other.get(square);
        return otherContent ? content.equals(otherContent, compareIds) : false;
      })
    );
  }

  /** Returns a new `BoardGrid` with the same content as this one. */
  clone(): BoardGrid<T> {
    const clone = new BoardGrid<T>();
    clone.grid = new Map(this.grid);
    return clone;
  }
}
