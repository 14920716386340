import { BoardDimensions } from '../common/BoardDimensions';
import { Square } from '../common/Square';
import { CastlingSide } from '../position/CastlingSide';
import { IdentifiablePiece } from '../position/IdentifiablePiece';
import { Position } from '../position/Position';

import { BasicMove } from './BasicMove';
import { MoveAnnotation } from './MoveAnnotation';
import { MoveData } from './MoveData';
import { MoveDbKey } from './MoveDbKey';
import { MoveId } from './MoveId';
import { Movement } from './Movement';
import { MoveNotation } from './MoveNotation';
import { MoveSegment } from './MoveSegment';
import { PawnPromotion } from './PawnPromotion';
import { RenderedMove } from './RenderedMove';

/**
 * A move which has been rendered (i.e. verified to be legal) on the chess board and tied to a specific position.
 * Moreover, a move can be decorated with comment and annotation.
 * Additional fields which are derived from the actual position are added (e.g. if the King is in check).
 */
export class Move {
  constructor(public readonly renderedMove: RenderedMove) {}

  /** Move evaluation symbol. */
  annotation = MoveAnnotation.none;

  /** Comment. */
  comment: string | null = null;

  //region CONVENIENCE PROPERTIES
  /** ID. */
  get id(): MoveId {
    return this.dbKey;
  }

  /** Position on which the Move is being played. */
  get position(): Position {
    return this.renderedMove.position;
  }

  /** Resulting position after the Move has been played. */
  get nextPosition(): Position {
    return this.renderedMove.nextPosition;
  }

  /** Underlying basic move. */
  get basicMove(): BasicMove {
    return this.renderedMove.basicMove;
  }

  /** Underlying Piece movement.
   * Note: For castles the King's movement is relevant. */
  get movement(): Movement {
    return this.renderedMove.movement;
  }

  /** Moving `Piece`.
   * Note: For castles the King's movement is relevant. */
  get piece(): IdentifiablePiece {
    return this.renderedMove.piece;
  }

  /** Old square.
   *  Note: For castles the King’s movement is relevant. */
  get from(): Square {
    return this.renderedMove.from;
  }

  /** New square.
   *  Note: For castles the King’s movement is relevant. */
  get to(): Square {
    return this.renderedMove.to;
  }

  /** Pawn promotion (if applicable) */
  get pawnPromotion(): PawnPromotion | null {
    return this.renderedMove.pawnPromotion;
  }

  /** Indicates if the Move is an en passant capture.
   * Note: An en passant capture is considered to be a "standard" capture as well. */
  get isEnPassantCapture(): boolean {
    return this.renderedMove.isEnPassantCapture;
  }

  /** Indicates if the Move is a capture.
   * Note: An en passant capture is considered to be a "standard" capture as well. */
  get isCapture(): boolean {
    return this.renderedMove.isCapture;
  }

  /** Indicates the side to which the King castles (if applicable). */
  get castlingSide(): CastlingSide | null {
    return this.renderedMove.castlingSide;
  }

  /** Indicates if the Move checks the opponent's King. */
  get isCheck(): boolean {
    return this.renderedMove.isCheck;
  }

  /** Indicates if the Move checkmates the opponent's King. */
  get isCheckmate(): boolean {
    return this.renderedMove.isCheckmate;
  }

  /** Data of the `Move`. */
  data(): MoveData {
    return new MoveData(this.annotation, this.comment);
  }
  //endregion

  //region BASICS
  /** Checks equality of two `Move`s.
   *  Two `Move`s are considered equal if they have the same properties. */
  equals(other: Move | null): boolean {
    return (
      !!other && this.renderedMove.equals(other.renderedMove) && this.data().equals(other.data())
    );
  }

  valueOf() {
    return (
      this.from.valueOf() * BoardDimensions.ranks.count * BoardDimensions.files.count +
      this.to.valueOf()
    );
  }
  //endregion

  //region CONVENIENCE API
  /** Returns a copy of this `Move`. */
  clone(): Move {
    const clone = new Move(this.renderedMove.clone());
    clone.annotation = this.annotation;
    clone.comment = this.comment;
    return clone;
  }
  /** Returns a copy of this move wit the annotation "brilliant". */
  get brilliant(): Move {
    const move = this.clone();
    move.annotation = MoveAnnotation.brilliant;
    return move;
  }

  /** Returns a copy of this move wit the annotation "good". */
  get good(): Move {
    const move = this.clone();
    move.annotation = MoveAnnotation.good;
    return move;
  }

  /** Returns a copy of this move wit the annotation "interesting". */
  get interesting(): Move {
    const move = this.clone();
    move.annotation = MoveAnnotation.interesting;
    return move;
  }

  /** Returns a copy of this move wit the annotation "dubious". */
  get dubious(): Move {
    const move = this.clone();
    move.annotation = MoveAnnotation.dubious;
    return move;
  }

  /** Returns a copy of this move wit the annotation "mistake". */
  get mistake(): Move {
    const move = this.clone();
    move.annotation = MoveAnnotation.mistake;
    return move;
  }

  /** Returns a copy of this move wit the annotation "blunder". */
  get blunder(): Move {
    const move = this.clone();
    move.annotation = MoveAnnotation.blunder;
    return move;
  }

  /** Returns a copy of this move wit the annotation "none". */
  get none(): Move {
    const move = this.clone();
    move.annotation = MoveAnnotation.none;
    return move;
  }
  //endregion

  //region MOVE EXECUTION
  segments(): MoveSegment[] {
    /** Returns the `MoveSegments`, i.e. the atomic changes to the position when this Move is executed.
     *  Note: The array is ordered, e.g. for a capture, the captured Piece is first captured and then the capturing Piece is being moved.*/
    return [];
  }
  //endregion

  //region NOTATION
  /** Returns the `MoveNotation`. */
  notation(): MoveNotation {
    const notation = this.renderedMove.notation();

    // Complement the annotation
    notation.annotation = this.annotation;
    return notation;
  }
  //endregion

  //region DATABASE
  /** Move key in Firebase. */
  get dbKey(): MoveDbKey {
    return this.renderedMove.dbKey;
  }
  //endregion
}
