import { Square } from '../common/Square';
import { CastlingRights } from '../position/CastlingRights';
import { IdentifiablePiece } from '../position/IdentifiablePiece';

/**
 * A Move consists of at least one `MoveSegment`, e.g. the movement of the Piece.
 * Other segments can complement a movement, e.g. the Rook's movement in case of castling or the revocation of castling rights.
 * In total all MoveSegments represent the effects of a Move on the position.
 */
export interface MoveSegment {
  type: 'move' | 'capture' | 'promotion' | 'adjustCastlingRights' | 'adjustEnPassantTargetSquare';
}

/** A basic movement of a Piece `from` one square `to` another. */
export class MoveSegmentMovement implements MoveSegment {
  constructor(public readonly from: Square, public readonly to: Square) {}

  type: 'move' = 'move';
}

/** A capture `of` a `Piece` `at` a given square, i.e. the removal of this `Piece`. */
export class MoveSegmentCapture implements MoveSegment {
  constructor(public readonly capturedPiece: IdentifiablePiece, public readonly at: Square) {}

  type: 'capture' = 'capture';
}

/** The promotion of a `pawn` `comingFrom` another square `at` a given square (on the back rank) `into` another `Piece`. */
export class MoveSegmentPromotion implements MoveSegment {
  constructor(
    public readonly pawn: IdentifiablePiece,
    public readonly at: Square,
    public readonly comingFrom: Square,
    public readonly promotedTo: IdentifiablePiece
  ) {}

  type: 'promotion' = 'promotion';
}

/** The adjustment of castling rights `from` one set of rights `to` another, e.g. after the King has moved. */
export class MoveSegmentAdjustCastlingRights implements MoveSegment {
  constructor(public readonly from: CastlingRights, public readonly to: CastlingRights) {}

  type: 'adjustCastlingRights' = 'adjustCastlingRights';
}

/** The adjustment of the en passant target square `from` one square `to` another (or `null`). */
export class MoveSegmentAdjustEnPassantTargetSquare implements MoveSegment {
  constructor(public readonly from: Square | null, public readonly to: Square | null) {}

  type: 'adjustEnPassantTargetSquare' = 'adjustEnPassantTargetSquare';
}
