import { StringUtils } from '../../../../../utils/StringUtils';
import { BoardDimensions } from '../common/BoardDimensions';
import { BoardGrid } from '../common/BoardGrid';
import { Piece } from '../common/Piece';
import { PieceColor } from '../common/PieceColor';
import { PieceType } from '../common/PieceType';
import { Square } from '../common/Square';

import { IdentifiablePiece } from './IdentifiablePiece';

/**
 * Represents the `Pieces` on the board (located on squares)
 * Does **not** care for Side to move, castling rights etc. but is just a static representation of the board.
 */
export class Board {
  private constructor(private pieceGrid: BoardGrid<IdentifiablePiece>) {}

  //region BASICS
  /** Checks equality of two `Board`s.
   *  Two `Board`s are considered equal if they have the same pieces at the same squares. */
  equals(other: Board | null, compareIds: boolean): boolean {
    return !!other && this.pieceGrid.equals(other.pieceGrid, compareIds);
  }

  /** Returns a new `Board` with the same pieces as this one. */
  clone(): Board {
    return new Board(this.pieceGrid.clone());
  }
  //endregion

  /** Returns an empty board without any pieces. */
  static empty() {
    return new Board(new BoardGrid());
  }

  /** Returns a board in the standard starting position. */
  static startingPosition() {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return Board.fromFenSymbol('rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR')!;
  }

  /** Returns a map with all the pieces and their squares. */
  pieces(): Map<Square, IdentifiablePiece> {
    return this.pieceGrid.content();
  }

  /** Returns the piece at the given square. */
  get(square: Square | null): IdentifiablePiece | null;
  /** Returns the piece at the given coordinates. */
  get(file: number, rank: number): IdentifiablePiece | null;
  get(squareOrFile: Square | null | number, rank?: number): IdentifiablePiece | null {
    return this.pieceGrid.get(squareOrFile, rank);
  }

  /** Adds the piece to the given square. */
  add(piece: IdentifiablePiece, square: Square): void;
  /** Adds the piece to the given coordinates. */
  add(piece: IdentifiablePiece, file: number, rank: number): void;
  add(piece: IdentifiablePiece, squareOrFile: Square | number, rank?: number): void {
    const square = Square.fromSignatureOverload(squareOrFile, rank);
    if (!square) {
      throw new Error(`Invalid square: '${squareOrFile}'.`);
    }
    this.pieceGrid.set(piece, square);
  }

  /** Removes a piece from the given square. */
  remove(square: Square): void;
  /** Removes an element at the given coordinates. */
  remove(file: number, rank: number): void;
  remove(squareOrFile: Square | number, rank?: number): void {
    this.pieceGrid.remove(squareOrFile, rank);
  }

  /** Moves a piece from the given square to the target square.
   *  If there is no piece at the source square, the target square is simply cleared. */
  move(from: Square, to: Square): void {
    const piece = this.get(from);
    this.remove(from);

    if (piece) {
      this.pieceGrid.set(piece, to);
    } else {
      this.remove(to);
    }
  }

  //region NOTATION
  private static fenRankDelimiter = '/';
  static fromFenSymbol(fenSymbol: string): Board | null {
    const fillRankFromFen = (rankFenSymbol: string, rankIndex: number) => {
      let fileIndex = 0;
      for (const char of rankFenSymbol) {
        if (StringUtils.isPositiveInteger(char)) {
          fileIndex += Number(char);
        } else {
          const square = Square.ifValid(fileIndex, rankIndex);
          if (!square) {
            throw new Error(
              `Invalid square coordinates (${fileIndex}, ${rankIndex}) in FEN rank '${rankFenSymbol}'.`
            );
          }

          const piece = Piece.fromFenSymbol(char);
          if (!piece) {
            throw new Error(`Invalid piece symbol '${char}' in FEN rank '${rankFenSymbol}'.`);
          }

          board.add(IdentifiablePiece.track(piece, square), square);
          fileIndex += 1;
        }
      }

      if (fileIndex !== BoardDimensions.files.count) {
        throw new Error(`Too few files in FEN rank '${rankFenSymbol}'.`);
      }
    };

    const board = new Board(new BoardGrid<IdentifiablePiece>());

    const ranks = fenSymbol.split(Board.fenRankDelimiter);
    if (ranks.length !== BoardDimensions.ranks.count) {
      return null;
    }

    try {
      ranks
        .slice()
        .reverse()
        .forEach((rankFenSymbol, rank) => {
          fillRankFromFen(rankFenSymbol, rank);
        });
    } catch (error) {
      return null;
    }

    return board;
  }

  get fenSymbol(): string {
    let fenSymbol = '';

    for (let rank = BoardDimensions.ranks.eight; rank >= BoardDimensions.ranks.one; rank--) {
      if (fenSymbol) {
        fenSymbol += Board.fenRankDelimiter;
      }

      let emptyCount = 0;

      for (let file = BoardDimensions.files.a; file <= BoardDimensions.files.h; file++) {
        const identifiablePiece = this.get(file, rank);
        if (identifiablePiece) {
          if (emptyCount > 0) {
            fenSymbol += emptyCount;
            emptyCount = 0;
          }

          fenSymbol += identifiablePiece.piece.fenSymbol;
        } else {
          emptyCount += 1;
        }
      }

      if (emptyCount > 0) {
        fenSymbol += emptyCount;
        emptyCount = 0;
      }
    }

    return fenSymbol;
  }
  //endregion

  //region CONVENIENCE METHODS
  /** Returns the squares of all `Pieces` of the given color. */
  findByColor(color: PieceColor): Set<Square> {
    return new Set(
      Array.from(this.pieces())
        .filter(([, identifiablePiece]) => identifiablePiece.color === color)
        .map(([square]) => square)
    );
  }

  /** Returns the square of the King of the given color. */
  findKing(color: PieceColor): Square {
    // This only works if there is only one King which is not checked during FEN parsing!
    return Array.from(this.pieces())
      .filter(
        ([, identifiablePiece]) =>
          identifiablePiece.type === PieceType.king && identifiablePiece.color === color
      )
      .map(([square]) => square)[0];
  }

  /** Returns the squares for the given piece. */
  find(piece: Piece): Set<Square> {
    return new Set(
      Array.from(this.pieces())
        .filter(([, identifiablePiece]) => identifiablePiece.piece === piece)
        .map(([square]) => square)
    );
  }
  //endregion
}
