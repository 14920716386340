import { Box, Typography } from '@mui/material';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import { DateUtils } from '../../../../../utils/DateUtils';
import { Timestamp } from '../../../models/Timestamp';
import {
  CompactTrainingResult,
  getPercentTrained,
  getRelativePercentageRight,
} from '../../../training/models/CompactTrainingResult';

export type TrainingProgressProps = {
  trainingResult: CompactTrainingResult | null;
  trainedOn: Timestamp | null;
};

export const TrainingProgress = ({ trainingResult, trainedOn }: TrainingProgressProps) => {
  const value = trainingResult ? getRelativePercentageRight(trainingResult) * 100 : 0;
  const circleRatio = trainingResult ? getPercentTrained(trainingResult) : 1;
  const subtext = trainedOn
    ? DateUtils.uiDateDifference(new Date(), DateUtils.dateFromTimestamp(trainedOn))
    : 'new';

  return (
    <Box
      sx={{
        width: 54,
        height: 54,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'space-around',
      }}
    >
      <Box sx={{ width: 20, height: 20 }}>
        <CircularProgressbar value={value} circleRatio={circleRatio} strokeWidth={20} />
      </Box>
      <Typography variant="caption">{subtext}</Typography>
    </Box>
  );
};
