import { BoardDimensions } from '../common/BoardDimensions';

enum RepeatType {
  once = 1,
  twice = 2,
  toEdge = BoardDimensions.length,
}
export class Repeat {
  private constructor(private readonly type: RepeatType) {}

  static once = new Repeat(RepeatType.once);
  static twice = new Repeat(RepeatType.twice);
  static toEdge = new Repeat(RepeatType.toEdge);

  get steps(): number {
    return this.type;
  }
}
