import { motion } from 'framer-motion';

import { Square } from '../../../models/common/Square';
import { useChessboardDimensionsContext } from '../ChessboardDimensionsContext';

export type CurrentDestinationSquareViewProps = {
  square: Square;
};

export const CurrentDestinationSquareView = ({ square }: CurrentDestinationSquareViewProps) => {
  const { squareLength, isResizing, getCoordinatesOfSquare } = useChessboardDimensionsContext();
  let { x, y } = getCoordinatesOfSquare(square);

  if (!squareLength) {
    return null;
  }

  x = x + squareLength / 2;
  y = y + squareLength / 2;

  return (
    <motion.circle
      fill="#33BE6B"
      initial={{ opacity: 0 }}
      animate={{ opacity: [0, 0.3, 0], scale: [12, 1.6, 2.5, 2.3] }}
      exit={{ opacity: 0 }}
      transition={{ duration: !isResizing ? 20 / 24 : 0, ease: 'easeInOut' }}
      key={square.id}
      cx={x}
      cy={y}
      r={squareLength / 2}
    />
  );
};
