import { OpeningColor, openingColorFromFbString } from '../../../opening/model/OpeningColor';
import {
  OpeningIndentation,
  openingIndentationFromFbNumber,
} from '../../../opening/model/OpeningIndentation';

/**
 * Data for an opening in a repertoires.
 */
export type OpeningInRepertoireData = {
  name: string;
  subtitle: string | null;
  indentation: OpeningIndentation;
  color: OpeningColor;
};

/**
 * Converts a Firebase snapshot to an `OpeningInRepertoireData`.
 * @throws an error if the color is invalid.
 */
export const openingInRepertoireDataFromSnapshot = (
  snapshot: Record<string, any>
): OpeningInRepertoireData => {
  return {
    name: snapshot['name'] ?? '',
    subtitle: snapshot['subTitle'] ?? '',
    indentation: openingIndentationFromFbNumber(snapshot['indentation']),
    color: openingColorFromFbString(snapshot['color']),
  };
};
