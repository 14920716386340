import { createContext, ReactNode, RefObject, useContext, useEffect, useState } from 'react';

import { BoardDimensions } from '../../models/common/BoardDimensions';
import { Square } from '../../models/common/Square';

export type Coordinates = {
  x: number;
  y: number;
};

type ChessboardDimensionsContextType = {
  /** The total length of the chessboard (square side length) including the margin; `undefined` before the first render. */
  totalLength: number | undefined;
  /** The length of the chessboard (square side length); `undefined` before the first render. */
  boardLength: number | undefined;
  /** The top left coordinates of the board (taking the margin into account) */
  topLeft: Coordinates;
  /** The length of a single square; `undefined` before the first render. */
  squareLength: number | undefined;
  /** Indicates if the container is currently being resized, so that animations can be suspended. */
  isResizing: boolean;
  /** Function to return the (x,y) coordinates of a given `square` with the current board dimensions. */
  getCoordinatesOfSquare: (square: Square) => Coordinates;
};

const ChessboardDimensionsContext = createContext<ChessboardDimensionsContextType | undefined>(
  undefined
);

export type ChessboardDimensionsContextProviderProps = {
  containerRef: RefObject<HTMLDivElement>;
  children: ReactNode;
};

// TODO: DIMENSION CONtEXT (ALSO RENAMEN
export const ChessboardDimensionsContextProvider = ({
  containerRef,
  children,
}: ChessboardDimensionsContextProviderProps) => {
  const [isResizing, setIsResizing] = useState(true);

  // Register a callback to be called when the container <div> is being resized
  useEffect(() => {
    let timeout: NodeJS.Timeout;

    const handleResize = () => {
      clearTimeout(timeout);

      setIsResizing(true);

      timeout = setTimeout(() => {
        setIsResizing(false);
      }, 200);
    };
    globalThis.window.addEventListener('resize', handleResize);

    // Dispatch the event immediately
    handleResize();

    return () => globalThis.window.removeEventListener('resize', handleResize);
  }, [containerRef]);

  const marginWidth = 8;
  const totalLength = containerRef.current
    ? Math.min(containerRef.current.clientWidth, containerRef.current.clientHeight)
    : undefined;

  const boardLength = totalLength ? totalLength - 2 * marginWidth : undefined;

  const topLeft: Coordinates = { x: marginWidth, y: marginWidth };

  const squareLength = boardLength ? boardLength / BoardDimensions.length : undefined;

  // TODO: SHOULD RETURN UNDE FINED?
  const getCoordinatesOfSquare = (square: Square): Coordinates => {
    if (!boardLength) {
      return { x: 0, y: 0 };
    }
    const x = topLeft.x + (square.file * boardLength) / BoardDimensions.files.count;
    const y =
      topLeft.y +
      ((BoardDimensions.ranks.count - 1 - square.rank) * boardLength) / BoardDimensions.ranks.count;
    return { x, y };
  };

  const value: ChessboardDimensionsContextType = {
    totalLength,
    boardLength,
    topLeft,
    squareLength,
    getCoordinatesOfSquare,
    isResizing,
  };

  return (
    <ChessboardDimensionsContext.Provider value={value}>
      {children}
    </ChessboardDimensionsContext.Provider>
  );
};

export const useChessboardDimensionsContext = () => {
  const context = useContext(ChessboardDimensionsContext);
  if (context === undefined) {
    throw new Error(
      'useChessboardDimensionsContext must be used within a ChessboardDimensionsContextProvider'
    );
  }
  return context;
};
