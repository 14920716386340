import { BoardDimensions } from './BoardDimensions';

export class Square {
  private constructor(readonly file: number, readonly rank: number) {}

  //region VALUES
  static a1 = new Square(BoardDimensions.files.a, BoardDimensions.ranks.one);
  static b1 = new Square(BoardDimensions.files.b, BoardDimensions.ranks.one);
  static c1 = new Square(BoardDimensions.files.c, BoardDimensions.ranks.one);
  static d1 = new Square(BoardDimensions.files.d, BoardDimensions.ranks.one);
  static e1 = new Square(BoardDimensions.files.e, BoardDimensions.ranks.one);
  static f1 = new Square(BoardDimensions.files.f, BoardDimensions.ranks.one);
  static g1 = new Square(BoardDimensions.files.g, BoardDimensions.ranks.one);
  static h1 = new Square(BoardDimensions.files.h, BoardDimensions.ranks.one);

  static a2 = new Square(BoardDimensions.files.a, BoardDimensions.ranks.two);
  static b2 = new Square(BoardDimensions.files.b, BoardDimensions.ranks.two);
  static c2 = new Square(BoardDimensions.files.c, BoardDimensions.ranks.two);
  static d2 = new Square(BoardDimensions.files.d, BoardDimensions.ranks.two);
  static e2 = new Square(BoardDimensions.files.e, BoardDimensions.ranks.two);
  static f2 = new Square(BoardDimensions.files.f, BoardDimensions.ranks.two);
  static g2 = new Square(BoardDimensions.files.g, BoardDimensions.ranks.two);
  static h2 = new Square(BoardDimensions.files.h, BoardDimensions.ranks.two);

  static a3 = new Square(BoardDimensions.files.a, BoardDimensions.ranks.three);
  static b3 = new Square(BoardDimensions.files.b, BoardDimensions.ranks.three);
  static c3 = new Square(BoardDimensions.files.c, BoardDimensions.ranks.three);
  static d3 = new Square(BoardDimensions.files.d, BoardDimensions.ranks.three);
  static e3 = new Square(BoardDimensions.files.e, BoardDimensions.ranks.three);
  static f3 = new Square(BoardDimensions.files.f, BoardDimensions.ranks.three);
  static g3 = new Square(BoardDimensions.files.g, BoardDimensions.ranks.three);
  static h3 = new Square(BoardDimensions.files.h, BoardDimensions.ranks.three);

  static a4 = new Square(BoardDimensions.files.a, BoardDimensions.ranks.four);
  static b4 = new Square(BoardDimensions.files.b, BoardDimensions.ranks.four);
  static c4 = new Square(BoardDimensions.files.c, BoardDimensions.ranks.four);
  static d4 = new Square(BoardDimensions.files.d, BoardDimensions.ranks.four);
  static e4 = new Square(BoardDimensions.files.e, BoardDimensions.ranks.four);
  static f4 = new Square(BoardDimensions.files.f, BoardDimensions.ranks.four);
  static g4 = new Square(BoardDimensions.files.g, BoardDimensions.ranks.four);
  static h4 = new Square(BoardDimensions.files.h, BoardDimensions.ranks.four);

  static a5 = new Square(BoardDimensions.files.a, BoardDimensions.ranks.five);
  static b5 = new Square(BoardDimensions.files.b, BoardDimensions.ranks.five);
  static c5 = new Square(BoardDimensions.files.c, BoardDimensions.ranks.five);
  static d5 = new Square(BoardDimensions.files.d, BoardDimensions.ranks.five);
  static e5 = new Square(BoardDimensions.files.e, BoardDimensions.ranks.five);
  static f5 = new Square(BoardDimensions.files.f, BoardDimensions.ranks.five);
  static g5 = new Square(BoardDimensions.files.g, BoardDimensions.ranks.five);
  static h5 = new Square(BoardDimensions.files.h, BoardDimensions.ranks.five);

  static a6 = new Square(BoardDimensions.files.a, BoardDimensions.ranks.six);
  static b6 = new Square(BoardDimensions.files.b, BoardDimensions.ranks.six);
  static c6 = new Square(BoardDimensions.files.c, BoardDimensions.ranks.six);
  static d6 = new Square(BoardDimensions.files.d, BoardDimensions.ranks.six);
  static e6 = new Square(BoardDimensions.files.e, BoardDimensions.ranks.six);
  static f6 = new Square(BoardDimensions.files.f, BoardDimensions.ranks.six);
  static g6 = new Square(BoardDimensions.files.g, BoardDimensions.ranks.six);
  static h6 = new Square(BoardDimensions.files.h, BoardDimensions.ranks.six);

  static a7 = new Square(BoardDimensions.files.a, BoardDimensions.ranks.seven);
  static b7 = new Square(BoardDimensions.files.b, BoardDimensions.ranks.seven);
  static c7 = new Square(BoardDimensions.files.c, BoardDimensions.ranks.seven);
  static d7 = new Square(BoardDimensions.files.d, BoardDimensions.ranks.seven);
  static e7 = new Square(BoardDimensions.files.e, BoardDimensions.ranks.seven);
  static f7 = new Square(BoardDimensions.files.f, BoardDimensions.ranks.seven);
  static g7 = new Square(BoardDimensions.files.g, BoardDimensions.ranks.seven);
  static h7 = new Square(BoardDimensions.files.h, BoardDimensions.ranks.seven);

  static a8 = new Square(BoardDimensions.files.a, BoardDimensions.ranks.eight);
  static b8 = new Square(BoardDimensions.files.b, BoardDimensions.ranks.eight);
  static c8 = new Square(BoardDimensions.files.c, BoardDimensions.ranks.eight);
  static d8 = new Square(BoardDimensions.files.d, BoardDimensions.ranks.eight);
  static e8 = new Square(BoardDimensions.files.e, BoardDimensions.ranks.eight);
  static f8 = new Square(BoardDimensions.files.f, BoardDimensions.ranks.eight);
  static g8 = new Square(BoardDimensions.files.g, BoardDimensions.ranks.eight);
  static h8 = new Square(BoardDimensions.files.h, BoardDimensions.ranks.eight);
  //endregion

  //region BASICS
  /**
   * Returns the `Square` with the given `file` and `rank` if it is valid; otherwise returns `null`.
   * @example: `0, 1` -> `Square.a2`; `-1, -1` -> `null`
   */
  static ifValid = (file: number, rank: number): Square | null => {
    switch (file) {
      case BoardDimensions.files.a:
        switch (rank) {
          case BoardDimensions.ranks.one:
            return Square.a1;
          case BoardDimensions.ranks.two:
            return Square.a2;
          case BoardDimensions.ranks.three:
            return Square.a3;
          case BoardDimensions.ranks.four:
            return Square.a4;
          case BoardDimensions.ranks.five:
            return Square.a5;
          case BoardDimensions.ranks.six:
            return Square.a6;
          case BoardDimensions.ranks.seven:
            return Square.a7;
          case BoardDimensions.ranks.eight:
            return Square.a8;
          default:
            return null;
        }

      case BoardDimensions.files.b:
        switch (rank) {
          case BoardDimensions.ranks.one:
            return Square.b1;
          case BoardDimensions.ranks.two:
            return Square.b2;
          case BoardDimensions.ranks.three:
            return Square.b3;
          case BoardDimensions.ranks.four:
            return Square.b4;
          case BoardDimensions.ranks.five:
            return Square.b5;
          case BoardDimensions.ranks.six:
            return Square.b6;
          case BoardDimensions.ranks.seven:
            return Square.b7;
          case BoardDimensions.ranks.eight:
            return Square.b8;
          default:
            return null;
        }

      case BoardDimensions.files.c:
        switch (rank) {
          case BoardDimensions.ranks.one:
            return Square.c1;
          case BoardDimensions.ranks.two:
            return Square.c2;
          case BoardDimensions.ranks.three:
            return Square.c3;
          case BoardDimensions.ranks.four:
            return Square.c4;
          case BoardDimensions.ranks.five:
            return Square.c5;
          case BoardDimensions.ranks.six:
            return Square.c6;
          case BoardDimensions.ranks.seven:
            return Square.c7;
          case BoardDimensions.ranks.eight:
            return Square.c8;
          default:
            return null;
        }

      case BoardDimensions.files.d:
        switch (rank) {
          case BoardDimensions.ranks.one:
            return Square.d1;
          case BoardDimensions.ranks.two:
            return Square.d2;
          case BoardDimensions.ranks.three:
            return Square.d3;
          case BoardDimensions.ranks.four:
            return Square.d4;
          case BoardDimensions.ranks.five:
            return Square.d5;
          case BoardDimensions.ranks.six:
            return Square.d6;
          case BoardDimensions.ranks.seven:
            return Square.d7;
          case BoardDimensions.ranks.eight:
            return Square.d8;
          default:
            return null;
        }

      case BoardDimensions.files.e:
        switch (rank) {
          case BoardDimensions.ranks.one:
            return Square.e1;
          case BoardDimensions.ranks.two:
            return Square.e2;
          case BoardDimensions.ranks.three:
            return Square.e3;
          case BoardDimensions.ranks.four:
            return Square.e4;
          case BoardDimensions.ranks.five:
            return Square.e5;
          case BoardDimensions.ranks.six:
            return Square.e6;
          case BoardDimensions.ranks.seven:
            return Square.e7;
          case BoardDimensions.ranks.eight:
            return Square.e8;
          default:
            return null;
        }

      case BoardDimensions.files.f:
        switch (rank) {
          case BoardDimensions.ranks.one:
            return Square.f1;
          case BoardDimensions.ranks.two:
            return Square.f2;
          case BoardDimensions.ranks.three:
            return Square.f3;
          case BoardDimensions.ranks.four:
            return Square.f4;
          case BoardDimensions.ranks.five:
            return Square.f5;
          case BoardDimensions.ranks.six:
            return Square.f6;
          case BoardDimensions.ranks.seven:
            return Square.f7;
          case BoardDimensions.ranks.eight:
            return Square.f8;
          default:
            return null;
        }

      case BoardDimensions.files.g:
        switch (rank) {
          case BoardDimensions.ranks.one:
            return Square.g1;
          case BoardDimensions.ranks.two:
            return Square.g2;
          case BoardDimensions.ranks.three:
            return Square.g3;
          case BoardDimensions.ranks.four:
            return Square.g4;
          case BoardDimensions.ranks.five:
            return Square.g5;
          case BoardDimensions.ranks.six:
            return Square.g6;
          case BoardDimensions.ranks.seven:
            return Square.g7;
          case BoardDimensions.ranks.eight:
            return Square.g8;
          default:
            return null;
        }

      case BoardDimensions.files.h:
        switch (rank) {
          case BoardDimensions.ranks.one:
            return Square.h1;
          case BoardDimensions.ranks.two:
            return Square.h2;
          case BoardDimensions.ranks.three:
            return Square.h3;
          case BoardDimensions.ranks.four:
            return Square.h4;
          case BoardDimensions.ranks.five:
            return Square.h5;
          case BoardDimensions.ranks.six:
            return Square.h6;
          case BoardDimensions.ranks.seven:
            return Square.h7;
          case BoardDimensions.ranks.eight:
            return Square.h8;
          default:
            return null;
        }

      default:
        return null;
    }
  };

  /**
   * Checks if the `Square` is valid, i.e. if there is a valid square with the given `file` and `rank` exists.
   */
  static isValid = (file: number, rank: number): boolean =>
    file >= BoardDimensions.files.a &&
    file <= BoardDimensions.files.h &&
    rank >= BoardDimensions.ranks.one &&
    rank <= BoardDimensions.ranks.eight;

  /** Returns an ID of the square. */
  get id(): string {
    return this.algebraic;
  }

  /** String representation of the `Square`. */
  toString() {
    return this.algebraic;
  }

  valueOf(): number {
    return this.file * BoardDimensions.files.count + this.rank;
  }
  //endregion

  //region ALGEBRAIC NOTATION
  private static supportedFiles = 'abcdefghABCDEFGH';

  /**
   * Returns the algebraic notation of the `Square`.
   * @example `(file: 0, rank: 3)` -> `"a4"`.
   */
  get algebraic(): string {
    return `${Square.supportedFiles[this.file]}${this.rank + 1}`;
  }

  /**
   * Creates a new `Square` from its algebraic representation.
   * @example: `"a4"` -> `Square.a4`.
   */
  static fromAlgebraic = (algebraicNotation: string): Square | null => {
    const getFileFromAlgebraicNotation = (algebraicFile: string): number => {
      const index = Square.supportedFiles.indexOf(algebraicFile);
      return index % BoardDimensions.files.count;
    };

    const parsedFile = getFileFromAlgebraicNotation(algebraicNotation[0]);
    const parsedRank = parseInt(algebraicNotation[1]) - 1;

    return Square.ifValid(parsedFile, parsedRank);
  };
  //endregion

  //region NAVIGATION
  static all: Set<Square> = new Set([
    Square.a1,
    Square.a2,
    Square.a3,
    Square.a4,
    Square.a5,
    Square.a6,
    Square.a7,
    Square.a8,
    Square.b1,
    Square.b2,
    Square.b3,
    Square.b4,
    Square.b5,
    Square.b6,
    Square.b7,
    Square.b8,
    Square.c1,
    Square.c2,
    Square.c3,
    Square.c4,
    Square.c5,
    Square.c6,
    Square.c7,
    Square.c8,
    Square.d1,
    Square.d2,
    Square.d3,
    Square.d4,
    Square.d5,
    Square.d6,
    Square.d7,
    Square.d8,
    Square.e1,
    Square.e2,
    Square.e3,
    Square.e4,
    Square.e5,
    Square.e6,
    Square.e7,
    Square.e8,
    Square.f1,
    Square.f2,
    Square.f3,
    Square.f4,
    Square.f5,
    Square.f6,
    Square.f7,
    Square.f8,
    Square.g1,
    Square.g2,
    Square.g3,
    Square.g4,
    Square.g5,
    Square.g6,
    Square.g7,
    Square.g8,
    Square.h1,
    Square.h2,
    Square.h3,
    Square.h4,
    Square.h5,
    Square.h6,
    Square.h7,
    Square.h8,
  ]);
  //endregion

  //region BOARD-GEOMETRY
  /**
   * Returns the directly adjacent square to the left (from White's perspective).
   * Example: `e4.left = d4`.
   */
  get left(): Square | null {
    return Square.ifValid(this.file - 1, this.rank);
  }

  /**
   * Returns the directly adjacent square to the right (from White's perspective).
   * Example: `e4.right = f4`.
   */
  get right(): Square | null {
    return Square.ifValid(this.file + 1, this.rank);
  }

  /**
   * Returns the directly adjacent square to the top (from White's perspective).
   * Example: `e4.up = e5`.
   */
  get up(): Square | null {
    return Square.ifValid(this.file, this.rank + 1);
  }

  /**
   * Returns the directly adjacent square to the bottom (from White's perspective).
   * Example: `e4.down = e3`.
   */
  get down(): Square | null {
    return Square.ifValid(this.file, this.rank - 1);
  }
  //endregion

  //region UTILS
  /** Returns a Square from the signature overload. */
  static fromSignatureOverload(
    squareNullOrFile: Square | null | number,
    rank?: number
  ): Square | null {
    if (squareNullOrFile === null) {
      return null;
    }

    let square: Square | null;
    if (typeof squareNullOrFile === 'number' && rank !== undefined) {
      square = Square.ifValid(squareNullOrFile, rank);
    } else {
      square = squareNullOrFile as Square | null;
    }

    if (!square) throw new Error(`Invalid square coordinates: ${squareNullOrFile}, ${rank}`);

    return square;
  }
  //endregion
}
