import { AnimatePresence } from 'framer-motion';

import { Square } from '../../../models/common/Square';
import { DestinationSquares } from '../../../models/position/DestinationSquares';

import { LegalDestinationSquareView } from './LegalDestinationSquareView';

export type LegalDestinationSquaresLayerProps = {
  legalDestinationSquares: DestinationSquares;
  origin: Square | null;
};

export const LegalDestinationSquaresLayer = ({
  legalDestinationSquares,
  origin,
}: LegalDestinationSquaresLayerProps) => {
  // TODO: PROMOTION DOWNWARDS NEEDED?
  return (
    <g>
      <AnimatePresence>
        {Array.from(legalDestinationSquares.nonCastlesNonPromoting()).map((square) => (
          <LegalDestinationSquareView
            key={origin?.id + square.id}
            type="standard"
            square={square}
            originSquare={origin}
          />
        ))}

        {Array.from(legalDestinationSquares.castlesKingside).map((square) => (
          <LegalDestinationSquareView
            key={origin?.id + square.id}
            type="castlesKingside"
            square={square}
            originSquare={origin}
          />
        ))}

        {Array.from(legalDestinationSquares.castlesQueenside).map((square) => (
          <LegalDestinationSquareView
            key={origin?.id + square.id}
            type="castlesQueenside"
            square={square}
            originSquare={origin}
          />
        ))}

        {Array.from(legalDestinationSquares.promotions).map((square) => (
          <LegalDestinationSquareView
            key={origin?.id + square.id}
            type="promoteUpwards"
            square={square}
            originSquare={origin}
          />
        ))}
      </AnimatePresence>
    </g>
  );
};
