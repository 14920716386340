import React from 'react';
import { BrowserRouter } from 'react-router-dom';

import { Router } from './routing/Router';
import { useAuthState } from './services/AccountService/AccountService';

export function App() {
  const authState = useAuthState();

  return (
    <BrowserRouter>
      <Router authState={authState} />
    </BrowserRouter>
  );
}
