/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useEffect, useMemo, useRef, useState } from 'react';

import { Square } from '../../models/common/Square';
import { DestinationSquares } from '../../models/position/DestinationSquares';
import { Position } from '../../models/position/Position';

import { BackgroundLayer } from './Board/BackgroundLayer';
import { BoardLayer } from './Board/BoardLayer';
import { useChessboardDimensionsContext } from './ChessboardDimensionsContext';
import { CurrentDestinationSquareLayer } from './CurrentDestinationSquare/CurrentDestinationSquareLayer';
import { HighlightLayer } from './Highlight/HighlightLayer';
import { LegalDestinationSquaresLayer } from './LegalDestinationSquares/LegalDestinationSquaresLayer';
import { PieceLayer, PieceViewType } from './Pieces/PieceLayer';

export type ChessboardProps = {
  position: Position;
  onMoveRequest: (from: Square, to: Square) => void;
};

const styles = () => ({
  svg: css`
    height: 100%;
    width: 100%;
    touch-action: none;
  `,
});

export const Chessboard = ({ position, onMoveRequest }: ChessboardProps) => {
  const classes = styles();

  const svgRef = useRef<SVGSVGElement>(null);
  const { totalLength, squareLength } = useChessboardDimensionsContext();
  const [highlightedPiece, setHighlightedPiece] = useState<Square | null>(null); // TODO: rename: squareOfPieceInHand?
  const [pieceInHand, setPieceInHand] = useState<Square | null>(null);
  const [draggedOverSquare, setDraggedOverSquare] = useState<Square | null>(null); // TODO: rename: squareOfPieceInHand?
  // TODO: VON AUSSEN??
  const [legalDestinationSquares, setLegalDestinationSquares] = useState<DestinationSquares>(
    DestinationSquares.empty()
  );

  // Clear board state when position changes
  useEffect(() => {
    console.log('POSITION HAS CHANGED: ' + position.getFen().short);
    setHighlightedPiece(null);
    setLegalDestinationSquares(DestinationSquares.empty());
  }, [position]);

  const getSquareFromClientCoordinates = (clientX: number, clientY: number): Square | null => {
    const getSvgCoordinates = (clientX: number, clientY: number): DOMPoint => {
      const domPoint = new DOMPoint(clientX, clientY);
      return domPoint.matrixTransform(svgRef.current?.getScreenCTM()?.inverse());
    };

    const getSquareFromSvgCoordinates = (x: number, y: number): Square | null => {
      const file = squareLength ? Math.floor(x / squareLength) : 0;
      const rank = totalLength && squareLength ? Math.floor((totalLength - y) / squareLength) : 0;
      return Square.ifValid(file, rank);
    };

    const svgCoordinates = getSvgCoordinates(clientX, clientY);
    return getSquareFromSvgCoordinates(svgCoordinates.x, svgCoordinates.y);
  };

  const debugMsg = (message: string) => {
    // Disabled (without a lint warning)
    if (!position) console.log(message);
  };

  const handleOnTouchStart = (clientX: number, clientY: number) => {
    const square = getSquareFromClientCoordinates(clientX, clientY);
    if (square) {
      // The scene has been touched at a valid square. Now we consider the following scenarios:
      //   1) There is a highlighted piece
      //        1.1) The user touches the same (highlighted) piece again
      //                -> unhighlight this piece
      //                -> remove all legal destination highlights
      //        1.2) The user touches any other square except the highlighted piece
      //                1.2.1) The other (= current) square contains a piece which can be grabbed
      //                          -> grab this piece
      //                          -> unhighlight the old piece
      //                          -> highlight legal destination squares of this piece
      //                1.2.2) The other (= current) square does not contain a piece which can be grabbed (because for example it belongs to the opponent)
      //                          -> do nothing
      //   2) There is no highlighted piece
      //        2.1) The touched square contains a piece which can be grabbed
      //                -> grab this piece
      //                -> highlight all legal destination squares of this grabbed piece
      //        2.2) The touched square does not contain a piece which can be grabbed (because for example it belongs to the opponent)
      //                -> do nothing
      if (highlightedPiece) {
        // (1)
        if (square === highlightedPiece) {
          // (1.1)
          debugMsg('DOWN (1.1)');
          // TODO: Will ich das piece unhighlighten?
          setHighlightedPiece(null);
          setLegalDestinationSquares(DestinationSquares.empty); // TODO: WENN GEÄNDERT!?
        } else {
          // (1.2)
          debugMsg('DOWN (1.2)');
          if (position.getLegalDestinationSquares(square).all().size > 0) {
            // TODO: 2x aufruf nach getlegalDestinations? Und delegate nach außen?
            // (1.2.1)
            debugMsg('DOWN (1.2.1)');
            setHighlightedPiece(square);
            setLegalDestinationSquares(position.getLegalDestinationSquares(square));
          } /* else (1.2.2): do nothing */ else {
            debugMsg('DOWN (1.2.2)');
          }
        }
      } else {
        // (2)
        debugMsg('DOWN (2)');
        if (position.getLegalDestinationSquares(square).all().size > 0) {
          // (2.1)
          debugMsg('DOWN (2.1)');
          setHighlightedPiece(square);
          setLegalDestinationSquares(position.getLegalDestinationSquares(square));
        } /* else (2.2): do nothing */
      }
    } else {
      // If the user has not touched any square at all (e.g. on the margin), cancel the highlight and grab
      debugMsg('DOWN (3)');
      setHighlightedPiece(null);
      setLegalDestinationSquares(DestinationSquares.empty);
    }
  };

  const handleOnTouchEnd = (clientX: number, clientY: number) => {
    const square = getSquareFromClientCoordinates(clientX, clientY);
    if (square) {
      // The user has stopped touching the scene at a valid square. Now we consider the following scenarios:
      //   1) The user has previously grabbed a piece which they now let go
      //        1.1) The user drops the piece at the original square (maybe this happens immediately after the user has grabbed it, maybe not - we don't care)
      //                1.1.1) The piece has previously been highlighted
      //                          -> unhighlight the piece
      //                1.1.2) The piece has previously not been highlighted
      //                          -> highlight the piece
      //                          -> put the piece back (the user has probably moved it slightly)
      //        1.2) The user drops the piece anywhere else except on its original square
      //                -> try to move the piece
      //   2) The user has not previously grabbed a piece (their hand is empty)
      //             -> in any case call the delegate
      //        2.1) The user has previously highlighted any piece
      //                2.1.1) The user has previously highlighted the same piece
      //                          -> unhighlight the piece
      //                          -> remove all legal destination squares
      //                2.1.2) The user has previously highlighted any other piece
      //                          -> remove all legal destination squares
      //                          -> unhighlight the piece
      //                          -> try to move the piece
      //                          -> unhighlight the piece
      //        2.2) The user has not previously highlighted any piece
      //                -> ask the delegare if a unique move to the destination square can be performed
      if (pieceInHand) {
        // (1)
        if (square === highlightedPiece) {
          // (1.1)
          if (pieceInHand === highlightedPiece) {
            // (1.1.1)
            debugMsg('UP (1.1.1)');
            setHighlightedPiece(null);
          } else {
            // (1.1.2)
            debugMsg('UP (1.1.2)');
            setHighlightedPiece(pieceInHand);
            // put the piece back (the user has probably moved it slightly)
          }
        } else {
          // (1.2)
          debugMsg('UP (1.2)');
          debugMsg('MOVING!!!');
          onMoveRequest(pieceInHand, square);
        }
      } else {
        // (2)
        if (highlightedPiece) {
          // (2.1)
          if (highlightedPiece === pieceInHand) {
            // (2.1.1)
            debugMsg('UP (2.1.1)');
            setHighlightedPiece(null);
            setLegalDestinationSquares(DestinationSquares.empty);
          } else {
            // (2.1.2)

            // TODO: HIER STIMMT DIE BESCHREIBUNG NICHT MEHR (neuer Use case: einfacher Klick auf ein Piece)
            if (highlightedPiece === square) {
              // (2.1.2.1)
              debugMsg('UP (2.1.2.1)');
              //setHighlightedPiece(null);
              //setLegalDestinationSquares(DestinationSquares.empty);
            } else {
              // (2.1.2.2)
              debugMsg('UP (2.1.2.2)');
              onMoveRequest(highlightedPiece, square);
            }
          }
        } else {
          // (2.2)
          debugMsg('UP (2.2)');
          // TODO: XXX
          debugMsg('CAN WE MOVE HERE?');
          // onMoveRequest(highlightedPiece, square);
        }
      }
    } else {
      // If the user stops the touch at no valid square at all (e.g. on the margin), cancel the highlight and grab
      debugMsg('UP (3)');
      setHighlightedPiece(null);
      setLegalDestinationSquares(DestinationSquares.empty);
    }
  };

  const handleOnMouseMove = (clientX: number, clientY: number) => {
    if (highlightedPiece) {
      const square = getSquareFromClientCoordinates(clientX, clientY);
      setDraggedOverSquare(square);
    }
  };

  /*
  console.log(
    `PIECE IN HAND: ${pieceInHand} /  HIGHLIGHT: ${highlightedPiece} / DRAGGED  OVER: ${draggedOverSquare}`
  );*/

  const legalDestinationSquaresForPieceInHand = useMemo(
    () => (pieceInHand ? position.getLegalDestinationSquares(pieceInHand).all() : new Set()),
    [pieceInHand, position]
  );

  const pieces: Map<Square, PieceViewType> = useMemo(
    () =>
      new Map(
        Array.from(position.board.pieces()).map(([square, identifiablePiece]) => [
          square,
          {
            identifiablePiece,
            isDraggable:
              identifiablePiece.piece.color.player === position.sideToMove &&
              position.getLegalDestinationSquares(square).all().size > 0, // TODO: F ROM OUTSIDE?
          },
        ])
      ),
    [position]
  );

  return totalLength ? (
    <svg
      viewBox={`0 0 ${totalLength} ${totalLength}`}
      css={classes.svg}
      ref={svgRef}
      onMouseDown={(e) => handleOnTouchStart(e.clientX, e.clientY)}
      onMouseMove={(e) => handleOnMouseMove(e.clientX, e.clientY)}
      onMouseUp={(e) => handleOnTouchEnd(e.clientX, e.clientY)}
      onTouchStart={(e) => handleOnTouchStart(e.touches[0].clientX, e.touches[0].clientY)}
      onTouchMove={(e) => handleOnMouseMove(e.touches[0].clientX, e.touches[0].clientY)}
      onTouchEnd={(e) => {
        // Without the following workaround, the event would fire twice (after touching a piece and immediately releasing it again)
        e.preventDefault();
        handleOnTouchEnd(e.changedTouches[0].clientX, e.changedTouches[0].clientY);
      }}
    >
      <BackgroundLayer />

      <BoardLayer />

      <CurrentDestinationSquareLayer
        square={
          pieceInHand && legalDestinationSquaresForPieceInHand.has(draggedOverSquare)
            ? draggedOverSquare
            : null
        }
      />

      <HighlightLayer square={highlightedPiece} />

      <PieceLayer
        pieces={pieces}
        onDragStart={(square) => setPieceInHand(square)}
        onDragEnd={() => setPieceInHand(null)}
      />

      <LegalDestinationSquaresLayer
        legalDestinationSquares={legalDestinationSquares}
        origin={highlightedPiece}
      />
    </svg>
  ) : null;
};
