import { Typography } from '@mui/material';
import React from 'react';

import { Link } from '../../../../components/Link/Link';
import { useSetDocumentTitle } from '../../../../hooks/useSetDocumentTitle/useSetDocumentTitle';
import { MainContainer } from '../components/Container/MainContainer';

export const PrivacyPolicy = () => {
  useSetDocumentTitle('Privacy Policy | King’s Cross');

  return (
    <MainContainer>
      <article>
        <Typography variant="h3" component="h1">
          Privacy Policy
        </Typography>

        <section>
          <Typography variant="h4" component="h2">
            Owner and Data Controller
          </Typography>
          <address>
            <Typography variant="body1" component="span">
              Andreas Kienle | Windeckstr. 15 | 68163 Mannheim
              <br />
            </Typography>
            <Typography variant="body1" component="span">
              Germany
            </Typography>
          </address>
          <Typography variant="body1">
            <Link href="mailto:privacy@kingscross-app.com">privacy@kingscross-app.com</Link>
          </Typography>
        </section>

        <section>
          <Typography variant="h4" component="h2">
            Types of Data Collected
          </Typography>
          <Typography variant="body1">
            Among the types of Personal Data that this Application collects, by itself or through
            third parties, there are: email address; password; Usage Data; Cookies; unique device
            identifiers for advertising (Google Advertiser ID or IDFA, for example).
          </Typography>
          <Typography variant="body1">
            Complete details on each type of Personal Data collected are provided in the dedicated
            sections of this privacy policy or by specific explanation texts displayed prior to the
            Data collection. Personal Data may be freely provided by the User, or, in case of Usage
            Data, collected automatically when using this Application. Unless specified otherwise,
            all Data requested by this Application is mandatory and failure to provide this Data may
            make it impossible for this Application to provide its services. In cases where this
            Application specifically states that some Data is not mandatory, Users are free not to
            communicate this Data without consequences to the availability or the functioning of the
            Service. Users who are uncertain about which Personal Data is mandatory are welcome to
            contact the Owner. Any use of Cookies – or of other tracking tools – by this Application
            or by the owners of third-party services used by this Application serves the purpose of
            providing the Service required by the User, in addition to any other purposes described
            in the present document and in the Cookie Policy, if available.
          </Typography>
          <Typography variant="body1">
            Users are responsible for any third-party Personal Data obtained, published or shared
            through this Application and confirm that they have the third party’s consent to provide
            the Data to the Owner.
          </Typography>
        </section>

        <section>
          <Typography variant="h4" component="h2">
            Mode and place of processing the Data
          </Typography>

          <Typography variant="h5" component="h3">
            Methods of processing
          </Typography>
          <Typography variant="body1">
            The Owner takes appropriate security measures to prevent unauthorized access,
            disclosure, modification, or unauthorized destruction of the Data. The Data processing
            is carried out using computers and/or IT enabled tools, following organizational
            procedures and modes strictly related to the purposes indicated. In addition to the
            Owner, in some cases, the Data may be accessible to certain types of persons in charge,
            involved with the operation of this Application (administration, sales, marketing,
            legal, system administration) or external parties (such as third-party technical service
            providers, mail carriers, hosting providers, IT companies, communications agencies)
            appointed, if necessary, as Data Processors by the Owner. The updated list of these
            parties may be requested from the Owner at any time.
          </Typography>

          <Typography variant="h5" component="h3">
            Legal basis of processing
          </Typography>
          <Typography variant="body1">
            The Owner may process Personal Data relating to Users if one of the following applies:
          </Typography>
          <ul>
            <li>
              Users have given their consent for one or more specific purposes. Note: Under some
              legislations the Owner may be allowed to process Personal Data until the User objects
              to such processing (“opt-out”), without having to rely on consent or any other of the
              following legal bases. This, however, does not apply, whenever the processing of
              Personal Data is subject to European data protection law;
            </li>
            <li>
              provision of Data is necessary for the performance of an agreement with the User
              and/or for any pre-contractual obligations thereof;
            </li>
            <li>
              processing is necessary for compliance with a legal obligation to which the Owner is
              subject;
            </li>
            <li>
              processing is related to a task that is carried out in the public interest or in the
              exercise of official authority vested in the Owner;
            </li>
            <li>
              processing is necessary for the purposes of the legitimate interests pursued by the
              Owner or by a third party.
            </li>
          </ul>
          <Typography variant="body1">
            In any case, the Owner will gladly help to clarify the specific legal basis that applies
            to the processing, and in particular whether the provision of Personal Data is a
            statutory or contractual requirement, or a requirement necessary to enter into a
            contract.
          </Typography>

          <Typography variant="h5" component="h3">
            Place
          </Typography>
          <Typography variant="body1">
            The Data is processed at the Owner’s operating offices and in any other places where the
            parties involved in the processing are located.
          </Typography>
          <Typography variant="body1">
            Depending on the User’s location, data transfers may involve transferring the User’s
            Data to a country other than their own. To find out more about the place of processing
            of such transferred Data, Users can check the section containing details about the
            processing of Personal Data.
          </Typography>
          <Typography variant="body1">
            Users are also entitled to learn about the legal basis of Data transfers to a country
            outside the European Union or to any international organization governed by public
            international law or set up by two or more countries, such as the UN, and about the
            security measures taken by the Owner to safeguard their Data.
          </Typography>
          <Typography variant="body1">
            If any such transfer takes place, Users can find out more by checking the relevant
            sections of this document or inquire with the Owner using the information provided in
            the contact section.
          </Typography>

          <Typography variant="h5" component="h3">
            Retention time
          </Typography>
          <Typography variant="body1">
            Personal Data shall be processed and stored for as long as required by the purpose they
            have been collected for.
          </Typography>
          <Typography variant="body1">Therefore:</Typography>
          <ul>
            <li>
              Personal Data collected for purposes related to the performance of a contract between
              the Owner and the User shall be retained until such contract has been fully performed.
            </li>
            <li>
              Personal Data collected for the purposes of the Owner’s legitimate interests shall be
              retained as long as needed to fulfill such purposes. Users may find specific
              information regarding the legitimate interests pursued by the Owner within the
              relevant sections of this document or by contacting the Owner.
            </li>
          </ul>
          <Typography variant="body1">
            The Owner may be allowed to retain Personal Data for a longer period whenever the User
            has given consent to such processing, as long as such consent is not withdrawn.
            Furthermore, the Owner may be obliged to retain Personal Data for a longer period
            whenever required to do so for the performance of a legal obligation or upon order of an
            authority.
          </Typography>

          <Typography variant="body1">
            Once the retention period expires, Personal Data shall be deleted. Therefore, the right
            to access, the right to erasure, the right to rectification and the right to data
            portability cannot be enforced after expiration of the retention period.
          </Typography>
        </section>

        <section>
          <Typography variant="h4" component="h2">
            The purposes of processing
          </Typography>
          <Typography variant="body1">
            The Data concerning the User is collected to allow the Owner to provide its Service,
            comply with its legal obligations, respond to enforcement requests, protect its rights
            and interests (or those of its Users or third parties), detect any malicious or
            fraudulent activity, as well as the following: Registration and authentication,
            Analytics, Commercial affiliation, Platform services and hosting, Hosting and backend
            infrastructure, Infrastructure monitoring, Content performance and features testing (A/B
            testing).
          </Typography>
          <Typography variant="body1">
            For specific information about the Personal Data used for each purpose, the User may
            refer to the section “Detailed information on the processing of Personal Data”.
          </Typography>
        </section>

        <section>
          <Typography variant="h4" component="h2">
            Detailed information on the processing of Personal Data
          </Typography>
          <Typography variant="h5" component="h3">
            Analytics
          </Typography>
          <Typography variant="body1">
            The services contained in this section enable the Owner to monitor and analyze web
            traffic and can be used to keep track of User behavior.
          </Typography>

          <Typography variant="h6" component="h4">
            Display Advertising extension for Google Analytics
          </Typography>
          <Typography variant="body1">
            Google Analytics on this Application might use Google’s Interest-based advertising,
            3rd-party audience data and information from the DoubleClick Cookie to extend analytics
            with demographics, interests and ads interaction data.
          </Typography>
          <Typography variant="body1">Personal Data processed: Cookies; Usage Data.</Typography>

          <Typography variant="body1">
            Place of processing: United States –{' '}
            <Link href="https://policies.google.com/privacy">Privacy Policy</Link> –{' '}
            <Link href="https://tools.google.com/dlpage/gaoptout">Opt Out</Link>; Ireland –{' '}
            <Link href="https://policies.google.com/privacy">Privacy Policy</Link>.
          </Typography>

          <Typography variant="h6" component="h4">
            Google Analytics for Firebase
          </Typography>
          <Typography variant="body1">
            Google Analytics for Firebase or Firebase Analytics is an analytics service provided by
            Google LLC or by Google Ireland Limited, depending on the location this Application is
            accessed from.
            <br />
            In order to understand Google’s use of Data, consult Google’s{' '}
            <Link href="https://www.google.com/policies/privacy/partners/">partner policy</Link>.
            Firebase Analytics may share Data with other tools provided by Firebase, such as Crash
            Reporting, Authentication, Remote Config or Notifications. The User may check this
            privacy policy to find a detailed explanation about the other tools used by the Owner.
            This Application uses identifiers for mobile devices and technologies similar to cookies
            to run the Firebase Analytics service. Users may opt-out of certain Firebase features
            through applicable device settings, such as the device advertising settings for mobile
            phones or by following the instructions in other Firebase related sections of this
            privacy policy, if available.
          </Typography>
          <Typography variant="body1">
            Personal Data processed: Cookies; unique device identifiers for advertising (Google
            Advertiser ID or IDFA, for example); Usage Data.
          </Typography>
          <Typography variant="body1">
            Place of processing: United States –{' '}
            <Link href="https://policies.google.com/privacy">Privacy Policy</Link>; Ireland –{' '}
            <Link href="https://policies.google.com/privacy">Privacy Policy</Link>.
          </Typography>

          <Typography variant="h6" component="h4">
            Google Analytics (Google Ireland Limited)
          </Typography>
          <Typography variant="body1">
            Google Analytics is a web analysis service provided by Google Ireland Limited
            (“Google”). Google utilizes the Data collected to track and examine the use of this
            Application, to prepare reports on its activities and share them with other Google
            services.
            <br />
            Google may use the Data collected to contextualize and personalize the ads of its own
            advertising network.
          </Typography>
          <Typography variant="body1">Personal Data processed: Cookies; Usage Data.</Typography>
          <Typography variant="body1">
            Place of processing: Ireland –{' '}
            <Link href="https://policies.google.com/privacy">Privacy Policy</Link> –{' '}
            <Link href="https://tools.google.com/dlpage/gaoptout?hl=en">Opt Out</Link>.
          </Typography>

          <Typography variant="h6" component="h4">
            Google Analytics with anonymized IP (Google Ireland Limited)
          </Typography>
          <Typography variant="body1">
            Google Analytics is a web analysis service provided by Google Ireland Limited
            (“Google”). Google utilizes the Data collected to track and examine the use of this
            Application, to prepare reports on its activities and share them with other Google
            services.
            <br />
            Google may use the Data collected to contextualize and personalize the ads of its own
            advertising network.
            <br />
            This integration of Google Analytics anonymizes your IP address. It works by shortening
            Users’ IP addresses within member states of the European Union or in other contracting
            states to the Agreement on the European Economic Area. Only in exceptional cases will
            the complete IP address be sent to a Google server and shortened within the US.
          </Typography>
          <Typography variant="body1">Personal Data processed: Cookies; Usage Data.</Typography>
          <Typography variant="body1">
            Place of processing: Ireland –{' '}
            <Link href="https://policies.google.com/privacy">Privacy Policy</Link> –{' '}
            <Link href="https://tools.google.com/dlpage/gaoptout?hl=en">Opt Out</Link>.
          </Typography>

          <Typography variant="h5" component="h3">
            Content performance and features testing (A/B testing)
          </Typography>
          <Typography variant="body1">
            The services contained in this section allow the Owner to track and analyze the User
            response concerning web traffic or behavior regarding changes to the structure, text or
            any other component of this Application.
          </Typography>
          <Typography variant="h6" component="h4">
            Firebase Remote Config
          </Typography>
          <Typography variant="body1">
            Firebase Remote Config is an A/B testing and configuration service provided by Google
            LLC or by Google Ireland Limited, depending on the location this Application is accessed
            from.
          </Typography>
          <Typography variant="body1">
            Personal Data processed: various types of Data as specified in the privacy policy of the
            service.
          </Typography>
          <Typography variant="body1">
            Place of processing: United States –{' '}
            <Link href="https://www.google.com/policies/privacy/">Privacy Policy</Link>; Ireland –{' '}
            <Link href="https://policies.google.com/privacy">Privacy Policy</Link>.
          </Typography>

          <Typography variant="h5" component="h3">
            Displaying content from external platforms
          </Typography>
          <Typography variant="body1">
            This type of service allows you to view content hosted on external platforms directly
            from the pages of this Application and interact with them.
            <br />
            This type of service might still collect web traffic data for the pages where the
            service is installed, even when Users do not use it.
          </Typography>

          <Typography variant="h6" component="h4">
            Google Fonts (Google Ireland Limited)
          </Typography>
          <Typography variant="body1">
            Google Fonts is a typeface visualization service provided by Google Ireland Limited that
            allows this Application to incorporate content of this kind on its pages.
          </Typography>
          <Typography variant="body1">
            Personal Data processed: Usage Data; various types of Data as specified in the privacy
            policy of the service.
          </Typography>
          <Typography variant="body1">
            Place of processing: Ireland –{' '}
            <Link href="https://policies.google.com/privacy">Privacy Policy</Link>.
          </Typography>

          <Typography variant="h5" component="h3">
            Hosting and backend infrastructure
          </Typography>
          <Typography variant="body1">
            This type of service has the purpose of hosting data and files that enable this
            Application to run and be distributed as well as to provide a ready-made infrastructure
            to run specific features or parts of this Application. Some of these services work
            through geographically distributed servers, making it difficult to determine the actual
            location where the Personal Data are stored.
          </Typography>

          <Typography variant="h6" component="h4">
            Firebase Realtime Database
          </Typography>
          <Typography variant="body1">
            Firebase Realtime Database is a hosting and backend service provided by Google LLC or by
            Google Ireland Limited, depending on the location this Application is accessed from.
          </Typography>
          <Typography variant="body1">
            Personal Data processed: Usage Data; various types of Data as specified in the privacy
            policy of the service.
          </Typography>
          <Typography variant="body1">
            Place of processing: United States –{' '}
            <Link href="https://policies.google.com/privacy">Privacy Policy</Link>; Ireland –{' '}
            <Link href="https://policies.google.com/privacy">Privacy Policy</Link>.
          </Typography>

          <Typography variant="h5" component="h3">
            Infrastructure monitoring
          </Typography>
          <Typography variant="body1">
            This type of service allows this Application to monitor the use and behavior of its
            components so its performance, operation, maintenance and troubleshooting can be
            improved.
            <br />
            Which Personal Data are processed depends on the characteristics and mode of
            implementation of these services, whose function is to filter the activities of this
            Application.
          </Typography>

          <Typography variant="h6" component="h4">
            Firebase Performance Monitoring
          </Typography>
          <Typography variant="body1">
            Firebase Performance Monitoring is a monitoring service provided by Google LLC or by
            Google Ireland Limited, depending on the location this Application is accessed from.
          </Typography>
          <Typography variant="body1">
            Personal Data processed: various types of Data as specified in the privacy policy of the
            service.
          </Typography>
          <Typography variant="body1">
            Place of processing: United States –{' '}
            <Link href="https://policies.google.com/privacy">Privacy Policy</Link>; Ireland –{' '}
            <Link href="https://policies.google.com/privacy">Privacy Policy</Link>.
          </Typography>

          <Typography variant="h6" component="h4">
            Crashlytics
          </Typography>
          <Typography variant="body1">
            Crashlytics is a monitoring service provided by Google LLC or by Google Ireland Limited,
            depending on the location this Application is accessed from.
          </Typography>
          <Typography variant="body1">
            Personal Data processed: geographic position; unique device identifiers for advertising
            (Google Advertiser ID or IDFA, for example); various types of Data as specified in the
            privacy policy of the service.
          </Typography>
          <Typography variant="body1">
            Place of processing: United States –{' '}
            <Link href="https://policies.google.com/privacy">Privacy Policy</Link>; Ireland –{' '}
            <Link href="https://policies.google.com/privacy">Privacy Policy</Link>.
          </Typography>

          <Typography variant="h5" component="h3">
            Registration and authentication
          </Typography>
          <Typography variant="body1">
            By registering or authenticating, Users allow this Application to identify them and give
            them access to dedicated services.
            <br />
            Depending on what is described below, third parties may provide registration and
            authentication services. In this case, this Application will be able to access some
            Data, stored by these third party services, for registration or identification purposes.
          </Typography>

          <Typography variant="h6" component="h4">
            Firebase Authentication
          </Typography>
          <Typography variant="body1">
            Firebase Authentication is a registration and authentication service provided by Google
            Inc. To simplify the registration and authentication process, Firebase Authentication
            can make use of third party identity providers and save the information on its platform.
          </Typography>
          <Typography variant="body1">Personal Data processed: email address; password.</Typography>
          <Typography variant="body1">
            Place of processing: United States –{' '}
            <Link href="https://policies.google.com/privacy">Privacy Policy</Link>; Ireland –{' '}
            <Link href="https://policies.google.com/privacy">Privacy Policy</Link>.
          </Typography>
        </section>

        <section>
          <Typography variant="h4" component="h2">
            Further information about Personal Data
          </Typography>

          <Typography variant="h5" component="h3">
            Unique device identification
          </Typography>
          <Typography variant="body1">
            This Application may track Users by storing a unique identifier of their device, for
            analytics purposes or for storing Users’ preferences.
          </Typography>
        </section>

        <section>
          <Typography variant="h4" component="h2">
            The rights of Users
          </Typography>
          <Typography variant="body1">
            Users may exercise certain rights regarding their Data processed by the Owner.
          </Typography>

          <Typography variant="body1">
            In particular, Users have the right to do the following:
          </Typography>
          <ul>
            <li>
              Withdraw their consent at any time. Users have the right to withdraw consent where
              they have previously given their consent to the processing of their Personal Data.
            </li>
            <li>
              Object to processing of their Data. Users have the right to object to the processing
              of their Data if the processing is carried out on a legal basis other than consent.
              Further details are provided in the dedicated section below.
            </li>
            <li>
              Access their Data. Users have the right to learn if Data is being processed by the
              Owner, obtain disclosure regarding certain aspects of the processing and obtain a copy
              of the Data undergoing processing.
            </li>
            <li>
              Verify and seek rectification. Users have the right to verify the accuracy of their
              Data and ask for it to be updated or corrected.
            </li>
            <li>
              Restrict the processing of their Data. Users have the right, under certain
              circumstances, to restrict the processing of their Data. In this case, the Owner will
              not process their Data for any purpose other than storing it.
            </li>
            <li>
              Have their Personal Data deleted or otherwise removed. Users have the right, under
              certain circumstances, to obtain the erasure of their Data from the Owner.
            </li>
            <li>
              Receive their Data and have it transferred to another controller. Users have the right
              to receive their Data in a structured, commonly used and machine readable format and,
              if technically feasible, to have it transmitted to another controller without any
              hindrance. This provision is applicable provided that the Data is processed by
              automated means and that the processing is based on the User’s consent, on a contract
              which the User is part of or on pre-contractual obligations thereof.
            </li>
            <li>
              Lodge a complaint. Users have the right to bring a claim before their competent data
              protection authority.
            </li>
          </ul>
          <Typography variant="h5" component="h3">
            Details about the right to object to processing
          </Typography>
          <Typography variant="body1">
            Where Personal Data is processed for a public interest, in the exercise of an official
            authority vested in the Owner or for the purposes of the legitimate interests pursued by
            the Owner, Users may object to such processing by providing a ground related to their
            particular situation to justify the objection.
          </Typography>
          <Typography variant="body1">
            Users must know that, however, should their Personal Data be processed for direct
            marketing purposes, they can object to that processing at any time without providing any
            justification. To learn, whether the Owner is processing Personal Data for direct
            marketing purposes, Users may refer to the relevant sections of this document.
          </Typography>

          <Typography variant="h5" component="h3">
            How to exercise these rights
          </Typography>
          <Typography variant="body1">
            Any requests to exercise User rights can be directed to the Owner through the contact
            details provided in this document. These requests can be exercised free of charge and
            will be addressed by the Owner as early as possible and always within one month.
          </Typography>
        </section>

        <section>
          <Typography variant="h4" component="h2">
            Additional information about Data collection and processing
          </Typography>
          <Typography variant="h5" component="h3">
            Legal action
          </Typography>
          <Typography variant="body1">
            The User’s Personal Data may be used for legal purposes by the Owner in Court or in the
            stages leading to possible legal action arising from improper use of this Application or
            the related Services.
            <br />
            The User declares to be aware that the Owner may be required to reveal personal data
            upon request of public authorities.
          </Typography>

          <Typography variant="h5" component="h3">
            Additional information about User’s Personal Data
          </Typography>
          <Typography variant="body1">
            In addition to the information contained in this privacy policy, this Application may
            provide the User with additional and contextual information concerning particular
            Services or the collection and processing of Personal Data upon request.
          </Typography>

          <Typography variant="h5" component="h3">
            System logs and maintenance
          </Typography>
          <Typography variant="body1">
            For operation and maintenance purposes, this Application and any third-party services
            may collect files that record interaction with this Application (System logs) use other
            Personal Data (such as the IP Address) for this purpose.
          </Typography>

          <Typography variant="h5" component="h3">
            Information not contained in this policy
          </Typography>
          <Typography variant="body1">
            More details concerning the collection or processing of Personal Data may be requested
            from the Owner at any time. Please see the contact information at the beginning of this
            document.
          </Typography>
          <Typography variant="h5" component="h3">
            How “Do Not Track” requests are handled
          </Typography>
          <Typography variant="body1">
            This Application does not support “Do Not Track” requests.
            <br />
            To determine whether any of the third-party services it uses honor the “Do Not Track”
            requests, please read their privacy policies.
          </Typography>

          <Typography variant="h5" component="h3">
            Changes to this privacy policy
          </Typography>
          <Typography variant="body1">
            The Owner reserves the right to make changes to this privacy policy at any time by
            notifying its Users on this page and possibly within this Application and/or - as far as
            technically and legally feasible - sending a notice to Users via any contact information
            available to the Owner. It is strongly recommended to check this page often, referring
            to the date of the last modification listed at the bottom.
          </Typography>

          <Typography variant="body1">
            Should the changes affect processing activities performed on the basis of the User’s
            consent, the Owner shall collect new consent from the User, where required.
          </Typography>
        </section>

        <section>
          <Typography variant="h4" component="h2">
            Last update
          </Typography>
          <Typography variant="body1">
            Latest update: <time dateTime="2020-12-10">10 December 2020</time>.
          </Typography>
        </section>
      </article>
    </MainContainer>
  );
};
