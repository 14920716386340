import { PieceType } from '../common/PieceType';

enum PawnPromotionType {
  Queen = 'Queen',
  Rook = 'Rook',
  Bishop = 'Bishop',
  Knight = 'Knight',
}

export class PawnPromotion {
  constructor(private readonly type: PawnPromotionType) {}

  //region VALUES
  /** Promotion to a Rook. */
  static rook = new PawnPromotion(PawnPromotionType.Rook);

  /** Promotion to a Bishop. */
  static bishop = new PawnPromotion(PawnPromotionType.Bishop);

  /** Promotion to a Knight. */
  static knight = new PawnPromotion(PawnPromotionType.Knight);

  /** Promotion to a Queen. */
  static queen = new PawnPromotion(PawnPromotionType.Queen);
  //endregion

  //region BASICS
  /** String representation of the promotion type. */
  toString(): string {
    return this.type;
  }
  //endregion

  //region GAME-RELATED
  static fromPieceType(pieceType: PieceType | null): PawnPromotion | null {
    switch (pieceType) {
      case PieceType.rook:
        return PawnPromotion.rook;
      case PieceType.bishop:
        return PawnPromotion.bishop;
      case PieceType.knight:
        return PawnPromotion.knight;
      case PieceType.queen:
        return PawnPromotion.queen;
      default:
        return null;
    }
  }

  get pieceType(): PieceType {
    switch (this.type) {
      case PawnPromotionType.Rook:
        return PieceType.rook;
      case PawnPromotionType.Bishop:
        return PieceType.bishop;
      case PawnPromotionType.Knight:
        return PieceType.knight;
      case PawnPromotionType.Queen:
        return PieceType.queen;
      default:
        throw new Error(`Unknown promotion type: '${this.type}'.`);
    }
  }
  //endregion

  //region NOTATION
  private static algebraicNotationRook = '=R';
  private static algebraicNotationKnight = '=N';
  private static algebraicNotationBishop = '=B';
  private static algebraicNotationQueen = '=Q';

  static fromAlgebraicSymbol(algebraicSymbol: string): PawnPromotion | null {
    switch (algebraicSymbol.toLocaleUpperCase()) {
      case PawnPromotion.algebraicNotationRook:
        return PawnPromotion.rook;
      case PawnPromotion.algebraicNotationKnight:
        return PawnPromotion.knight;
      case PawnPromotion.algebraicNotationBishop:
        return PawnPromotion.bishop;
      case PawnPromotion.algebraicNotationQueen:
        return PawnPromotion.queen;
      default:
        return null;
    }
  }

  static fromAlgebraicString(algebraicString: string): PawnPromotion | null {
    if (algebraicString.toLocaleUpperCase().includes(PawnPromotion.algebraicNotationQueen)) {
      return PawnPromotion.queen;
    }
    if (algebraicString.toLocaleUpperCase().includes(PawnPromotion.algebraicNotationRook)) {
      return PawnPromotion.rook;
    }
    if (algebraicString.toLocaleUpperCase().includes(PawnPromotion.algebraicNotationKnight)) {
      return PawnPromotion.knight;
    }
    if (algebraicString.toLocaleUpperCase().includes(PawnPromotion.algebraicNotationBishop)) {
      return PawnPromotion.bishop;
    }
    return null;
  }

  get algebraicSymbol(): string {
    switch (this.type) {
      case PawnPromotionType.Rook:
        return PawnPromotion.algebraicNotationRook;
      case PawnPromotionType.Bishop:
        return PawnPromotion.algebraicNotationBishop;
      case PawnPromotionType.Knight:
        return PawnPromotion.algebraicNotationKnight;
      case PawnPromotionType.Queen:
        return PawnPromotion.algebraicNotationQueen;
      default:
        throw new Error(`Unknown promotion type: '${this.type}'.`);
    }
  }
  //endregion
}
