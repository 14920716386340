/**
 * Standard format to describe a chess position.
 * @see https://en.wikipedia.org/wiki/Forsyth%E2%80%93Edwards_Notation
 * @see https://www.chessprogramming.org/FEN
 */
import { StringUtils } from '../../../../../utils/StringUtils';
import { Square } from '../common/Square';

import { Board } from './Board';
import { CastlingRights } from './CastlingRights';
import { Player } from './Player';

export class Fen {
  constructor(
    public readonly board: Board,
    public readonly sideToMove: Player,
    public readonly castlingRights: CastlingRights,
    public readonly enPassantTargetSquare: Square | null,
    public readonly halfMoveClock: number | null,
    public readonly fullMoveCounter: number | null
  ) {}

  //region BASICS
  /** Checks equality of two `Fen`s.
   *  Two `Fen`s are considered equal if they have the same properties. */
  equals(other: Fen | null): boolean {
    return (
      !!other &&
      this.board.equals(other.board, true) &&
      this.sideToMove === other.sideToMove &&
      this.castlingRights.equals(other.castlingRights) &&
      this.enPassantTargetSquare === other.enPassantTargetSquare &&
      this.halfMoveClock === other.halfMoveClock &&
      this.fullMoveCounter === other.fullMoveCounter
    );
  }
  //endregion

  //region NOTATION
  private static FenGroupDelimiter = ' ';

  /** Creates a `Fen` from a `fenString`. */
  static fromFenString(fenString: string): Fen | null {
    // Split the String into (at least) 4 groups
    const fenGroups = fenString.split(Fen.FenGroupDelimiter);
    if (fenGroups.length < 4) return null;

    // Parse the Board
    const board = Board.fromFenSymbol(fenGroups[0]);
    if (!board) return null;

    // Parse the Player
    const sideToMove = Player.fromFenSymbol(fenGroups[1]);
    if (!sideToMove) return null;

    // Parse the CastlingRights
    const castlingRights = CastlingRights.fromFenSymbol(fenGroups[2]);
    if (!castlingRights) return null;

    // Parse the enPassantTargetSquare
    const enPassantTargetSquare = Square.fromAlgebraic(fenGroups[3]);

    // Parse the halfMoveClock
    const halfMoveClock =
      fenGroups.length >= 5 && StringUtils.isPositiveInteger(fenGroups[4])
        ? parseInt(fenGroups[4])
        : null;

    // Parse the fullMoveCounter
    const fullMoveCounter =
      fenGroups.length >= 6 && StringUtils.isPositiveInteger(fenGroups[5])
        ? parseInt(fenGroups[5])
        : null;

    return new Fen(
      board,
      sideToMove,
      castlingRights,
      enPassantTargetSquare,
      halfMoveClock,
      fullMoveCounter
    );
  }

  /** Describes a chess position as a one-line ASCII-string without halfMove clock and fullMove counter. */
  get short(): string {
    return [
      this.board.fenSymbol,
      this.sideToMove.fenSymbol,
      this.castlingRights.fenSymbol,
      this.enPassantTargetSquare ? this.enPassantTargetSquare.algebraic : '-',
    ].join(Fen.FenGroupDelimiter);
  }

  /** Describes a chess position as a one-line ASCII-string including the halfMove clock and fullMove counter. */
  get long(): string {
    return [
      this.short,
      this.halfMoveClock ? this.halfMoveClock.toString() : '0',
      this.fullMoveCounter ? this.fullMoveCounter.toString() : '1',
    ].join(Fen.FenGroupDelimiter);
  }
  //endregion

  //region DATABASE
  /** FEN representation in Firebase. */
  get fbString(): string {
    // "/" is an illegal character in Firebase → escape with "|"
    return StringUtils.dbCompatible(this.short);
  }

  /** Initializes a `Fen` from its representation in Firebase. */
  static fromFbString(fbString: string): Fen | null {
    const trueFenString = fbString.replaceAll('|', '/');
    return Fen.fromFenString(trueFenString);
  }
  //endregion

  //region CONSTANTS
  /** Standard starting position. */
  static startingPositionString = 'rnbqkbnr/pppppppp/8/8/8/8/PPPPPPPP/RNBQKBNR w KQkq - 0 1';

  /** Standard starting position. */
  static startingPosition(): Fen {
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return Fen.fromFenString(Fen.startingPositionString)!;
  }
  //endregion
}
