import { getDatabase, orderByChild, query, ref } from 'firebase/database';
import { useEffect, useState } from 'react';
import { useList } from 'react-firebase-hooks/database';

import {
  OpeningInRepertoire,
  openingInRepertoireFromSnapshot,
} from '../../features/private/repertoires/repertoire/model/OpeningInRepertoire/OpeningInRepertoire';

export const useRepertoire = () => {
  // const TESTUSER = 'BADa113EY2bMd9UgD5SVYlDkmF42';
  //const ANDREASKIENLE = 'GNkkKaQw8ndZ64Dmi9wsHrpOPi43';
  const user = 'BADa113EY2bMd9UgD5SVYlDkmF42';
  const [openings, setOpenings] = useState<OpeningInRepertoire[]>([]);

  const database = getDatabase();
  const openingsInRepertoireRef = ref(database, `/private/${user}/repertoires/main/openings`);
  const sortedOpeningsInRepertoireQuery = query(openingsInRepertoireRef, orderByChild('sort'));

  const [repertoireSnapshots, repertoireLoading, repertoireError] = useList(
    sortedOpeningsInRepertoireQuery
  );
  console.log(repertoireError);

  useEffect(() => {
    if (!repertoireLoading && repertoireSnapshots) {
      const openingsInRepertoire: OpeningInRepertoire[] = [];

      repertoireSnapshots.forEach((openingInRepertoireSnapshot) => {
        try {
          openingsInRepertoire.push(
            openingInRepertoireFromSnapshot(
              // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
              openingInRepertoireSnapshot.key!,
              openingInRepertoireSnapshot.val()
            )
          );
        } catch (e) {
          console.error('Error in useRepertoire', e); // TODO: disable on prod
        }
      });

      setOpenings(openingsInRepertoire);
    }
  }, [repertoireLoading, repertoireSnapshots]);

  return { openings, isLoading: repertoireLoading };
};
