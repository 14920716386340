import { AnimatePresence } from 'framer-motion';

import { Square } from '../../../models/common/Square';

import { HighlightView } from './HighlightView';

export type HighlightLayerProps = {
  square: Square | null;
};

export const HighlightLayer = ({ square }: HighlightLayerProps) => {
  return (
    <g>
      <AnimatePresence>
        {!!square && <HighlightView key={square.id} square={square} />}
      </AnimatePresence>
    </g>
  );
};
