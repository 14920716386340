export const BoardDimensions = {
  ranks: {
    /** The first rank.
     *  Note: This is irrespective of the actual flipped state of the board on the UI. */
    one: 0,

    /** The second rank.
     *  Note: This is irrespective of the actual flipped state of the board on the UI. */
    two: 1,

    /** The third rank.
     *  Note: This is irrespective of the actual flipped state of the board on the UI. */
    three: 2,

    /** The fourth rank.
     *  Note: This is irrespective of the actual flipped state of the board on the UI. */
    four: 3,

    /** The fifth rank.
     *  Note: This is irrespective of the actual flipped state of the board on the UI. */
    five: 4,

    /** The sixth rank.
     *  Note: This is irrespective of the actual flipped state of the board on the UI. */
    six: 5,

    /** The seventh rank.
     *  Note: This is irrespective of the actual flipped state of the board on the UI. */
    seven: 6,

    /** The eighth rank.
     *  Note: This is irrespective of the actual flipped state of the board on the UI. */
    eight: 7,

    /** Number of ranks. */
    count: 8,

    /** The White Pawn rank.
     *  Note: This is irrespective of the actual flipped state of the board on the UI. */
    whitePawn: 1,

    /** The Black Pawn rank.
     *  Note: This is irrespective of the actual flipped state of the board on the UI. */
    blackPawn: 6,

    /** The White back rank.
     *  Note: This is irrespective of the actual flipped state of the board on the UI. */
    whiteBack: 0,

    /** The Black back rank.
     *  Note: This is irrespective of the actual flipped state of the board on the UI. */
    blackBack: 7,
  },

  files: {
    /** The a-file. */
    a: 0,

    /** The b-file. */
    b: 1,

    /** The c-file. */
    c: 2,

    /** The d-file. */
    d: 3,

    /** The e-file. */
    e: 4,

    /** The f-file. */
    f: 5,

    /** The g-file. */
    g: 6,

    /** The h-file. */
    h: 7,

    /** Number of files. */
    count: 8,
  },

  /** Length of the board. */
  length: 8,
};
