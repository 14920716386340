import { StringUtils } from '../../../../../utils/StringUtils';
import { PieceColor } from '../common/PieceColor';
import { PieceType } from '../common/PieceType';
import { Square } from '../common/Square';
import { UiLanguage } from '../common/UiLanguage';
import { CastlingSide } from '../position/CastlingSide';
import { Player } from '../position/Player';
import { Position } from '../position/Position';

import { BasicMove } from './BasicMove';
import { CaptureSymbol } from './CaptureSymbol';
import { CheckSymbol } from './CheckSymbol';
import { MoveAnnotation } from './MoveAnnotation';
import { Movement } from './Movement';
import { MoveNotationUiType } from './MoveNotationUiType';
import { PawnPromotion } from './PawnPromotion';

export class MoveNotation {
  annotation = MoveAnnotation.none;

  private constructor(
    public readonly pieceType: PieceType,
    public readonly from: Square | null,
    public readonly to: Square | null,
    public readonly isCapture: boolean,
    public readonly isEnPassantCapture: boolean,
    public readonly castlingSide: CastlingSide | null,
    public readonly pawnPromotion: PawnPromotion | null,
    public readonly isCheck: boolean,
    public readonly isCheckmate: boolean
  ) {}

  //region BASICS
  /** Returns a `MoveNotation` if it is valid. */
  static ifValid(
    pieceType: PieceType,
    from: Square | null,
    to: Square | null,
    isCapture: boolean,
    isEnPassantCapture: boolean,
    castlingSide: CastlingSide | null,
    pawnPromotion: PawnPromotion | null,
    isCheck: boolean,
    isCheckmate: boolean
  ): MoveNotation | null {
    if (
      MoveNotation.isValid(
        pieceType,
        from,
        to,
        isCapture,
        isEnPassantCapture,
        castlingSide,
        pawnPromotion,
        isCheck,
        isCheckmate
      )
    ) {
      return new MoveNotation(
        pieceType,
        from,
        to,
        isCapture,
        isEnPassantCapture,
        castlingSide,
        pawnPromotion,
        isCheck,
        isCheckmate
      );
    } else {
      return null;
    }
  }

  /**
   * Checks if the intended `MoveNotation` is valid.
   * The following conditions are checked:
   *   - For non-castling moves, are the `from` and `to` squares specified?  (`castlingSide == nil` => `from, to != nil`)
   *   - For castles, is the moving Piece a King?  (`castlingSide != nil` => `pieceType == .king`)
   *   - For en passant captures, is the moving Piece a Pawn?  (`enPassantCapture == true` => `pieceType == .pawn`)
   *   - For en passant captures, is the move marked as capture?  (`enPassantCapture == true` => `capture == true`)
   *   - For checkmates, is the move marked as check?  (`isCheckmate == true` => `isCheck == true`)
   *   - For Pawn promotions, is the moving Piece a Pawn?  (`pawnPromotion != nil` => `pieceType == .pawn`)
   */
  static isValid(
    pieceType: PieceType,
    from: Square | null,
    to: Square | null,
    isCapture: boolean,
    isEnPassantCapture: boolean,
    castlingSide: CastlingSide | null,
    pawnPromotion: PawnPromotion | null,
    isCheck: boolean,
    isCheckmate: boolean
  ): boolean {
    // castlingSide === null => from, to != null
    // castlingSide !== null => pieceType == .king
    if (castlingSide && pieceType !== PieceType.king) return false;
    if (!castlingSide && (!from || !to)) return false;

    // enPassantCapture == true => pieceType == .pawn
    // enPassantCapture == true => capture == true
    if (isEnPassantCapture && (pieceType !== PieceType.pawn || !isCapture)) return false;

    // isCheckmate == true => isCheck == true
    if (isCheckmate && !isCheck) return false;

    // pawnPromotion != nil => pieceType == .pawn
    return !(pawnPromotion && pieceType !== PieceType.pawn);
  }

  /** Checks equality of two `MoveNotation`s.
   *  Two `MoveNotation`s are considered equal if they have the same properties. */
  equals(other: MoveNotation | null): boolean {
    return (
      !!other &&
      other.pieceType === this.pieceType &&
      other.from === this.from &&
      other.to === this.to &&
      other.isCapture === this.isCapture &&
      other.isEnPassantCapture === this.isEnPassantCapture &&
      other.castlingSide === this.castlingSide &&
      other.pawnPromotion === this.pawnPromotion &&
      other.isCheck === this.isCheck &&
      other.isCheckmate === this.isCheckmate &&
      other.annotation === this.annotation
    );
  }
  //endregion

  //region NOTATION
  /** Returns the long UI representation. */
  longUiRepresentation(
    type: MoveNotationUiType,
    language: UiLanguage,
    sideToMove: Player,
    includeAnnotation: boolean,
    includePawnSymbol: boolean,
    includeEllipsis: boolean
  ): string {
    const figurine = this.longFigurine(
      sideToMove,
      includeAnnotation,
      includePawnSymbol,
      includeEllipsis
    );

    switch (type) {
      case MoveNotationUiType.figurineAlgebraic:
        return figurine;
      case MoveNotationUiType.algebraic:
        return MoveNotation.replaceWithUiAlgebraic(figurine, language);
      default:
        throw new Error(`Unknown MoveNotationUiType: '${type}'.`);
    }
  }

  /** Returns the short UI representation. */
  shortUiRepresentation(
    type: MoveNotationUiType,
    language: UiLanguage,
    position: Position,
    includeAnnotation: boolean,
    includePawnSymbol: boolean,
    includeEllipsis: boolean,
    fullMoveCounter: number | null
  ): string {
    const figurine = this.shortFigurine(
      position,
      includeAnnotation,
      includePawnSymbol,
      includeEllipsis,
      fullMoveCounter
    );

    switch (type) {
      case MoveNotationUiType.figurineAlgebraic:
        return figurine;
      case MoveNotationUiType.algebraic:
        return MoveNotation.replaceWithUiAlgebraic(figurine, language);
      default:
        throw new Error(`Unknown MoveNotationUiType: '${type}'.`);
    }
  }

  //region LONG/SHORT ALGEBRAIC NOTATION
  /**
   * Creates a `MoveNotation` from a `lanString` in long algebraic notation.
   * @returns: `null` if the `lanString` is invalid.
   */
  static fromLanString(lanString: string): MoveNotation | null {
    const checkSymbol = CheckSymbol.fromMoveNotationString(lanString);
    const moveAnnotation = MoveAnnotation.fromMoveNotationString(lanString);
    const castlingSide = CastlingSide.fromString(lanString);

    if (castlingSide) {
      // Castling
      const moveNotation = new MoveNotation(
        PieceType.king,
        null,
        null,
        false,
        false,
        castlingSide,
        null,
        checkSymbol.isCheck,
        checkSymbol.isCheckmate
      );
      moveNotation.annotation = moveAnnotation;
      return moveNotation;
    } else {
      // Standard move
      let workingNotation = lanString; // the string we work with
      if (workingNotation.length < 4) return null;

      // Determine PieceType and get rid of the first character
      let pieceType: PieceType | null;
      if (StringUtils.isPositiveInteger(workingNotation[1])) {
        // We are dealing with a pawn move ("e1-e2") where the first letter is not the piece
        pieceType = PieceType.pawn;
      } else {
        // Non-pawn move
        pieceType = PieceType.fromFenSymbol(workingNotation[0]);
        if (!pieceType) return null;
        workingNotation = workingNotation.substring(1);
      }

      // The rest has to be at least as long as to hold "a3-a4"
      if (workingNotation.length < 4) return null;

      // Determine "actual" move and get rid of the first 5 characters
      const from = Square.fromAlgebraic(workingNotation.substring(0, 2));
      const captureSymbol = CaptureSymbol.fromMoveNotationString(workingNotation);
      const to = Square.fromAlgebraic(workingNotation.substring(3, 5));
      if (!from || !to) return null;
      workingNotation = workingNotation.substring(5);

      // Check for CheckSymbol
      const checkSymbol = CheckSymbol.fromMoveNotationString(workingNotation);

      // Check for pawn promotion
      const pawnPromotion = PawnPromotion.fromAlgebraicString(workingNotation);

      const moveNotation = new MoveNotation(
        pieceType,
        from,
        to,
        captureSymbol.isCapture,
        captureSymbol.isEnPassantCapture,
        null,
        pawnPromotion,
        checkSymbol.isCheck,
        checkSymbol.isCheckmate
      );

      moveNotation.annotation = moveAnnotation;
      return moveNotation;
    }
  }

  /**
   * Returns the long algebraic notation.
   * Example: `"Rd2-d4"`
   */
  longAlgebraic(includeAnnotation = true): string {
    let notation = '';
    if (this.castlingSide) {
      // Castling
      notation += this.castlingSide.algebraicSymbol;
    } else {
      // Standard move
      const captureSymbol = CaptureSymbol.from(this.isCapture, this.isEnPassantCapture);
      if (this.pieceType !== PieceType.pawn) {
        notation += this.pieceType.algebraic;
      }
      notation += this.from?.algebraic;
      notation += captureSymbol.moveNotationInfixSymbol;
      notation += this.to?.algebraic;
      notation += captureSymbol.moveNotationSuffixSymbol;
      if (this.pawnPromotion) {
        notation += this.pawnPromotion.algebraicSymbol;
      }
    }

    const checkSymbol = CheckSymbol.from(this.isCheck, this.isCheckmate);
    notation += checkSymbol.moveNotationSymbol;

    if (includeAnnotation && this.annotation) {
      notation += this.annotation.moveNotationSymbol;
    }
    return notation;
  }

  /**
   * Returns the short algebraic notation.
   * Example: `"Rd4"`
   * @param position Current position (needed to disambiguate the moves)
   * @param includeAnnotation Suffixes annotation (e.g. !!)
   */
  shortAlgebraic(position: Position, includeAnnotation: boolean): string {
    let notation = '';

    if (this.castlingSide) {
      // Castling
      notation += this.castlingSide.algebraicSymbol;
    } else {
      // Standard move
      if (this.pieceType !== PieceType.pawn) {
        notation += this.pieceType.algebraic;

        // For non-pawns we have to include the rank/file if the move would be ambiguous
        const otherAmbiguousMoves = position
          .getLegalMoves()
          .filter(
            (move) =>
              move.to === this.to &&
              move.from !== this.from &&
              move.piece.type === this.pieceType &&
              move.piece.color === position.sideToMove.pieceColor
          );

        if (otherAmbiguousMoves.length > 0) {
          // More than one Piece can reach the Square
          // Let's see if we need to disambiguate by file, rank or both
          const otherAmbiguousMovesWithSameFile = otherAmbiguousMoves.filter(
            (move) => move.from.file === this.from?.file
          );

          if (otherAmbiguousMovesWithSameFile.length === 0) {
            // Disambiguate by file
            notation += this.from?.algebraic[0];
          } else {
            const otherAmbiguousMovesWithSameRank = otherAmbiguousMoves.filter(
              (move) => move.from.rank === this.from?.rank
            );

            if (otherAmbiguousMovesWithSameRank.length === 0) {
              // Disambiguate by rank
              notation += this.from?.algebraic[1];
            } else {
              // Disambiguate by both
              notation += this.from?.algebraic;
            }
          }
        }
      } else {
        // Pawns are prefixed with the file if they capture
        if (this.isCapture) {
          notation += this.from?.algebraic[0];
        }
      }

      const captureSymbol = CaptureSymbol.from(this.isCapture, this.isEnPassantCapture);
      if (this.isCapture) {
        notation += captureSymbol.moveNotationInfixSymbol;
      }
      notation += this.to?.algebraic;
      notation += captureSymbol.moveNotationSuffixSymbol;
      notation += this.pawnPromotion?.algebraicSymbol ?? '';
    }

    const checkSymbol = CheckSymbol.from(this.isCheck, this.isCheckmate);
    notation += checkSymbol.moveNotationSymbol;

    if (includeAnnotation) {
      notation += this.annotation.moveNotationSymbol;
    }

    return notation;
  }
  //endregion

  /**
   * Returns the long algebraic notation.
   * Example: ♕h2-h6+
   * @param sideToMove Indicates what color the Pieces have (♖ vs. ♜).
   * @param includeAnnotation Suffixes annotation (e.g. !!)
   * @param includePawnSymbol Indicates whether the Pawn symbol should be included (e.g. ♙ vs. ♟).
   * @param includeEllipsis Prefixes Black’s moves with `…`.
   */
  longFigurine(
    sideToMove: Player,
    includeAnnotation: boolean,
    includePawnSymbol: boolean,
    includeEllipsis: boolean
  ): string {
    let notation = '';

    if (includeEllipsis && sideToMove.pieceColor === PieceColor.black) {
      notation += '…';
    }

    if (includePawnSymbol && this.pieceType === PieceType.pawn) {
      notation += this.pieceType.algebraic;
    }

    notation += this.longAlgebraic(includeAnnotation);

    return MoveNotation.replaceWithFigurine(notation, sideToMove);
  }

  /**
   * Returns the long algebraic notation.
   * Example: ♕h2-h6+
   * @param position Current position (needed to disambiguate the moves and to choose the correct piece color)
   * @param includeAnnotation Suffixes annotation (e.g. !!)
   * @param includePawnSymbol Indicates whether the Pawn symbol should be included (e.g. ♙ vs. ♟).
   * @param includeEllipsis Prefixes Black’s moves with `…`.
   * @param fullMoveCounter: Adds the full move counter in front of the move.
   */
  shortFigurine(
    position: Position,
    includeAnnotation: boolean,
    includePawnSymbol: boolean,
    includeEllipsis: boolean,
    fullMoveCounter: number | null
  ): string {
    let notation = '';

    // Prefix (e.g. "1." or "2…")
    if (fullMoveCounter) {
      notation += fullMoveCounter.toString();

      if (!includeEllipsis || position.sideToMove === Player.white) {
        notation += '.';
      }
    }

    if (includeEllipsis && position.sideToMove === Player.black) {
      notation += '…';
    }

    if (includePawnSymbol && this.pieceType === PieceType.pawn) {
      notation += PieceType.pawn.algebraic;
    }

    notation += this.shortAlgebraic(position, includeAnnotation);

    return MoveNotation.replaceWithFigurine(notation, position.sideToMove);
  }

  /**
   * Replaces all pieces in the given `str` with their figurine notation
   * Examples: `"Nde2"` (white) -> `"♘de2"`
   *           `"e8=Q"` (black) -> `"e8=♛"`
   */
  static replaceWithFigurine(str: string, sideToMove: Player): string {
    const pieceColor = sideToMove.pieceColor;
    let figurine = str;
    PieceType.all().forEach((pieceType) => {
      figurine = figurine.replaceAll(pieceType.algebraic, pieceType.figurine(pieceColor));
    });

    return figurine;
  }

  /**
   * Replaces all figurine pieces in the given `str` with their algebraic notation
   * Examples: `"♘de2"` (white) -> `"Nde2"` (English)
   *           `"e8=♛"` (black) -> `"e8=D"` (German)
   */
  static replaceWithUiAlgebraic(str: string, language: UiLanguage): string {
    let uiAlgebraic = str;
    PieceType.all().forEach((pieceType) => {
      uiAlgebraic = uiAlgebraic
        .replaceAll(pieceType.figurine(PieceColor.white), pieceType.uiAlgebraic(language))
        .replaceAll(pieceType.figurine(PieceColor.black), pieceType.uiAlgebraic(language));
    });

    return uiAlgebraic;
  }
  //endregion

  /** Returns a `BasicMove` (for castling, the King's movement is returned). */
  basicMove(sideToMove: Player): BasicMove {
    let movement: Movement;

    if (this.castlingSide) {
      movement = this.castlingSide.getKingMovement(sideToMove);
    } else {
      if (!this.from || !this.to) {
        throw new Error(
          `Move without 'from' or 'to'. Make sure to validate the move before calling this method.`
        );
      }
      movement = new Movement(this.from, this.to);
    }
    return new BasicMove(movement, this.pawnPromotion);
  }
}
