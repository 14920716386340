import { Square } from '../common/Square';

import { Movement } from './Movement';
import { PawnPromotion } from './PawnPromotion';

export class BasicMove {
  constructor(
    public readonly movement: Movement,
    public readonly pawnPromotion: PawnPromotion | null = null
  ) {}

  //region BASICS
  /** Checks equality of two `BasicMove`s.
   *  Two `BasicMove`s are considered equal if they have the same properties. */
  equals(other: BasicMove | null): boolean {
    return (
      !!other && this.movement.equals(other.movement) && this.pawnPromotion === other.pawnPromotion
    );
  }
  //endregion

  //region CONVENIENCE PROPERTIES
  /** Old square.
   *  Note: For castles the King’s movement is relevant. */
  get from(): Square {
    return this.movement.from;
  }

  /** New square.
   *  Note: For castles the King’s movement is relevant. */
  get to(): Square {
    return this.movement.to;
  }
  //endregion
}
