/**
 * Color of an Opening.
 */
export enum OpeningColor {
  /** White. */
  White = 'White',
  /** Black. */
  Black = 'Black',
}

const fbStringWhite = 'white';
const fbStringBlack = 'black';

/**
 * Converts an `OpeningColor` to a Firebase string.
 * @throws An error if the Firebase string is invalid.
 */
export const openingColorFromFbString = (fbString: string): OpeningColor => {
  switch (fbString) {
    case fbStringWhite:
      return OpeningColor.White;
    case fbStringBlack:
      return OpeningColor.Black;
    default:
      throw new Error(`Invalid OpeningColor: '${fbString}'`);
  }
};

/**
 * Converts an `OpeningColor` to a Firebase string.
 */
export const openingColorToFbString = (openingColor: OpeningColor): string => {
  switch (openingColor) {
    case OpeningColor.White:
      return fbStringWhite;
    case OpeningColor.Black:
      return fbStringBlack;
  }
};

// TODO: TEST
export const cssColorFromOpeningColor = (openingColor: OpeningColor): string => {
  switch (openingColor) {
    case OpeningColor.White:
      return '#fff';
    case OpeningColor.Black:
      return '#000';
  }
};
