/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from '@emotion/react';
import { Link as MuiLink, LinkProps as MuiLinkProps } from '@mui/material';
import { ReactNode } from 'react';
import { Link as RouterLink } from 'react-router-dom';

export type LinkDesign = 'underlinedOnHover' | 'static' | 'text';

export type LinkProps = MuiLinkProps & {
  to?: string;
  design?: LinkDesign;
  children: ReactNode;
  styles?: SerializedStyles;
};

const styles = (design: LinkDesign) => ({
  link: css`
    color: ${design === 'text' ? '#488868' : 'inherit'};
    text-decoration: inherit;
    border-bottom: solid 2px transparent;
    transition: border-bottom-color 0.1s ease-out, color 0.1s ease-out;

    &:hover {
      border-bottom: ${design === 'underlinedOnHover'
        ? 'solid 2px white'
        : design === 'text'
        ? 'solid 2px #d65050'
        : 'solid 2px transparent'};
      color: ${design === 'text' ? '#d65050' : 'inherit'};
    }
  `,
});

export const Link = ({
  to,
  styles: propStyles,
  design = 'text',
  children,
  ...props
}: LinkProps) => {
  const classes = styles(design);

  return (
    <>
      {to && (
        <MuiLink
          underline="none"
          css={[classes.link, propStyles]}
          component={RouterLink}
          to={to}
          {...props}
        >
          {children}
        </MuiLink>
      )}

      {!to && (
        <MuiLink underline="none" css={[classes.link, propStyles]} {...props}>
          {children}
        </MuiLink>
      )}
    </>
  );
};
