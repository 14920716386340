export class Direction {
  private constructor(public readonly stepFile: number, public readonly stepRank: number) {}

  // straight
  static left = new Direction(-1, 0);
  static right = new Direction(1, 0);
  static up = new Direction(0, 1);
  static down = new Direction(0, -1);

  // diagonally
  static upLeft = new Direction(-1, 1);
  static upRight = new Direction(1, 1);
  static downLeft = new Direction(-1, -1);
  static downRight = new Direction(1, -1);

  // Knight's jump
  static upUpLeft = new Direction(-1, 2);
  static upUpRight = new Direction(1, 2);
  static rightRightUp = new Direction(2, 1);
  static rightRightDown = new Direction(2, -1);
  static downDownLeft = new Direction(-1, -2);
  static downDownRight = new Direction(1, -2);
  static leftLeftUp = new Direction(-2, 1);
  static leftLeftDown = new Direction(-2, -1);
}
