import { motion } from 'framer-motion';
import { useEffect, useRef, useState } from 'react';

import { Square } from '../../../models/common/Square';
import { IdentifiablePiece } from '../../../models/position/IdentifiablePiece';
import { useChessboardDimensionsContext } from '../ChessboardDimensionsContext';

export type PieceViewProps = {
  identifiablePiece: IdentifiablePiece;
  square: Square;
  isDraggable: boolean;
  onDragStart: () => void;
  onDragEnd: () => void;
};

export const PieceView = ({
  identifiablePiece,
  square,
  isDraggable,
  onDragStart,
  onDragEnd,
}: PieceViewProps) => {
  const { boardLength, squareLength, topLeft, isResizing, getCoordinatesOfSquare } =
    useChessboardDimensionsContext();
  let { x, y } = getCoordinatesOfSquare(square);

  const [isDragging, setIsDragging] = useState(false);
  const firstRenderRef = useRef(true);
  const firstRender = firstRenderRef.current ?? true;

  // HACK for the problem that the pieces are animated into their starting position: just disable animation on first render
  useEffect(() => {
    let timeoutId: NodeJS.Timeout | undefined;

    if (firstRenderRef.current && !timeoutId) {
      timeoutId = globalThis.setTimeout(() => {
        firstRenderRef.current = false;
      }, 1000);
    }

    return () => {
      if (timeoutId) globalThis.clearTimeout(timeoutId);
    };
  }, []);

  // HACK: If the coordinates do not change between renders, framer-motion will not animate the piece.
  // Scenario: Drag a piece to a new square on the same file or rank (i.e. no change in x or y).
  x = x + Math.random() / 100;
  y = y + Math.random() / 100;

  const handleDragStart = () => {
    setIsDragging(true);
    onDragStart();
  };

  const handleDragEnd = () => {
    onDragEnd();
    setIsDragging(false);
  };

  return squareLength && boardLength ? (
    <motion.image
      initial={{ x, y }}
      animate={!isDragging ? { x, y } : {}}
      exit={{ opacity: 0 }}
      transition={{ duration: isResizing || isDragging || firstRender ? 0 : 16 / 48 }}
      key={identifiablePiece.id}
      width={squareLength}
      height={squareLength}
      xlinkHref={identifiablePiece.piece.assetName}
      drag={isDraggable}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      dragConstraints={{
        top: topLeft.y,
        left: topLeft.x,
        bottom: boardLength + topLeft.y - squareLength,
        right: boardLength + topLeft.x - squareLength,
      }}
      cursor={isDraggable ? 'grab' : 'default'}
      whileDrag={{ scale: 1.1 }}
      dragElastic={0}
      dragMomentum={false}
      dragSnapToOrigin
      dragTransition={{
        bounceStiffness: 100,
        bounceDamping: 20,
      }}
    />
  ) : null;
};
