/** Shared utility functions for strings. */
export const StringUtils = {
  /** Checks if the given string is a positive integer. */
  isPositiveInteger(str: string): boolean {
    return str.match(/[0-9]/) !== null;
  },

  /// Returns the string in a way which can be used as key in the databas.
  dbCompatible(str: string): string {
    // "/" is an illegal character in Firebase -> escape with "|"
    return str.replaceAll('/', '|');
  },
};
