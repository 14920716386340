import { initializeApp } from 'firebase/app';
import { ReactNode } from 'react';

export const FirebaseProvider = ({ children }: { children: ReactNode }) => {
  const initializeFirebase = () => {
    // noinspection SpellCheckingInspection
    const firebaseConfig = {
      apiKey: 'AIzaSyATXTFdvVVScUjngDopTq6PMh9bjcrQbYg',
      authDomain: 'project-4166017698763095973.firebaseapp.com',
      databaseURL: 'https://project-4166017698763095973.firebaseio.com',
      projectId: 'project-4166017698763095973',
      storageBucket: 'project-4166017698763095973.appspot.com',
      messagingSenderId: '402833457456',
      appId: '1:402833457456:web:fb94474f30366d2c',
      measurementId: 'G-WG8ZQBNV8N',
    };

    initializeApp(firebaseConfig);
  };

  initializeFirebase();

  // console.log('FIREBASE INITIALIZED');

  return <>{children}</>;
};
