import boardBackground from '../../../../../../assets/board-background/board_background@ipad_2.png';

export const BackgroundLayer = () => {
  return (
    <image
      x="0"
      y="0"
      width="100%"
      height="100%"
      xlinkHref={boardBackground}
      preserveAspectRatio="xMidYMid slice"
    />
  );
};
