import { css } from '@emotion/react';
import { Theme, useTheme } from '@mui/material';
import { ReactNode } from 'react';

import { Container } from './Container';

export type MainContainerProps = {
  children: ReactNode;
};

const styles = (theme: Theme) => ({
  container: css`
    padding-top: 2rem;
    padding-bottom: 2rem;

    ${theme.breakpoints.up('lg')} {
      padding-top: 5rem;
      padding-bottom: 5rem;
    }
  `,
});

export const MainContainer = ({ children }: MainContainerProps) => {
  const theme = useTheme();
  const classes = styles(theme);

  return (
    <Container as="main" styles={classes.container}>
      {children}
    </Container>
  );
};
