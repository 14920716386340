import { Typography } from '@mui/material';
import React from 'react';

import { Link } from '../../../../components/Link/Link';
import { useSetDocumentTitle } from '../../../../hooks/useSetDocumentTitle/useSetDocumentTitle';
import { MainContainer } from '../components/Container/MainContainer';

export const TermsOfUse = () => {
  useSetDocumentTitle('Terms of Use | King’s Cross');

  return (
    <MainContainer>
      <article>
        <Typography variant="h3" component="h1">
          Terms of Use
        </Typography>
        <section>
          <Typography variant="h4" component="h2">
            About King’s Cross
          </Typography>
          <Typography variant="body1">
            King’s Cross is a Service available online at, among other places, kingscross-app.com
            and in the Apple App Store as “King’s Cross”. The purpose of King’s Cross is to help our
            users build their chess opening repertoire and record their training progress.
          </Typography>
        </section>
        <section>
          <Typography variant="h4" component="h2">
            Using Our Services
          </Typography>
          <Typography variant="body1">
            To use our Services you must follow some simple rules. The Service is only available for
            your private use and is not intended for commercial use. Do not misuse the Services, so
            that either King’s Cross or anyone else is harmed in any way. You may only use the
            Services as permitted by law and these Terms. By using our Services, you agree that:
          </Typography>
          <ul>
            <li>All of the personal data provided by you is accurate and up to date.</li>
            <li>
              You are solely responsible for all activities on your account and all the content that
              is uploaded and/or created under your King’s Cross account (“User Material”).
            </li>
            <li>
              King’s Cross does not monitor the contents of the Service, but may at any time choose,
              at its sole discretion, to remove User Material from the Service and/or your user
              account and to terminate your account and membership.
            </li>
            <li>
              Your membership, including your email and password, with King’s Cross is personal and
              may not be transferred or used by someone else. You are responsible for storing your
              login details in a safe manner. King’s Cross is not in any way responsible for any
              loss or damage caused by unauthorized access to your account or use of your login
              details. If you learn of or suspect any unauthorized use of your account, you must
              immediately inform King’s Cross for example via{' '}
              <Link href="mailto:privacy@kingscross-app.com">privacy@kingscross-app.com</Link>.
            </li>
            <li>
              King’s Cross is not intended for use by persons under the age of 13. To use King’s
              Cross, you must be at least 13 years old.
            </li>
            <li>
              Violation of any of these Terms will lead to a direct termination of your user account
              and subscription. If you violate these terms, you will not get a refund.
            </li>
            <li>
              You may not engage in any commercial activities, advertise and/or provide hints (such
              as links) on where commercial activities are present through our Services.
            </li>
            <li>
              You may not contribute with any propaganda, religious and/or political views, or
              contribute with information which in any way contains or involves incitement to racial
              hatred, child pornography or pornography through the use of our Services. Further, you
              may not defame, harass or offend other people through the use of our Services.
            </li>
            <li>
              If you have any criticism or feedback regarding King’s Cross or our Services, to
              firstly contact King’s Cross to help us to improve our Services.
            </li>
            <li>
              You may not transmit, and/or distribute files that may damage King’s Cross or others’
              computers or property (such as viruses and trojan horses).
            </li>
            <li>You may not share others’ personal information, without their approval.</li>
          </ul>
        </section>
        <section>
          <Typography variant="h4" component="h2">
            Your Account
          </Typography>
          <Typography variant="body1">
            You can at any time choose to cancel/end your account at your convenience. Please note
            that uninstalling the mobile application or closing your account will not automatically
            stop your subscription — you must actively cancel the subscription. Please note that if
            you have subscribed to King’s Cross through the use of App Store or any other service
            provider, you can only cancel your subscription through the use of their services. To
            find out how to cancel the subscription and/or your account, please follow the
            instructions in the app or on the website. Special terms and conditions apply to King’s
            Cross Premium.
          </Typography>
        </section>
        <section>
          <Typography variant="h4" component="h2">
            King’s Cross Premium
          </Typography>
          <Typography variant="body1">
            This section only applies when you purchase and/or subscribe to King’s Cross Premium or
            other paid products. By paying the subscription fee you get access to King’s Cross
            Premium during the time your subscription is valid, subject to these Terms.
          </Typography>
        </section>
        <section>
          <Typography variant="h4" component="h2">
            All subscriptions with King’s Cross Premium are paid in advance.
          </Typography>
          <Typography variant="body1">
            You may at any time terminate your membership, in which case your membership will still
            be valid for the subscription time you have already paid for. If you have subscribed to
            King’s Cross through the use of App Store or any other such service provider, using
            in-app purchase, you can only cancel your subscription through the use of their
            services.
          </Typography>

          <Typography variant="body1">
            Subscription fees can be found in the mobile application. Special terms and conditions
            may apply. King’s Cross reserves the right to change the subscription fees from time to
            time.
          </Typography>
          <Typography variant="body1">
            You hereby consent to start using the Services immediately upon subscribing with King’s
            Cross Premium or other paid products. All payments handled by Apple, such as in-app
            purchases, can only be refunded by Apple and with Apple’s consent. Default in payment
            shall not be deemed as a termination of a King’s Cross Premium subscription.
          </Typography>
        </section>
        <section>
          <Typography variant="h4" component="h2">
            Intellectual Property
          </Typography>
          <Typography variant="body1">
            All rights in and to the Services, including any trademarks, service marks, trade names
            and copyrighted content (collectively “Intellectual Property”) presented within the
            Service are the property of King’s Cross and/or third parties. You agree not to use
            Intellectual Property for any other purposes except for your use of the Service, unless
            required otherwise by applicable mandatory law.
          </Typography>
          <Typography variant="body1">
            By submitting User Material to King’s Cross, you warrant and represent that you hold the
            copyright, trademark and/or other intellectual property rights to your content. You
            agree to grant King’s Cross a non-exclusive, transferable, sub-licensable, royalty-free,
            worldwide license to use User Material to the extent necessary for King’s Cross to
            operate and maintain the Service. This license shall remain valid until the respective
            User Material is deleted from the Service by you or by King’s Cross in accordance with
            these Terms.
          </Typography>
        </section>
        <section>
          <Typography variant="h4" component="h2">
            Disclaimer and Limitation of Liability
          </Typography>
          <Typography variant="body1">
            You use the Service at your own risk. The Service is provided “AS IS”, without any
            warranties, and King’s Cross does not warrant that the Service and availability thereof
            will be uninterrupted or error free. King’s Cross does not assume any responsibility for
            errors or omissions in the information or software or other documents, including User
            Material, which are referenced by or linked to. References or links to third parties’
            websites are provided “AS IS” without warranty of any kind, either express or implied.
          </Typography>
          <Typography variant="body1">
            In no event shall King’s Cross be liable for any indirect or consequential damages,
            except in cases of intentional misconduct or gross negligence. To the maximum extent
            permitted under applicable law, King’s Cross aggregated liability for any direct damages
            shall be limited to the lesser of: (i) membership fees paid by the user; (ii) membership
            fees paid by the user during the previous calendar year.
          </Typography>
        </section>
        <section>
          <Typography variant="h4" component="h2">
            Miscellaneous
          </Typography>
          <Typography variant="body1">
            For the sake of clarity, King’s Cross, doesn’t undertake the obligation to monitor the
            contents of User Material.
          </Typography>
          <Typography variant="body1">
            You are not entitled to assign your rights and/or obligations under these Terms or use
            of the Service to any third party without King’s Cross’s prior written consent. King’s
            Cross is entitled to assign its rights and/or obligations under these Terms.
          </Typography>
          <Typography variant="body1">
            King’s Cross reserves the right to refuse the Service to anyone for any reason at any
            time. King’s Cross may revise these Terms from time to time and the most current version
            will always be posted on King’s Cross’s website (www.kingscross-app.com). Any and all
            material changes shall become into effect between you and King’s Cross upon your
            acceptance of such changes (e.g. by using the Services after such notification has been
            made to you or renewing your subscription).
          </Typography>
        </section>
        <section>
          <Typography variant="h4" component="h2">
            Governing Law and Dispute Resolution
          </Typography>
          <Typography variant="body1">
            All disputes shall be settled within the law of the Federal Republic of Germany. If and
            when in the regulations above a criminal offence or rights of a third party is referred
            to the law of the German Federal Republic is the only law applicable.
          </Typography>
          <Typography variant="body1">
            The place of jurisdiction for all disputes is Mannheim.
          </Typography>
          <Typography variant="body1">
            If parts of these conditions are not, no longer or not completely in accordance with
            current law, the remaining parts remain untouched in their contents and validity.
          </Typography>
        </section>
        <section>
          <Typography variant="h4" component="h2">
            Contacting Us
          </Typography>
          <Typography variant="body1">
            If you have questions regarding these terms and conditions, you may email{' '}
            <Link href="mailto:privacy@kingscross-app.com">privacy@kingscross-app.com</Link> or find
            more details on the <Link to="/contact">contact</Link> page.
          </Typography>
        </section>
      </article>
    </MainContainer>
  );
};
