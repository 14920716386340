import { PieceColor } from './PieceColor';
import { UiLanguage } from './UiLanguage';

enum PieceTypeType {
  Pawn = 'Pawn',
  Rook = 'Rook',
  Knight = 'Knight',
  Bishop = 'Bishop',
  Queen = 'Queen',
  King = 'King',
}

/**
 * Type of `PieceView`.
 */
export class PieceType {
  private constructor(private readonly type: PieceTypeType) {}

  //region VALUES
  /** Pawn. */
  static pawn = new PieceType(PieceTypeType.Pawn);

  /** Rook. */
  static rook = new PieceType(PieceTypeType.Rook);

  /** Knight. */
  static knight = new PieceType(PieceTypeType.Knight);

  /** Bishop. */
  static bishop = new PieceType(PieceTypeType.Bishop);

  /** Queen. */
  static queen = new PieceType(PieceTypeType.Queen);

  /** King. */
  static king = new PieceType(PieceTypeType.King);
  //endregion

  //region BASICS
  /** Returns a string representation of the `PieceType`. */
  toString(): string {
    return this.type;
  }

  /** Returns an array of all PieceTypes. */
  static all(): PieceType[] {
    return [
      PieceType.pawn,
      PieceType.rook,
      PieceType.knight,
      PieceType.bishop,
      PieceType.queen,
      PieceType.king,
    ];
  }
  //endregion

  //region NOTATION
  private static algebraicNotationSymbolRook = 'R';
  private static algebraicNotationSymbolKnight = 'N';
  private static algebraicNotationSymbolBishop = 'B';
  private static algebraicNotationSymbolQueen = 'Q';
  private static algebraicNotationSymbolKing = 'K';
  private static algebraicNotationSymbolPawn = 'P';

  /**
   * Converts an algebraic representation of a piece type to a `PieceType`.
   * Note: **The function *does* care for the case of the `algebraicSymbol`: Only uppercase strings are supported.**
   */
  static fromAlgebraic(algebraicSymbol: string): PieceType | null {
    switch (algebraicSymbol) {
      case PieceType.algebraicNotationSymbolRook:
        return PieceType.rook;
      case PieceType.algebraicNotationSymbolKnight:
        return PieceType.knight;
      case PieceType.algebraicNotationSymbolBishop:
        return PieceType.bishop;
      case PieceType.algebraicNotationSymbolQueen:
        return PieceType.queen;
      case PieceType.algebraicNotationSymbolKing:
        return PieceType.king;
      case PieceType.algebraicNotationSymbolPawn:
        return PieceType.pawn;
      default:
        return null;
    }
  }

  /**
   * Algebraic representation of the `PieceType`.
   * Example: Queen = `Q`, King = `K`.
   * Note: **The function always returns uppercase strings**.
   */
  get algebraic(): string {
    switch (this.type) {
      case PieceTypeType.Rook:
        return PieceType.algebraicNotationSymbolRook;
      case PieceTypeType.Knight:
        return PieceType.algebraicNotationSymbolKnight;
      case PieceTypeType.Bishop:
        return PieceType.algebraicNotationSymbolBishop;
      case PieceTypeType.Queen:
        return PieceType.algebraicNotationSymbolQueen;
      case PieceTypeType.King:
        return PieceType.algebraicNotationSymbolKing;
      case PieceTypeType.Pawn:
        return PieceType.algebraicNotationSymbolPawn;
      default:
        throw new Error(`Unknown piece type: '${this.type}'.`);
    }
  }

  /**
   * Converts a FEN representation of a piece type to a `PieceType`.
   * Note: **The function does *not* care for the case of the `String`.**
   */
  static fromFenSymbol(fenSymbol: string): PieceType | null {
    return PieceType.fromAlgebraic(fenSymbol.toUpperCase());
  }

  /**
   * FEN representation of the `PieceType`.
   * Example: White Queen = `Q`, Black King = `k`.
   */
  fenSymbol(color: PieceColor): string {
    const preliminarySymbol = this.algebraic;
    return color === PieceColor.white ? preliminarySymbol : preliminarySymbol.toLowerCase();
  }

  /**
   * Returns the figurine symbol for a given color.
   * Example: black rook = ♜.
   */
  figurine(color: PieceColor): string {
    switch (this.type) {
      case PieceTypeType.Rook:
        return color === PieceColor.black ? '♜' : '♖';
      case PieceTypeType.Knight:
        return color === PieceColor.black ? '♞' : '♘';
      case PieceTypeType.Bishop:
        return color === PieceColor.black ? '♝' : '♗';
      case PieceTypeType.Queen:
        return color === PieceColor.black ? '♛' : '♕';
      case PieceTypeType.King:
        return color === PieceColor.black ? '♚' : '♔';
      case PieceTypeType.Pawn:
        return color === PieceColor.black ? '♟' : '♙';
      default:
        throw new Error(`Unknown piece type: '${this.type}'.`);
    }
  }
  //endregion

  //region UI

  /**
   * Algebraic representation of the `PieceType` which can be used on the UI, i.e. which takes the current language into account.
   * Example: Queen = `Q` (English), `D` (German).
   * **The function always returns uppercase `Strings`**.
   */
  uiAlgebraic(language: UiLanguage): string {
    switch (language) {
      case UiLanguage.en:
      case UiLanguage.en_gb:
        switch (this.type) {
          case PieceTypeType.Rook:
            return 'R';
          case PieceTypeType.Knight:
            return 'N';
          case PieceTypeType.Bishop:
            return 'B';
          case PieceTypeType.Queen:
            return 'Q';
          case PieceTypeType.King:
            return 'K';
          case PieceTypeType.Pawn:
            return '';
          default:
            throw new Error(`Unknown piece type: '${this.type}'.`);
        }

      case UiLanguage.de:
        switch (this.type) {
          case PieceTypeType.Rook:
            return 'T';
          case PieceTypeType.Knight:
            return 'S';
          case PieceTypeType.Bishop:
            return 'L';
          case PieceTypeType.Queen:
            return 'D';
          case PieceTypeType.King:
            return 'K';
          case PieceTypeType.Pawn:
            return '';
          default:
            throw new Error(`Unknown piece type: '${this.type}'.`);
        }

      default:
        return this.uiAlgebraic(UiLanguage.en);
    }
  }
  //endregion
}
