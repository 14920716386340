import { Timestamp } from '../../../../models/Timestamp';
import { CompactTrainingResult } from '../../../../training/models/CompactTrainingResult';

/**
 * Last training of an opening in a repertoires.
 */
export type OpeningInRepertoireLastTraining = Pick<
  CompactTrainingResult,
  'numberRightUnits' | 'numberWrongUnits' | 'totalNumberUnits'
> & {
  trainedOn: Timestamp;
  depth: number | null; // TODO: BRAUCHE ICH DAS ÜBERHAUPT??
};

/**
 * Converts a Firebase snapshot to an `OpeningInRepertoireLastTraining`.
 */
export const openingInRepertoireLastTrainingFromSnapshot = (
  snapshot: Record<string, any>
): OpeningInRepertoireLastTraining => {
  if (
    snapshot['trainedOn'] === undefined ||
    snapshot['trainedOn'] === null ||
    typeof snapshot['trainedOn'] !== 'number' ||
    snapshot['numberPositions'] === undefined ||
    snapshot['numberPositions'] === null ||
    typeof snapshot['numberPositions'] !== 'number'
  ) {
    console.log('THROW');
    throw new Error(`Invalid OpeningInRepertoireLastTraining: '${JSON.stringify(snapshot)}'.`);
  }

  console.log('CONTINUE');
  return {
    trainedOn: snapshot['trainedOn'],
    totalNumberUnits: snapshot['numberPositions'],
    numberRightUnits: snapshot['numberRightPositions'] ?? 0,
    numberWrongUnits: snapshot['numberWrongPositions'] ?? 0,
    depth: snapshot['depth'] ?? null,
  };
};
