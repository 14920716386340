/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { IconButton, Menu, MenuItem, SvgIcon } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import * as React from 'react';

import { AppIcon } from '../../../../../components/AppIcon/AppIcon';
import { Link } from '../../../../../components/Link/Link';
import { signOut } from '../../../../../services/AccountService/AccountService';

const styles = () => ({
  container: css`
    height: var(--header-height);
    display: flex;
    justify-content: center;
  `,

  linkWithMargin: css`
    margin-right: 3rem;
  `,

  toolbarWrapper: css`
    & .MuiToolbar-gutters {
      padding-left: 0;
      padding-right: 0;
    }
  `,

  logoContainer: css`
    display: flex;
    align-items: center;
  `,
});

export const Header = () => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const classes = styles();
  const open = Boolean(anchorEl);
  const handleClick = (event: React.ChangeEvent<any>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleSignOut = () => {
    signOut();
    handleClose();
  };

  return (
    <AppBar position="sticky" css={classes.container}>
      <nav css={classes.toolbarWrapper}>
        <Toolbar variant="dense">
          <Link to="/" design="static" styles={classes.linkWithMargin}>
            <div css={classes.logoContainer}>
              <SvgIcon fontSize="large" sx={{ mr: 1 }}>
                <AppIcon />
              </SvgIcon>
              King’s Cross
            </div>
          </Link>

          <IconButton
            size="large"
            edge="end"
            color="inherit"
            aria-label="menu"
            sx={{ ml: 2 }}
            onClick={(e) => handleClick(e)}
          >
            <MoreHorizIcon />
          </IconButton>
          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
          >
            <MenuItem onClick={handleClose}>Settings TODO</MenuItem>
            <MenuItem onClick={handleSignOut}>Logout</MenuItem>
          </Menu>
        </Toolbar>
      </nav>
    </AppBar>
  );
};
