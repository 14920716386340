import React from 'react';
import { Navigate, Outlet, RouteProps } from 'react-router-dom';

import { AuthState } from '../services/AccountService/AccountService';

import { Route } from './Routes';

export type PrivateRouteProps = RouteProps & {
  authState: AuthState;
};

export const PrivateRoute = ({ authState }: PrivateRouteProps) => {
  return (
    <>
      {authState === AuthState.SIGNED_IN && <Outlet />}
      {authState === AuthState.SIGNED_OUT && <Navigate to={Route.Home} />}
    </>
  );
};
