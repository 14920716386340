/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { CssBaseline } from '@mui/material';
import React from 'react';
import { Outlet } from 'react-router-dom';

import { PrivateThemeProvider } from '../PrivateThemeProvider/PrivateThemeProvider';

import { Header } from './components/Header/Header';

// TODO: ROUTER  REFACTORING
const styles = () => ({
  container: css`
    position: relative;
    background-color: #1e140f;
    --header-height: 48px;
    height: 100vh;
  `,
});

export const Private = () => {
  const classes = styles();

  return (
    <PrivateThemeProvider>
      {/* <CssBaseline> needs to be rendered for the PrivateThemeProvider to kick in with the body rules */}
      <CssBaseline />

      <div css={classes.container}>
        <Header />

        <Outlet />
      </div>
    </PrivateThemeProvider>
  );
};
