import { SquareContent } from '../common/BoardGrid';
import { Piece } from '../common/Piece';
import { PieceColor } from '../common/PieceColor';
import { PieceType } from '../common/PieceType';
import { Square } from '../common/Square';

/**
 * Piece which can be tracked across multiple positions.
 * The idea is that when a position is initialized, the pieces are started to be tracked at their starting squares (i.e. their `id` is the algebraic notation of the starting square).
 * If moves are made, the pieces change positions, but their `id` stays the same.
 */
export class IdentifiablePiece implements SquareContent {
  private constructor(readonly id: string, readonly piece: Piece) {}

  /** Color of the piece. */
  get color(): PieceColor {
    return this.piece.color;
  }

  /** Type of the piece. */
  get type(): PieceType {
    return this.piece.type;
  }

  /** Starts the tracking of a piece at the given square. */
  static track(piece: Piece, square: Square): IdentifiablePiece {
    return new IdentifiablePiece(square.algebraic, piece);
  }

  /** Checks equality of two `IdentifiablePiece`s.
   *  Two `IdentifiablePiece`s are considered equal if they have the same piece and the same id. */
  equals(other: IdentifiablePiece | null, compareIds: boolean): boolean {
    return !!other && (!compareIds || this.id === other.id) && this.piece === other.piece;
  }
}
