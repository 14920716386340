import { Square } from '../common/Square';

import { CastlingRight } from './CastlingRight';
import { CastlingSide } from './CastlingSide';
import { Player } from './Player';

export class CastlingRights {
  constructor(readonly rights: Set<CastlingRight> = new Set()) {}

  //region BASICS
  /** Checks equality of two `CastlingRights`.
   *  Two `CastlingRights` are considered equal if they grant the same individual rights. */
  equals(other: CastlingRights | null): boolean {
    return (
      !!other &&
      this.rights.size === other.rights.size &&
      Array.from(this.rights).every((right) => other.rights.has(right))
    );
  }

  /** Returns a new `CastlingRights` with the same rights. */
  clone(): CastlingRights {
    return new CastlingRights(new Set(this.rights));
  }
  //endregion

  //region NOTATION
  private static fenSymbolNone = '-';

  /** FEN representation of the `CastlingRights`. */
  get fenSymbol(): string {
    if (this.rights.size === 0) {
      return CastlingRights.fenSymbolNone;
    }

    let result = '';
    if (this.rights.has(CastlingRight.whiteKingside)) {
      result += CastlingRight.whiteKingside.fenSymbol;
    }
    if (this.rights.has(CastlingRight.whiteQueenside)) {
      result += CastlingRight.whiteQueenside.fenSymbol;
    }
    if (this.rights.has(CastlingRight.blackKingside)) {
      result += CastlingRight.blackKingside.fenSymbol;
    }
    if (this.rights.has(CastlingRight.blackQueenside)) {
      result += CastlingRight.blackQueenside.fenSymbol;
    }
    return result;
  }

  /**
   * Converts a FEN representation of castling rights to `CastlingRights`.
   * @returns: `null` if the FEN symbol is invalid.
   */
  static fromFenSymbol(fenSymbol: string): CastlingRights | null {
    const rights = new Set<CastlingRight>();

    for (const fenCharacter of fenSymbol) {
      if (fenCharacter === CastlingRights.fenSymbolNone) {
        continue;
      }
      const right = CastlingRight.fromFenSymbol(fenCharacter);
      if (!right) {
        return null;
      }

      rights.add(right);
    }

    return new CastlingRights(rights);
  }

  //endregion

  //region CONVENIENCE INITIALIZERS
  /** Initializes the default castling rights for the given `Player`. */
  static ofPlayer(player: Player): CastlingRights {
    return new CastlingRights(
      new Set(
        player === Player.white
          ? [CastlingRight.whiteKingside, CastlingRight.whiteQueenside]
          : [CastlingRight.blackKingside, CastlingRight.blackQueenside]
      )
    );
  }

  /** Returns all castling rights. */
  static all(): CastlingRights {
    return new CastlingRights(
      new Set([
        CastlingRight.whiteKingside,
        CastlingRight.whiteQueenside,
        CastlingRight.blackKingside,
        CastlingRight.blackQueenside,
      ])
    );
  }

  /** Returns no castling rights. */
  static none(): CastlingRights {
    return new CastlingRights();
  }
  //endregion

  //region API
  /** Checks if a single `CastlingRight` is granted. */
  isGranted(right: CastlingRight): boolean {
    return this.rights.has(right);
  }

  /** Checks if the given `Player` can castle to the given side. */
  isGrantedForPlayerToSide(player: Player, side: CastlingSide): boolean {
    switch (player) {
      case Player.white:
        switch (side) {
          case CastlingSide.kingside:
            return this.isGranted(CastlingRight.whiteKingside);
          case CastlingSide.queenside:
            return this.isGranted(CastlingRight.whiteQueenside);
          default:
            throw new Error(`Invalid CastlingSide: '${side}'.`);
        }
      case Player.black:
        switch (side) {
          case CastlingSide.kingside:
            return this.isGranted(CastlingRight.blackKingside);
          case CastlingSide.queenside:
            return this.isGranted(CastlingRight.blackQueenside);
          default:
            throw new Error(`Invalid CastlingSide: '${side}'.`);
        }
      default:
        throw new Error(`Invalid Player: '${player}'.`);
    }
  }

  /** Checks if the given `Player` can castle to any `CastlingSide`. */
  isAnyGrantedForPlayer(player: Player): boolean {
    switch (player) {
      case Player.white:
        return (
          this.isGranted(CastlingRight.whiteKingside) ||
          this.isGranted(CastlingRight.whiteQueenside)
        );
      case Player.black:
        return (
          this.isGranted(CastlingRight.blackKingside) ||
          this.isGranted(CastlingRight.blackQueenside)
        );
      default:
        throw new Error(`Invalid Player: '${player}'.`);
    }
  }

  /** Checks if any `Player` can castle to the given `CastlingSide`. */
  isAnyGrantedToSide(side: CastlingSide): boolean {
    switch (side) {
      case CastlingSide.kingside:
        return (
          this.isGranted(CastlingRight.whiteKingside) || this.isGranted(CastlingRight.blackKingside)
        );
      case CastlingSide.queenside:
        return (
          this.isGranted(CastlingRight.whiteQueenside) ||
          this.isGranted(CastlingRight.blackQueenside)
        );
      default:
        throw new Error(`Invalid CastlingSide: '${side}'.`);
    }
  }

  /** Revokes all rights in the given `CastlingRights`. */
  revoke(rights: CastlingRights): void;
  /** Revokes a single `CastlingRight`. */
  revoke(right: CastlingRight): void;
  revoke(rightsOrRight: CastlingRights | CastlingRight): void {
    if (rightsOrRight instanceof CastlingRight) {
      this.rights.delete(rightsOrRight);
    } else {
      rightsOrRight.rights.forEach((right) => this.rights.delete(right));
    }
  }

  /** Revokes all rights in the given `CastlingRights`. */
  revoked(rights: CastlingRights): CastlingRights;
  /** Revokes a single `CastlingRight`. */
  revoked(right: CastlingRight): CastlingRights;
  revoked(rightsOrRight: CastlingRights | CastlingRight): CastlingRights {
    const newCastlingRights = new CastlingRights(new Set(this.rights));

    if (rightsOrRight instanceof CastlingRight) {
      newCastlingRights.revoke(rightsOrRight);
    } else {
      newCastlingRights.revoke(rightsOrRight);
    }

    return newCastlingRights;
  }

  /** Revokes all rights which are affected by Piece activities (movement to/capture on) the given square. */
  revokeBecauseOfActivityOnSquare(square: Square): void {
    switch (square) {
      case Square.a1:
        this.revoke(CastlingRight.whiteQueenside);
        return;
      case Square.h1:
        this.revoke(CastlingRight.whiteKingside);
        return;
      case Square.a8:
        this.revoke(CastlingRight.blackQueenside);
        return;
      case Square.h8:
        this.revoke(CastlingRight.blackKingside);
        return;
      case Square.e1:
        this.revoke(CastlingRights.ofPlayer(Player.white));
        return;
      case Square.e8:
        this.revoke(CastlingRights.ofPlayer(Player.black));
        return;
    }
  }

  /** Revokes all rights which are affected by Piece activities (movement to/capture on) the given square. */
  revokedBecauseOfActivityOnSquare(square: Square): CastlingRights {
    const newCastlingRights = new CastlingRights(new Set(this.rights));
    newCastlingRights.revokeBecauseOfActivityOnSquare(square);
    return newCastlingRights;
  }
  //endregion
}
