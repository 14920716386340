import { Player } from '../position/Player';

enum PieceColorType {
  White = 'White',
  Black = 'Black',
}

/**
 * Color of a piece.
 */
export class PieceColor {
  private constructor(private readonly type: PieceColorType) {}

  //region VALUES
  /** White. */
  static white = new PieceColor(PieceColorType.White);

  /** Black. */
  static black = new PieceColor(PieceColorType.Black);
  //endregion

  //region BASICS
  /** Returns a string representation of the `PieceColor`. */
  toString(): string {
    return this.type;
  }
  //endregion

  //region GAME-RELATED
  /** Owning `Player`. */
  get player(): Player {
    return this.type === PieceColorType.White ? Player.white : Player.black;
  }

  /** Flips the color `white` ↔ `black`. */
  get flipped(): PieceColor {
    return this.type === PieceColorType.White ? PieceColor.black : PieceColor.white;
  }
  //endregion
}
