/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from '@emotion/react';
import { Theme, useTheme } from '@mui/material';
import { ElementType, ReactNode } from 'react';

export type ContainerProps = {
  as?: ElementType;
  children: ReactNode;
  styles?: SerializedStyles;
};

const styles = (theme: Theme) => ({
  wrapper: css`
    margin-left: auto;
    margin-right: auto;
    padding-left: 1rem;
    padding-right: 1rem;
    overflow: hidden;

    ${theme.breakpoints.up('xs')} {
      width: 100vw;
    }

    ${theme.breakpoints.up('sm')} {
      width: 37.5rem;
    }

    ${theme.breakpoints.up('md')} {
      width: 56.25rem;
    }
    ${theme.breakpoints.up('lg')} {
      width: 75rem;
    }
  `,
});

export const Container = ({
  as: ItemComponent = 'div',
  children,
  styles: propStyles,
}: ContainerProps) => {
  const theme = useTheme();
  const classes = styles(theme);

  return <ItemComponent css={[classes.wrapper, propStyles]}>{children}</ItemComponent>;
};
