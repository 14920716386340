import { Timestamp } from '../features/private/models/Timestamp';

/** Shared utility functions for working with dates. */
export const DateUtils = {
  /** Returns the time interval to another date to be displayed on the UI.
   *  Intervals under one day are abbreviated as "<1d". */
  uiDateDifference(date1: Date, date2: Date): string {
    // Year difference
    const yDiff = Math.abs(date2.getFullYear() - date1.getFullYear());
    if (yDiff > 0) {
      return `${yDiff}y`;
    }

    // Month difference
    const mDiff = Math.abs(date2.getMonth() - date1.getMonth());
    if (mDiff > 0) {
      return `${mDiff}m`;
    }

    // Week difference
    const wDiff = Math.floor(
      Math.abs((date2.getTime() - date1.getTime()) / (24 * 3600 * 1000 * 7))
    );
    if (wDiff > 0) {
      return `${wDiff}w`;
    }

    // Day difference
    const dDiff = Math.abs(date2.getDate() - date1.getDate());
    if (dDiff > 0) {
      return `${dDiff}d`;
    }

    // Hour difference
    const hDiff = Math.abs(date2.getHours() - date1.getHours());
    if (hDiff > 0) {
      return `${hDiff}h`;
    }

    return '<1h';
  },

  /** Initializes a date from the database timestamp. */
  dateFromTimestamp(dbTimestamp: Timestamp): Date {
    return new Date(dbTimestamp);
  },
};
