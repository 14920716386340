/**
 * A chess position.
 * Note: The position has a `Board` but also additional information like the side to move, castling rights etc.
 */
import { Piece } from '../common/Piece';
import { PieceColor } from '../common/PieceColor';
import { PieceType } from '../common/PieceType';
import { Square } from '../common/Square';
import { BasicMove } from '../move/BasicMove';
import { Direction } from '../move/Direction';
import { Move } from '../move/Move';
import { Movement } from '../move/Movement';
import {
  MoveSegmentAdjustCastlingRights,
  MoveSegmentAdjustEnPassantTargetSquare,
  MoveSegmentCapture,
  MoveSegmentMovement,
  MoveSegmentPromotion,
} from '../move/MoveSegment';
import { PawnPromotion } from '../move/PawnPromotion';
import { RenderedMove } from '../move/RenderedMove';
import { Repeat } from '../move/Repeat';

import { Board } from './Board';
import { CastlingRights } from './CastlingRights';
import { CastlingSide } from './CastlingSide';
import { DestinationSquares } from './DestinationSquares';
import { Fen } from './Fen';
import { IdentifiablePiece } from './IdentifiablePiece';
import { Player } from './Player';
import { PositionDbKey } from './PositionDbKey';

export class Position {
  constructor(
    public readonly board: Board,
    public sideToMove: Player,
    public castlingRights: CastlingRights,
    public enPassantTargetSquare: Square | null = null
  ) {}

  //region BASICS
  /** Checks equality of two `Position`s.
   *  Two `Position`s are considered equal if they have equal properties. */
  equals(other: Position | null, comparePieceIds: boolean): boolean {
    return (
      !!other &&
      this.sideToMove === other.sideToMove &&
      this.enPassantTargetSquare === other.enPassantTargetSquare &&
      this.castlingRights.equals(other.castlingRights) &&
      this.board.equals(other.board, comparePieceIds)
    );
  }

  /** Returns a new `Position` with the same properties as this one. */
  clone(): Position {
    return new Position(
      this.board.clone(),
      this.sideToMove,
      this.castlingRights.clone(),
      this.enPassantTargetSquare
    );
  }
  //endregion

  //region FEN
  /** Standard starting position. */
  static start(): Position {
    return Position.fromFen(Fen.startingPosition());
  }

  /** Constructs a position from `Fen`. */
  static fromFen(fen: Fen): Position {
    return new Position(fen.board, fen.sideToMove, fen.castlingRights, fen.enPassantTargetSquare);
  }

  /** Conveniently constructs a position from a FEN string. */
  static fromFenString(fenString: string): Position | null {
    const fen = Fen.fromFenString(fenString);
    if (!fen) {
      return null;
    }

    return Position.fromFen(fen);
  }

  /** Returns the position as `Fen`. */
  getFen(): Fen {
    return new Fen(
      this.board,
      this.sideToMove,
      this.castlingRights,
      this.enPassantTargetSquare,
      null,
      null
    );
  }
  //endregion

  //region DATABASE
  /** Constructs a Position from its dbKey. */
  static fromDbKey(dbKey: PositionDbKey): Position | null {
    const fen = Fen.fromFbString(dbKey);
    if (!fen) return null;
    return Position.fromFen(fen);
  }

  /** Key representation of this position in Firebase. */
  get dbKey(): PositionDbKey {
    return this.getFen().fbString;
  }
  //endregion

  //region MOVE EXECUTION
  /** Returns a new `Position` with the given move executed. */
  executed(move: Move): Position;
  executed(renderedMove: RenderedMove): Position;
  executed(moveOrRenderedMove: Move | RenderedMove): Position {
    const clone = this.clone();
    clone.execute(moveOrRenderedMove);
    return clone;
  }

  /** Executes a given `RenderedMove`. */
  execute(move: Move): void;
  execute(renderedMove: RenderedMove): void;
  execute(moveOrRenderedMove: Move | RenderedMove): void {
    const renderedMove =
      moveOrRenderedMove instanceof Move ? moveOrRenderedMove.renderedMove : moveOrRenderedMove;

    // Execute the move segments
    renderedMove.segments().forEach((segment) => {
      if (segment instanceof MoveSegmentMovement) {
        this.board.move(segment.from, segment.to);
      }

      if (segment instanceof MoveSegmentCapture) {
        this.board.remove(segment.at);
      }

      if (segment instanceof MoveSegmentPromotion) {
        this.board.remove(segment.at);
        this.board.add(segment.promotedTo, segment.at);
      }

      if (segment instanceof MoveSegmentAdjustCastlingRights) {
        this.castlingRights = segment.to;
      }

      if (segment instanceof MoveSegmentAdjustEnPassantTargetSquare) {
        this.enPassantTargetSquare = segment.to;
      }
    });

    // This is deliberately at the bottom to not interfere with the above
    this.sideToMove = this.sideToMove.flipped();
  }

  /** Returns a new `Position` with the given move undone. */
  undone(move: Move): Position;
  undone(renderedMove: RenderedMove): Position;
  undone(moveOrRenderedMove: Move | RenderedMove): Position {
    const clone = this.clone();
    clone.undo(moveOrRenderedMove);
    return clone;
  }

  /** Undoes a move, i.e. reverses the `execute()` function. */
  undo(renderedMove: RenderedMove): void;
  undo(moveOrRenderedMove: Move | RenderedMove): void {
    const renderedMove =
      moveOrRenderedMove instanceof Move ? moveOrRenderedMove.renderedMove : moveOrRenderedMove;

    // Execute the move segments
    renderedMove
      .segments()
      .reverse()
      .forEach((segment) => {
        if (segment instanceof MoveSegmentMovement) {
          this.board.move(segment.to, segment.from);
        }

        if (segment instanceof MoveSegmentCapture) {
          this.board.add(segment.capturedPiece, segment.at);
        }

        if (segment instanceof MoveSegmentPromotion) {
          this.board.remove(segment.at);
          this.board.add(segment.pawn, segment.at);
        }

        if (segment instanceof MoveSegmentAdjustCastlingRights) {
          this.castlingRights = segment.from;
        }

        if (segment instanceof MoveSegmentAdjustEnPassantTargetSquare) {
          this.enPassantTargetSquare = segment.from;
        }
      });

    this.sideToMove = this.sideToMove.flipped();
  }
  //endregion

  //region MOVE RENDERING
  /** Checks if the intended `Movement` is legal. */
  isLegalMoveRequest(moveRequest: Movement): boolean {
    const legalDestinations = this.getLegalDestinationSquares(moveRequest.from);
    return legalDestinations.all().has(moveRequest.to);
  }

  /** Checks if the intended `Movement` will lead to a Pawn promotion. */
  willPromote(movement: Movement): boolean {
    // Check if a Pawn is being moved
    const movingPiece = this.board.get(movement.from);
    if (!movingPiece || movingPiece.type !== PieceType.pawn) {
      return false;
    }

    // Check if the Pawn moves to the opponent's back rank
    const opponentsBackRank = movingPiece.color.player.flipped().backRank;
    if (movement.to.rank !== opponentsBackRank) {
      return false;
    }

    // Check if the move intent is legal
    return this.isLegalMoveRequest(movement);
  }

  /** Checks if the requested `BasicMove` can be satisfied (i.e. is legal) and transforms it into a `RenderedMove`. */
  renderMove(basicMove: BasicMove): RenderedMove | null {
    // Get legal destination squares of the current piece (this will as by-product also check if the Move is legal)
    const legalDestinations = this.getLegalDestinationSquares(basicMove.from);
    if (!legalDestinations.all().has(basicMove.to)) {
      return null;
    }

    // Now that we know that the move is legal, we have to construct the actual RenderedMove
    // Castling
    const castlingSide = legalDestinations.castles().has(basicMove.to)
      ? CastlingSide.fromKingMovement(basicMove.movement, this.sideToMove)
      : null;

    const isEnPassantCapture = legalDestinations.enPassantCaptures.has(basicMove.to);
    const isCapture = isEnPassantCapture || legalDestinations.captures.has(basicMove.to);

    return new RenderedMove(this, basicMove, isCapture, isEnPassantCapture, castlingSide);
  }

  /** Finds the `RenderedMove` which leads from this position to the given position if possible. */
  renderToPosition(nextPosition: Position): RenderedMove | null {
    // The basic idea is to infer the piece movement from the differences in the Positions and then try to render this move proposal to see if we reach the toPosition
    // This way we can keep the logic here simple and leave the complicated evaluations to the render(BasicMove) function.

    // Compare the two Positions
    type Difference = {
      square: Square;
      oldPiece: IdentifiablePiece | null;
      newPiece: IdentifiablePiece | null;
    };
    const differences: Difference[] = [];
    Square.all.forEach((square) => {
      const oldPiece = this.board.get(square);
      const newPiece = nextPosition.board.get(square);
      if (oldPiece?.piece !== newPiece?.piece) {
        // There is a difference
        differences.push({ square, oldPiece, newPiece });
      }
    });

    // We use the number of differences to get a first idea of what's going on
    let proposedBasicMove: BasicMove | null = null;
    switch (differences.length) {
      case 2: {
        // Possible scenarios: quiet move, standard capture, promotion

        // The "from" Square is always the one where the newPiece is null; the "to" Square is always the one where the newPiece is not null
        const from = differences.find((difference) => !difference.newPiece);
        const to = differences.find((difference) => difference.newPiece);
        if (!from || !to) {
          return null;
        }

        // Decide if we deal with a Pawn Promotion
        let pawnPromotion: PawnPromotion | null = null;
        if (
          from.oldPiece?.type === PieceType.pawn &&
          to.square.rank === this.sideToMove.flipped().backRank
        ) {
          pawnPromotion = PawnPromotion.fromPieceType(to.newPiece?.type ?? null);
        }

        proposedBasicMove = new BasicMove(new Movement(from.square, to.square), pawnPromotion);
        break;
      }

      case 3: {
        // This has to be an en passant capture

        // The "to" Square is always the one where the newPiece is not null; the "from" Square is the one where the oldPiece equals to the piece on the to Square.
        const to = differences.find((difference) => difference.newPiece);
        const from = differences.find((difference) =>
          difference.oldPiece?.equals(to?.newPiece ?? null, false)
        );
        if (!from || !to) {
          return null;
        }
        // (We can ignore the third difference)

        proposedBasicMove = new BasicMove(new Movement(from.square, to.square), null);
        break;
      }

      case 4: {
        // This has to be a castling move

        // Get the from and to Squares (= King movement)
        const from = differences.find((difference) => difference.oldPiece?.type === PieceType.king);
        const to = differences.find((difference) => difference.newPiece?.type === PieceType.king);
        if (!from || !to) {
          return null;
        }

        proposedBasicMove = new BasicMove(new Movement(from.square, to.square), null);
        break;
      }

      default:
        // No other move results in this number of differences
        return null;
    }

    // Test if the move proposal is legal and will result in the expected newPosition
    const renderedMove = this.renderMove(proposedBasicMove);
    if (!renderedMove) {
      return null;
    }
    if (!this.executed(renderedMove).equals(nextPosition, false)) {
      return null;
    }

    return renderedMove;
  }

  /**  Checks if the position is legal. */
  isLegal(): boolean {
    // Check if there is exactly one king of each color
    if (
      this.board.find(Piece.whiteKing).size !== 1 ||
      this.board.find(Piece.blackKing).size !== 1
    ) {
      return false;
    }

    // Check if pawns are located on the first and last rank
    for (const pawnSquare of [
      ...Array.from(this.board.find(Piece.whitePawn)),
      ...Array.from(this.board.find(Piece.blackPawn)),
    ]) {
      if (pawnSquare.rank === Player.white.backRank || pawnSquare.rank === Player.black.backRank) {
        return false;
      }
    }

    // Check if the other player is not in check
    const otherKingPosition = this.board.findKing(this.sideToMove.flipped().pieceColor);
    if (this.isAttacked(otherKingPosition, this.sideToMove.flipped(), [], null)) {
      return false;
    }

    // Check if we have to legalize the position (especially the castling rights)
    const clone = this.clone();
    clone.adjustCastlingRights();

    return clone.equals(this, true);
  }

  legalize() {
    this.adjustCastlingRights();

    if (!this.isLegal()) {
      throw new Error(`Position cannot be legalized: '${this.getFen().long}'.`);
    }
  }

  private adjustCastlingRights() {
    const irregularSquares = new Set<Square>();

    // white rooks
    const whiteRooks = this.board.find(Piece.whiteRook);
    if (!whiteRooks.has(Square.a1)) {
      irregularSquares.add(Square.a1);
    }
    if (!whiteRooks.has(Square.h1)) {
      irregularSquares.add(Square.h1);
    }

    // black rooks
    const blackRooks = this.board.find(Piece.blackRook);
    if (!blackRooks.has(Square.a8)) {
      irregularSquares.add(Square.a8);
    }
    if (!blackRooks.has(Square.h8)) {
      irregularSquares.add(Square.h8);
    }

    // Kings
    if (this.board.findKing(PieceColor.white) !== Square.e1) {
      irregularSquares.add(Square.e1);
    }
    if (this.board.findKing(PieceColor.black) !== Square.e8) {
      irregularSquares.add(Square.e8);
    }

    irregularSquares.forEach((square) =>
      this.castlingRights.revokeBecauseOfActivityOnSquare(square)
    );
  }

  /** Checks if the current Player is in check. */
  isInCheck(): boolean {
    const kingPosition = this.board.findKing(this.sideToMove.pieceColor);
    return this.isAttacked(kingPosition, this.sideToMove, [], null);
  }

  /** Checks if the current Player is checkmate. */
  isCheckmate(): boolean {
    return this.isInCheck() && !this.hasLegalMoves();
  }

  /** Checks if the current Player is stalemate. */
  isStalemate(): boolean {
    return !this.isInCheck() && !this.hasLegalMoves();
  }

  /**
   * Determines if a square is attacked by any `Piece` owned by the `defender`'s opponent
   * The combination of `xRaySquare` with `newBlocker` allows the consideration of an in-between move.
   * @param origin Origin
   * @param defender Defender
   * @param xRaySquares This square will be treated as an empty square.
   * @param newBlocker This square will be treated as an own Piece.
   */
  isAttacked(
    origin: Square,
    defender: Player,
    xRaySquares: Square[],
    newBlocker: Square | null = null
  ): boolean {
    // Idea: Position a "super piece" on the square, i.e. a Piece which can move like any other Piece combined.
    // Determine the captures of this super piece -- those are the opponent's Pieces which can potentially reach the origin.
    // Then check if the potential attacking Pieces can actually attack the origin Square.

    const superPieceDestinations = DestinationSquares.fromSuperPiece(
      this,
      origin,
      defender.pieceColor,
      xRaySquares
    );

    for (const superPieceDestination of Array.from(superPieceDestinations.captures)) {
      if (superPieceDestination === newBlocker) {
        // We have captured the possible attacker
        continue;
      }

      const possibleAttacker = this.board.get(superPieceDestination);
      if (!possibleAttacker) throw new Error(`No piece at square '${superPieceDestination}'.`);
      if (
        this.controlledSquares(
          superPieceDestination,
          possibleAttacker.piece,
          xRaySquares,
          newBlocker
        ).has(origin)
      ) {
        return true;
      }
    }

    return false;
  }

  private controlledSquares(
    origin: Square,
    piece: Piece,
    xRaySquares: Square[],
    newBlocker: Square | null
  ): Set<Square> {
    const concatMaps = (set: Set<Square>, ...iterables: Set<Square>[]) => {
      for (const iterable of iterables) {
        for (const item of Array.from(iterable)) {
          set.add(item);
        }
      }
    };

    const getForQueen = (): Set<Square> => {
      const controlledSquares = new Set<Square>();
      concatMaps(controlledSquares, getForRook(), getForBishop());
      return controlledSquares;
    };

    const getForRook = (): Set<Square> => {
      const controlledSquares = new Set<Square>();
      concatMaps(
        controlledSquares,
        getControlledSquares(Direction.up, Repeat.toEdge),
        getControlledSquares(Direction.down, Repeat.toEdge),
        getControlledSquares(Direction.left, Repeat.toEdge),
        getControlledSquares(Direction.right, Repeat.toEdge)
      );
      return controlledSquares;
    };

    const getForBishop = (): Set<Square> => {
      const controlledSquares = new Set<Square>();
      concatMaps(
        controlledSquares,
        getControlledSquares(Direction.upLeft, Repeat.toEdge),
        getControlledSquares(Direction.upRight, Repeat.toEdge),
        getControlledSquares(Direction.downLeft, Repeat.toEdge),
        getControlledSquares(Direction.downRight, Repeat.toEdge)
      );
      return controlledSquares;
    };

    const getForKnight = (): Set<Square> => {
      const controlledSquares = new Set<Square>();
      concatMaps(
        controlledSquares,
        getControlledSquares(Direction.upUpLeft, Repeat.once),
        getControlledSquares(Direction.upUpRight, Repeat.once),
        getControlledSquares(Direction.rightRightUp, Repeat.once),
        getControlledSquares(Direction.rightRightDown, Repeat.once),
        getControlledSquares(Direction.downDownLeft, Repeat.once),
        getControlledSquares(Direction.downDownRight, Repeat.once),
        getControlledSquares(Direction.leftLeftUp, Repeat.once),
        getControlledSquares(Direction.leftLeftDown, Repeat.once)
      );
      return controlledSquares;
    };

    const getForKing = (): Set<Square> => {
      const controlledSquares = new Set<Square>();
      concatMaps(
        controlledSquares,
        getControlledSquares(Direction.up, Repeat.once),
        getControlledSquares(Direction.down, Repeat.once),
        getControlledSquares(Direction.left, Repeat.once),
        getControlledSquares(Direction.right, Repeat.once),
        getControlledSquares(Direction.upLeft, Repeat.once),
        getControlledSquares(Direction.upRight, Repeat.once),
        getControlledSquares(Direction.downLeft, Repeat.once),
        getControlledSquares(Direction.downRight, Repeat.once)
      );
      return controlledSquares;
    };

    const getForPawn = (): Set<Square> => {
      const controlledSquares = new Set<Square>();

      const captureDirections =
        piece.color === PieceColor.white
          ? [Direction.upLeft, Direction.upRight]
          : [Direction.downLeft, Direction.downRight];

      captureDirections.forEach((captureDirection) => {
        concatMaps(controlledSquares, getControlledSquares(captureDirection, Repeat.once));
      });

      return controlledSquares;
    };

    const getControlledSquares = (direction: Direction, repeating: Repeat): Set<Square> => {
      const controlledSquares = new Set<Square>();

      // We do not consider the origin square
      let rank = origin.rank + direction.stepRank;
      let file = origin.file + direction.stepFile;
      let repetitionsLeft = repeating.steps;

      while (repetitionsLeft > 0 && Square.isValid(file, rank)) {
        // The "current" square is considered to be controlled
        const square = Square.ifValid(file, rank);
        if (!square) throw new Error(`No square at '${file}x${rank}'.`);
        controlledSquares.add(square);

        // Break if we reach a new blocker
        if (square == newBlocker) {
          return controlledSquares;
        }

        // Also break if the "current" square is occupied by any piece unless it is the xRaySquare (in ControlledSquares case, we continue)
        if (this.board.get(square) && !xRaySquares.includes(square)) {
          return controlledSquares;
        }

        rank += direction.stepRank;
        file += direction.stepFile;
        repetitionsLeft -= 1;
      }

      return controlledSquares;
    };

    switch (piece.type) {
      case PieceType.rook:
        return getForRook();
      case PieceType.knight:
        return getForKnight();
      case PieceType.bishop:
        return getForBishop();
      case PieceType.queen:
        return getForQueen();
      case PieceType.king:
        return getForKing();
      case PieceType.pawn:
        return getForPawn();
      default:
        throw new Error(`Unknown PieceType: ${piece.type}`);
    }
  }

  /** Returns the castling sides to which the player can actually castle (taking into account the attacked squares). */
  public getCastlingAbilities(player: Player): CastlingSide[] {
    const castlingSides: CastlingSide[] = [];
    if (this.isInCheck()) {
      // castling is only allowed if the king is not in check
      return castlingSides;
    }

    if (this.canCastleKingside(player)) {
      castlingSides.push(CastlingSide.kingside);
    }
    if (this.canCastleQueenside(player)) {
      castlingSides.push(CastlingSide.queenside);
    }

    return castlingSides;
  }

  private canCastleKingside(player: Player): boolean {
    const backRank = player.backRank;
    if (!this.castlingRights.isGrantedForPlayerToSide(player, CastlingSide.kingside)) {
      // no castling rights
      return false;
    }

    const rook = this.board.get(7, backRank);
    if (!rook || rook.color !== player.pieceColor || rook.type !== PieceType.rook) {
      // no rook of expected color at rook square
      return false;
    }

    const passingSquare1 = Square.ifValid(5, backRank);
    const passingSquare2 = Square.ifValid(6, backRank);
    if (!passingSquare1 || !passingSquare2) throw new Error(`Invalid rank: ${backRank}`);

    if (this.board.get(passingSquare1) || this.board.get(passingSquare2)) {
      // no free square to castle over
      return false;
    }

    return !(
      this.isAttacked(passingSquare1, player, [], null) ||
      this.isAttacked(passingSquare2, player, [], null)
    );
  }

  private canCastleQueenside(player: Player): boolean {
    const backRank = player.backRank;
    if (!this.castlingRights.isGrantedForPlayerToSide(player, CastlingSide.queenside)) {
      // no castling rights
      return false;
    }

    const rook = this.board.get(0, backRank);
    if (!rook || rook.color !== player.pieceColor || rook.type !== PieceType.rook) {
      // no rook of expected color at rook square
      return false;
    }

    const passingSquare1 = Square.ifValid(3, backRank);
    const passingSquare2 = Square.ifValid(2, backRank);
    const passingSquare3 = Square.ifValid(1, backRank);
    if (!passingSquare1 || !passingSquare2 || !passingSquare3)
      throw new Error(`Invalid rank: ${backRank}`);

    if (
      this.board.get(passingSquare1) ||
      this.board.get(passingSquare2) ||
      this.board.get(passingSquare3)
    ) {
      // no free square to castle over
      return false;
    }

    return !(
      this.isAttacked(passingSquare1, player, [], null) ||
      this.isAttacked(passingSquare2, player, [], null)
    );
  }
  //endregion

  //region MOVE GENERATION
  /** Returns all legal destination squares for a `Piece` on `origin`. */
  getLegalDestinationSquares(origin: Square): DestinationSquares {
    console.log('GET LEGAL DESTINATION SQUARES');
    const movingIdentifiablePiece = this.board.get(origin);
    if (!movingIdentifiablePiece || movingIdentifiablePiece.color !== this.sideToMove.pieceColor) {
      // We are trying to move no piece or an opponent's Piece
      return DestinationSquares.empty();
    }

    return DestinationSquares.fromPiece(this, origin);
  }

  /** Checks if there are any legal moves in the position. */
  private hasLegalMoves(): boolean {
    // eslint-disable-next-line testing-library/await-async-query
    const squaresWithOurPieces = this.board.findByColor(this.sideToMove.pieceColor);

    for (const squareWithOurPieces of Array.from(squaresWithOurPieces)) {
      if (this.getLegalDestinationSquares(squareWithOurPieces).all().size > 0) {
        return true;
      }
    }
    return false;
  }

  /** Returns all legal moves as `RenderedMoves` in this position.
   *  Note: For Pawn promotions all four possible `RenderedMoves` are returned (Rook, Queen, Bishop, Knight). */
  getLegalMoves(): RenderedMove[] {
    const moves: RenderedMove[] = [];

    // eslint-disable-next-line testing-library/await-async-query
    const squaresWithOurPieces = this.board.findByColor(this.sideToMove.pieceColor);
    squaresWithOurPieces.forEach((squareWithOurPiece) => {
      this.getLegalDestinationSquares(squareWithOurPiece)
        .all()
        .forEach((destinationSquare) => {
          const movement = new Movement(squareWithOurPiece, destinationSquare);
          moves.push(...this.renderIncludingPromotion(movement));
        });
    });

    return moves;
  }

  private renderIncludingPromotion(movement: Movement): RenderedMove[] {
    const moves: RenderedMove[] = [];

    if (this.willPromote(movement)) {
      // Return all possible Pawn promotions
      const queenPromotion = this.renderMove(new BasicMove(movement, PawnPromotion.queen));
      if (queenPromotion) {
        moves.push(queenPromotion);
      }
      const rookPromotion = this.renderMove(new BasicMove(movement, PawnPromotion.rook));
      if (rookPromotion) {
        moves.push(rookPromotion);
      }
      const knightPromotion = this.renderMove(new BasicMove(movement, PawnPromotion.knight));
      if (knightPromotion) {
        moves.push(knightPromotion);
      }
      const bishopPromotion = this.renderMove(new BasicMove(movement, PawnPromotion.bishop));
      if (bishopPromotion) {
        moves.push(bishopPromotion);
      }
    } else {
      const move = this.renderMove(new BasicMove(movement, null));
      if (move) {
        moves.push(move);
      }
    }
    return moves;
  }

  /** Returns all legal moves which end up on the given square.
   *  Note: For Pawn promotions all four possible `RenderedMoves` are returned (Rook, Queen, Bishop, Knight). */
  getLegalMovesToSquare(targetSquare: Square): RenderedMove[] {
    // Idea: Position a "super piece" owned by the opponent on the square, i.e. a Piece which can move like any other Piece combined.
    // Determine the captures of this super piece -- those are our own Pieces which can potentially reach the target.
    // Then check if the potential Pieces can actually reach the target square.

    const superPieceDestinations = DestinationSquares.fromSuperPiece(
      this,
      targetSquare,
      this.sideToMove.flipped().pieceColor,
      []
    );

    const moves: RenderedMove[] = [];

    superPieceDestinations.captures.forEach((superPieceDestination) => {
      const movement = new Movement(superPieceDestination, targetSquare);
      moves.push(...this.renderIncludingPromotion(movement));
    });

    return moves;
  }
  //endregion
}
