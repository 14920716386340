enum UiLanguageType {
  en = 'en',
  en_gb = 'en-GB',
  de = 'de',
}

/**
 * Language for the UI.
 */
export class UiLanguage {
  private constructor(private readonly type: UiLanguageType) {}

  /** (American) English. */
  static en = new UiLanguage(UiLanguageType.en);

  /** British English. */
  static en_gb = new UiLanguage(UiLanguageType.en_gb);

  /** German. */
  static de = new UiLanguage(UiLanguageType.de);

  /**
   * Returns the string representation of the `UiLanguage`.
   */
  toString(): string {
    return this.type;
  }

  /** Returns all supported languages. */
  static all(): UiLanguage[] {
    return [UiLanguage.en, UiLanguage.en_gb, UiLanguage.de];
  }
}
