import {
  getAuth,
  onAuthStateChanged,
  signInWithEmailAndPassword,
  signOut as fbSignOut,
} from 'firebase/auth';
import { useEffect, useState } from 'react';

/** Authentication state. */
export enum AuthState {
  /** Initial state; no details can be given yet. */
  PENDING = 'PENDING',
  /** The user is most likely signed in (from their previous visit) but this is not yet confirmed with the server. */
  ASSUMED_SIGNED_IN = 'ASSUMED_SIGNED_IN',
  /** The user is signed in. */
  SIGNED_IN = 'SIGNED_IN',
  /** The user is signed out. */
  SIGNED_OUT = 'SIGNED_OUT',
}

/**
 * Observes the state of the user’s authentication and maps the internal state to an `AuthState`:
 *  - Initially the state is `PENDING`
 *  - As soon as the server returns a definite signed-in or signed-out state, this state is mapped to an `AuthState`
 *  - While we’re waiting for the server to return a definite state, if the user was signed in from the previous session (logged to local storage), the state is `ASSUMED_SIGNED_IN`.
 * @returns The current authentication state.
 */
export const useAuthState = (): AuthState => {
  const LOCAL_STORAGE_KEY = 'assumed-signed-in';
  const [authState, setAuthState] = useState<AuthState>(AuthState.PENDING);

  if (localStorage.getItem(LOCAL_STORAGE_KEY)) {
    if (authState !== AuthState.ASSUMED_SIGNED_IN && authState !== AuthState.SIGNED_IN) {
      setAuthState(AuthState.ASSUMED_SIGNED_IN);
    }
  }

  useEffect(() => {
    onAuthStateChanged(getAuth(), (fbUser) => {
      if (fbUser) {
        localStorage.setItem(LOCAL_STORAGE_KEY, 'true');
        setAuthState(AuthState.SIGNED_IN);
      } else {
        localStorage.removeItem(LOCAL_STORAGE_KEY);
        setAuthState(AuthState.SIGNED_OUT);
      }
    });
  }, [setAuthState]);

  return authState;
};

/**
 * Signs the user in with the given `email` and `password`.
 * Note that after a successful sign-in, the account information is not updated immediately. Use `useAuthState` instead
 *
 * @param email Email
 * @param password Password
 * @returns A promise which indicates whether the action was successful or not (i.e. can throw an error).
 */
export const signIn = async (email: string, password: string) =>
  signInWithEmailAndPassword(getAuth(), email, password)
    .then(() => Promise.resolve(undefined))
    .catch((error) => {
      throw error;
    });

/**
 * Signs the currently signed-in user out of their account.
 * Note that after a successful sign-out, the account information is not updated immediately. Use `useAuthState` instead
 *
 * @returns A promise which indicates whether the action was successful or not (i.e. can throw an error).
 */
export const signOut = async () =>
  fbSignOut(getAuth())
    .then(() => Promise.resolve(undefined))
    .catch((error) => {
      console.log({ error });
      throw error;
    });
