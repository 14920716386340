enum MoveNotationUiTypeType {
  Algebraic = 'Algebraic',
  FigurineAlgebraic = 'FigurineAlgebraic',
}

export class MoveNotationUiType {
  private constructor(private readonly type: MoveNotationUiTypeType) {}

  //region VALUES
  /** Algebraic notation, e.g. Nxc6 (short) or Nb8xc6 (long). */
  static algebraic = new MoveNotationUiType(MoveNotationUiTypeType.Algebraic);

  /** Figurine (algebraic) notation, e.g. ♞c6 (short) or ♞b8xc6 (long), ♟e4 (short) ♟e2-e4 (long). */
  static figurineAlgebraic = new MoveNotationUiType(MoveNotationUiTypeType.FigurineAlgebraic);
  //endregion

  //region BASICS
  /** String representation. */
  toString(): string {
    return this.type.toString();
  }
  //endregion

  //region DATABASE
  private static dbStringAlgebraic = 'algebraic';
  private static dbStringFigurineAlgebraic = 'figurineAlgebraic';

  get dbString(): string {
    switch (this.type) {
      case MoveNotationUiTypeType.Algebraic:
        return MoveNotationUiType.dbStringAlgebraic;
      case MoveNotationUiTypeType.FigurineAlgebraic:
        return MoveNotationUiType.dbStringFigurineAlgebraic;
      default:
        throw new Error(`Unknown MoveNotationUiTypeType: ${this.type}`);
    }
  }

  static fromDbString(dbString: string): MoveNotationUiType | null {
    switch (dbString) {
      case MoveNotationUiType.dbStringAlgebraic:
        return MoveNotationUiType.algebraic;
      case MoveNotationUiType.dbStringFigurineAlgebraic:
        return MoveNotationUiType.figurineAlgebraic;
      default:
        return null;
    }
  }
  //endregion
}
