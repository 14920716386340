import { AnimatePresence } from 'framer-motion';

import { Square } from '../../../models/common/Square';

import { CurrentDestinationSquareView } from './CurrentDestinationSquareView';

export type CurrentDestinationSquareLayerProps = {
  square: Square | null;
};

export const CurrentDestinationSquareLayer = ({ square }: CurrentDestinationSquareLayerProps) => {
  return (
    <g>
      <AnimatePresence>
        {/* Notice the random key: Every highlight should be unique; there should be no connection between two subsequent highlights on the same square. */}
        {!!square && <CurrentDestinationSquareView key={Math.random()} square={square} />}
      </AnimatePresence>
    </g>
  );
};
