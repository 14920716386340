import { motion } from 'framer-motion';

import piece_highlight from '../../../../../../assets/piece_highlight@ipad_2.png';
import { Square } from '../../../models/common/Square';
import { useChessboardDimensionsContext } from '../ChessboardDimensionsContext';

export type HighlightViewProps = {
  square: Square;
};

export const HighlightView = ({ square }: HighlightViewProps) => {
  const { squareLength, isResizing, getCoordinatesOfSquare } = useChessboardDimensionsContext();
  const { x, y } = getCoordinatesOfSquare(square);

  return (
    <motion.image
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: !isResizing ? 16 / 48 : 0 }}
      key={square.id}
      x={x}
      y={y}
      width={squareLength}
      height={squareLength}
      xlinkHref={piece_highlight}
    />
  );
};
