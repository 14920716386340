import { Square } from '../common/Square';
import { Movement } from '../move/Movement';

import { Player } from './Player';

enum CastlingSideType {
  Kingside = 'Kingside',
  Queenside = 'Queenside',
}

export class CastlingSide {
  private constructor(private readonly type: CastlingSideType) {}

  //region VALUES
  /** Kingside (short) castling. */
  static kingside = new CastlingSide(CastlingSideType.Kingside);

  /** Queenside (long) castling. */
  static queenside = new CastlingSide(CastlingSideType.Queenside);
  //endregion

  //region BASICS
  /** Returns a string representation of the `PieceColor`. */
  toString(): string {
    return this.type;
  }
  //endregion

  //region NOTATION
  /**
   * FIDE representation of the `CastlingSide`.
   * Note: The FIDE standard differs from the PGN standard (`O` vs. `0`)
   */
  get algebraicSymbol() {
    switch (this.type) {
      case CastlingSideType.Kingside:
        return '0-0';
      case CastlingSideType.Queenside:
        return '0-0-0';
      default:
        throw new Error(`Unknown CastlingSide: '${this.type}'.`);
    }
  }

  /**
   * PGN representation of the `CastlingSide`.
   * Note: The FIDE standard differs from the PGN standard (`O` vs. `0`)
   */
  get pgnSymbol() {
    switch (this.type) {
      case CastlingSideType.Kingside:
        return 'O-O';
      case CastlingSideType.Queenside:
        return 'O-O-O';
      default:
        throw new Error(`Unknown CastlingSide: '${this.type}'.`);
    }
  }

  /**
   * Returns the `CastlingSide` of a (FIDE or PGN) castling move representation
   * Note: The FIDE standard differs from the PGN standard (`O` vs. `0`)
   * @returns `null` if the algebraicString is invalid.
   */
  static fromSymbol(symbol: string): CastlingSide | null {
    const tolerantSymbol = symbol.toLocaleUpperCase().replaceAll('O', '0');
    switch (tolerantSymbol) {
      case '0-0':
        return CastlingSide.kingside;
      case '0-0-0':
        return CastlingSide.queenside;
      default:
        return null;
    }
  }

  /**
   * Returns the castling side of a FIDE castling move representation
   * Note: The FIDE standard differs from the PGN standard (`O` vs. `0`)
   */
  static fromString(str: string): CastlingSide | null {
    const tolerantAlgebraicString = str.toLocaleUpperCase().replaceAll('O', '0');

    if (tolerantAlgebraicString.includes('0-0-0')) {
      return CastlingSide.queenside;
    }
    if (tolerantAlgebraicString.includes('0-0')) {
      return CastlingSide.kingside;
    }
    return null;
  }
  //endregion

  //region MOVE RENDERING
  private static movementWhiteKingCastlesKingside = new Movement(Square.e1, Square.g1);
  private static movementWhiteKingCastlesQueenside = new Movement(Square.e1, Square.c1);
  private static movementBlackKingCastlesKingside = new Movement(Square.e8, Square.g8);
  private static movementBlackKingCastlesQueenside = new Movement(Square.e8, Square.c8);

  private static movementWhiteRookCastlesKingside = new Movement(Square.h1, Square.f1);
  private static movementWhiteRookCastlesQueenside = new Movement(Square.a1, Square.d1);
  private static movementBlackRookCastlesKingside = new Movement(Square.h8, Square.f8);
  private static movementBlackRookCastlesQueenside = new Movement(Square.a8, Square.d8);
  /** Returns the `Movement` of the castling Rook given the side to move. */
  getRookMovement(player: Player): Movement {
    switch (player) {
      case Player.white:
        switch (this.type) {
          case CastlingSideType.Kingside:
            return CastlingSide.movementWhiteRookCastlesKingside;
          case CastlingSideType.Queenside:
            return CastlingSide.movementWhiteRookCastlesQueenside;
          default:
            throw new Error(`Unknown CastlingSide: '${this.type}'.`);
        }
      case Player.black:
        switch (this.type) {
          case CastlingSideType.Kingside:
            return CastlingSide.movementBlackRookCastlesKingside;
          case CastlingSideType.Queenside:
            return CastlingSide.movementBlackRookCastlesQueenside;
          default:
            throw new Error(`Unknown CastlingSide: '${this.type}'.`);
        }
      default:
        throw new Error(`Unknown Player: '${player}'.`);
    }
  }

  /** Returns the `Movement` of the castling King given the side to move. */
  getKingMovement(player: Player): Movement {
    switch (player) {
      case Player.white:
        switch (this.type) {
          case CastlingSideType.Kingside:
            return CastlingSide.movementWhiteKingCastlesKingside;
          case CastlingSideType.Queenside:
            return CastlingSide.movementWhiteKingCastlesQueenside;
          default:
            throw new Error(`Unknown CastlingSide: '${this.type}'.`);
        }
      case Player.black:
        switch (this.type) {
          case CastlingSideType.Kingside:
            return CastlingSide.movementBlackKingCastlesKingside;
          case CastlingSideType.Queenside:
            return CastlingSide.movementBlackKingCastlesQueenside;
          default:
            throw new Error(`Unknown CastlingSide: '${this.type}'.`);
        }
      default:
        throw new Error(`Unknown Player: '${player}'.`);
    }
  }

  /** Returns the `CastlingSide` for a presumed King `Movement`. */
  static fromKingMovement(movement: Movement, player: Player): CastlingSide | null {
    switch (player) {
      case Player.white:
        if (movement.equals(CastlingSide.movementWhiteKingCastlesKingside)) {
          return CastlingSide.kingside;
        }
        if (movement.equals(CastlingSide.movementWhiteKingCastlesQueenside)) {
          return CastlingSide.queenside;
        }
        return null;
      case Player.black:
        if (movement.equals(CastlingSide.movementBlackKingCastlesKingside)) {
          return CastlingSide.kingside;
        }
        if (movement.equals(CastlingSide.movementBlackKingCastlesQueenside)) {
          return CastlingSide.queenside;
        }
        return null;
      default:
        return null;
    }
  }
  //endregion
}
