/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { useRef } from 'react';

import { Square } from '../../models/common/Square';
import { Position } from '../../models/position/Position';

import { Chessboard } from './Chessboard';
import { ChessboardDimensionsContextProvider } from './ChessboardDimensionsContext';

export type ChessboardContainerProps = {
  position: Position;
  onMoveRequest: (from: Square, to: Square) => void;
};

const styles = () => {
  return {
    container: css`
      position: absolute;
      width: 100%;
      height: 100%;
    `,
  };
};

export const ChessboardContainer = ({ position, onMoveRequest }: ChessboardContainerProps) => {
  const classes = styles();

  const divRef = useRef<HTMLDivElement>(null);

  return (
    <div css={classes.container} ref={divRef}>
      <ChessboardDimensionsContextProvider containerRef={divRef}>
        <Chessboard position={position} onMoveRequest={onMoveRequest} />
      </ChessboardDimensionsContextProvider>
    </div>
  );
};
