import React from 'react';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import { SignIn } from '../Account/components/SignIn';
import { BackdropLoadingIndicator } from '../components/BackdropLoadingIndicator/BackdropLoadingIndicator';
import { Private } from '../features/private/pages/Private';
import { Opening } from '../features/private/repertoires/opening/components/Opening';
import { Repertoire } from '../features/private/repertoires/repertoire/components/Repertoire';
import { Account } from '../features/public/pages/Account/Account';
import { Contact } from '../features/public/pages/Contact/Contact';
import { Faq } from '../features/public/pages/Faq/Faq';
import { Home } from '../features/public/pages/Home/Home';
import { PageNotFound } from '../features/public/pages/PageNotFound/PageNotFound';
import { PrivacyPolicy } from '../features/public/pages/PrivacyPolicy/PrivacyPolicy';
import { Public } from '../features/public/pages/Public';
import { TermsOfUse } from '../features/public/pages/TermsOfUse/TermsOfUse';
import { AuthState } from '../services/AccountService/AccountService';

import { PrivateRoute } from './PrivateRoute';
import { Route as InternalRoute } from './Routes';

export type RouterWrapperProps = {
  authState: AuthState;
};

export const Router = ({ authState }: RouterWrapperProps) => {
  const location = useLocation();
  return (
    <Routes location={location} key={location.pathname}>
      {/* ‘/repertoires’ → <Private /> with internal routes */}
      <Route path={InternalRoute.Repertoire} element={<PrivateRoute authState={authState} />}>
        <Route path={InternalRoute.Repertoire} element={<Private />}>
          <Route path="" element={<Repertoire />} />
          <Route path="openings" element={<Navigate replace to={'/repertoires'} />} />
          <Route path="openings/:openingId" element={<Opening />} />
        </Route>
      </Route>

      {/* ‘/’ → <pages /> (if not signed in) or ‘/repertoires’ (if signed in) */}
      {authState === AuthState.SIGNED_IN && (
        <Route
          path={InternalRoute.Home}
          element={<Navigate replace to={InternalRoute.Repertoire} />}
        />
      )}

      {/* Temporary secret login. Once this is final, just remove this */}
      {authState === AuthState.SIGNED_OUT && (
        <Route
          path="jYPEZyvvEmCeWccLlyvktQESrLSYSKCdIyvjwsNGMcreJzkypfZFyOcCvIAtUExvpcDBQwiGybqAVbWeNcclzSePtKKUCGqGFnwcSmwWaUvIMtKHVyWccPieZzlylcSjTaAKFPVGicHoSWptCOkJTjrNqMHQakORjqHRZsUarTLYOjTVADDjzLLerxaFYuNAlYLMFSbT"
          element={<SignIn />}
        />
      )}

      {/* TODO: Im Folgen nachdenken, ob das nicht immer gelten soll (also Account usw auch wenn ich eingeloggt bin!) */}
      {authState === AuthState.SIGNED_OUT && (
        <Route path={InternalRoute.Home} element={<Public />}>
          <Route path="" element={<Home />} />
          <Route path="faq" element={<Faq />} />
          <Route path="contact" element={<Contact />} />
          <Route path="privacy-policy" element={<PrivacyPolicy />} />
          <Route path="terms-of-use" element={<TermsOfUse />} />
          <Route path="account" element={<Account />} />

          {/* Language Redirects (until we have the languages sorted out)*/}
          <Route path=":lang" element={<Navigate replace to="/" />} />
          <Route path=":lang/faq" element={<Navigate replace to="/faq" />} />
          <Route path=":lang/contact" element={<Navigate replace to="/contact" />} />
          <Route path=":lang/privacy-policy" element={<Navigate replace to="/privacy-policy" />} />
          <Route path=":lang/terms-of-use" element={<Navigate replace to="/terms-of-use" />} />
          <Route path=":lang/account" element={<Navigate replace to="/account" />} />

          {/* Legacy iOS App Links */}
          <Route path="faq_en" element={<Navigate replace to="/en/faq" />} />
          <Route path="faq_de" element={<Navigate replace to="/de/faq" />} />
          <Route path="privacy-policy-de" element={<Navigate replace to="/de/privacy-policy" />} />
          <Route path="privacy-policy-en" element={<Navigate replace to="/en/privacy-policy" />} />

          {/* 404 */}
          <Route path="*" element={<PageNotFound />} />
        </Route>
      )}

      {(authState === AuthState.PENDING || authState === AuthState.ASSUMED_SIGNED_IN) && (
        <Route path="/" element={<BackdropLoadingIndicator isLoading={true} />} />
      )}
    </Routes>
  );
};
