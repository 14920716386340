import { UiLanguage } from '../common/UiLanguage';

enum MoveAnnotationType {
  Blunder = 'Blunder',
  Mistake = 'Mistake',
  Dubious = 'Dubious',
  None = 'None',
  Interesting = 'Interesting',
  Good = 'Good',
  Brilliant = 'Brilliant',
}

export class MoveAnnotation {
  constructor(private readonly type: MoveAnnotationType) {}

  //region VALUES
  /** A very bad mistake. */
  static blunder = new MoveAnnotation(MoveAnnotationType.Blunder);

  /** A poor move that should not be played. */
  static mistake = new MoveAnnotation(MoveAnnotationType.Mistake);

  /** An objectively bad but hard to refute move. */
  static dubious = new MoveAnnotation(MoveAnnotationType.Dubious);

  /** No annotation. */
  static none = new MoveAnnotation(MoveAnnotationType.None);

  /** An interesting but perhaps not the best move. */
  static interesting = new MoveAnnotation(MoveAnnotationType.Interesting);

  /** A good move. */
  static good = new MoveAnnotation(MoveAnnotationType.Good);

  /** A brilliant move. */
  static brilliant = new MoveAnnotation(MoveAnnotationType.Brilliant);
  //endregion

  //region BASICS
  /** String representation of the promotion type. */
  toString(): string {
    return this.type;
  }

  /** Overrides the default sort order.
   *  Sort by brilliant - good - ... - mistake - blunder */
  valueOf(): number {
    switch (this.type) {
      case MoveAnnotationType.Blunder:
        return -3;
      case MoveAnnotationType.Mistake:
        return -2;
      case MoveAnnotationType.Dubious:
        return -1;
      case MoveAnnotationType.None:
        return 0;
      case MoveAnnotationType.Interesting:
        return 1;
      case MoveAnnotationType.Good:
        return 2;
      case MoveAnnotationType.Brilliant:
        return 3;
      default:
        throw new Error(`Invalid MoveAnnotationType: ${this.type}.`);
    }
  }
  //endregion

  //region NOTATION
  private static notationSymbolBlunder = '??';
  private static notationSymbolMistake = '?';
  private static notationSymbolDubious = '?!';
  private static notationSymbolNone = '';
  private static notationSymbolInteresting = '!?';
  private static notationSymbolGood = '!';
  private static notationSymbolBrilliant = '!!';

  /** Converts a symbol of an annotation to a `MoveAnnotation`.
   *  @returns: `null` if the MoveNotation symbol is invalid. */
  static fromMoveNotationSymbol(notationSymbol: string): MoveAnnotation {
    switch (notationSymbol) {
      case MoveAnnotation.notationSymbolBlunder:
        return MoveAnnotation.blunder;
      case MoveAnnotation.notationSymbolMistake:
        return MoveAnnotation.mistake;
      case MoveAnnotation.notationSymbolDubious:
        return MoveAnnotation.dubious;
      case MoveAnnotation.notationSymbolInteresting:
        return MoveAnnotation.interesting;
      case MoveAnnotation.notationSymbolGood:
        return MoveAnnotation.good;
      case MoveAnnotation.notationSymbolBrilliant:
        return MoveAnnotation.brilliant;
      default:
        return MoveAnnotation.none;
    }
  }

  /** Extracts the annotation symbol of a move notation string and returns the corresponding `MoveAnnotation`.
   *  @returns: `null` if the move notation string is invalid or if no annotation was found. */
  static fromMoveNotationString(notationString: string): MoveAnnotation {
    if (notationString.toLocaleUpperCase().includes(MoveAnnotation.notationSymbolBlunder)) {
      return MoveAnnotation.blunder;
    }
    if (notationString.toLocaleUpperCase().includes(MoveAnnotation.notationSymbolBrilliant)) {
      return MoveAnnotation.brilliant;
    }
    if (notationString.toLocaleUpperCase().includes(MoveAnnotation.notationSymbolDubious)) {
      return MoveAnnotation.dubious;
    }
    if (notationString.toLocaleUpperCase().includes(MoveAnnotation.notationSymbolInteresting)) {
      return MoveAnnotation.interesting;
    }
    if (notationString.toLocaleUpperCase().includes(MoveAnnotation.notationSymbolMistake)) {
      return MoveAnnotation.mistake;
    }
    if (notationString.toLocaleUpperCase().includes(MoveAnnotation.notationSymbolGood)) {
      return MoveAnnotation.good;
    }

    return MoveAnnotation.none;
  }

  /** Notation symbol. */
  get moveNotationSymbol(): string {
    switch (this.type) {
      case MoveAnnotationType.Blunder:
        return MoveAnnotation.notationSymbolBlunder;
      case MoveAnnotationType.Mistake:
        return MoveAnnotation.notationSymbolMistake;
      case MoveAnnotationType.Dubious:
        return MoveAnnotation.notationSymbolDubious;
      case MoveAnnotationType.None:
        return MoveAnnotation.notationSymbolNone;
      case MoveAnnotationType.Interesting:
        return MoveAnnotation.notationSymbolInteresting;
      case MoveAnnotationType.Good:
        return MoveAnnotation.notationSymbolGood;
      case MoveAnnotationType.Brilliant:
        return MoveAnnotation.notationSymbolBrilliant;
      default:
        throw new Error(`Invalid MoveAnnotationType: ${this.type}.`);
    }
  }
  //endregion

  //region DATABASE
  /** Constructs a `MoveAnnotation` from its representation in Firebase. */
  static fromDbSymbol(dbSymbol: string): MoveAnnotation {
    return MoveAnnotation.fromMoveNotationSymbol(dbSymbol);
  }

  /** Firebase representation of the `MoveAnnotation`. */
  get dbSymbol(): string {
    return this.moveNotationSymbol;
  }
  //endregion

  //region TRAINING
  /** Only moves with the following annotations are considered for the training of the owning player:
   *  (none), brilliant, good, interesting
   *  Note: This is only valid for moves of the “owning” player – bad moves of the opponent should still be included in the training.
   *  Note: This only checks if the annotation will immediately exclude the move from training. It does not compare the annotations of different moves and chooses the best. */
  get isExcludedFromOwningPlayersTraining(): boolean {
    return this.valueOf() < 0;
  }
  //endregion

  //region PGN
  private static pgnNagBrilliant = '$3';
  private static pgnNagGood = '$1';
  private static pgnNagInteresting = '$5';
  private static pgnNagDubious = '$6';
  private static pgnNagMistake = '$2';
  private static pgnNagBlunder = '$4';

  /** The “numeric annotation glyph” (NAG) of this annotation. */
  get pgnNag(): string | null {
    switch (this.type) {
      case MoveAnnotationType.Brilliant:
        return MoveAnnotation.pgnNagBrilliant;
      case MoveAnnotationType.Good:
        return MoveAnnotation.pgnNagGood;
      case MoveAnnotationType.Dubious:
        return MoveAnnotation.pgnNagDubious;
      case MoveAnnotationType.Interesting:
        return MoveAnnotation.pgnNagInteresting;
      case MoveAnnotationType.Mistake:
        return MoveAnnotation.pgnNagMistake;
      case MoveAnnotationType.Blunder:
        return MoveAnnotation.pgnNagBlunder;
      default:
        return null;
    }
  }

  /** Initializes a MoveAnnotation from the “numeric annotation glyph” (NAG). */
  static fromPgnNag(pgnNag: string | null): MoveAnnotation | null {
    switch (pgnNag) {
      case MoveAnnotation.pgnNagBrilliant:
        return MoveAnnotation.brilliant;
      case MoveAnnotation.pgnNagGood:
        return MoveAnnotation.good;
      case MoveAnnotation.pgnNagDubious:
        return MoveAnnotation.dubious;
      case MoveAnnotation.pgnNagInteresting:
        return MoveAnnotation.interesting;
      case MoveAnnotation.pgnNagMistake:
        return MoveAnnotation.mistake;
      case MoveAnnotation.pgnNagBlunder:
        return MoveAnnotation.blunder;
      default:
        return null;
    }
  }
  //endregion

  //region API
  /**
   * This function determines the "prevailing" move annotation given the centrally saved annotation and the annotation which should be appended:
   * Is there already a centrally saved annotation other than .none?
   *    (1) yes → this annotation should not be changed
   *    (2) no  → Is there an annotation to append other than .non?
   *         (2a) yes → accept this annotation
   *         (2b) no  → do not change the annotation
   */
  static merge(
    centralAnnotation: MoveAnnotation | null,
    appendingAnnotation: MoveAnnotation | null
  ): MoveAnnotation {
    if (centralAnnotation && centralAnnotation !== MoveAnnotation.none) {
      // (1)
      return centralAnnotation;
    } else {
      // (2)
      if (appendingAnnotation && appendingAnnotation !== MoveAnnotation.none) {
        // (2a)
        return appendingAnnotation;
      } else {
        // (2b)
        return MoveAnnotation.none;
      }
    }
  }
  //endregion

  //region UI
  /** Descriptive localized text, e.g. "interesting". */
  uiText(language: UiLanguage): string {
    switch (language) {
      case UiLanguage.en:
      case UiLanguage.en_gb:
        switch (this.type) {
          case MoveAnnotationType.Brilliant:
            return 'Brilliant move';
          case MoveAnnotationType.Good:
            return 'Good move';
          case MoveAnnotationType.Dubious:
            return 'Dubious move';
          case MoveAnnotationType.Interesting:
            return 'Interesting move';
          case MoveAnnotationType.Mistake:
            return 'Mistake';
          case MoveAnnotationType.Blunder:
            return 'Blunder';
          default:
            return '(none)';
        }

      case UiLanguage.de:
        switch (this.type) {
          case MoveAnnotationType.Brilliant:
            return 'Brillanter Zug';
          case MoveAnnotationType.Good:
            return 'Guter Zug';
          case MoveAnnotationType.Dubious:
            return 'Fragwürdiger Zug';
          case MoveAnnotationType.Interesting:
            return 'Interessanter Zug';
          case MoveAnnotationType.Mistake:
            return 'Schlechter Zug';
          case MoveAnnotationType.Blunder:
            return 'Patzer';
          default:
            return '(ohne)';
        }

      default:
        return this.uiText(UiLanguage.en);
    }
  }
  //endregion
}
