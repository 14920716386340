import { AnimatePresence } from 'framer-motion';

import { Square } from '../../../models/common/Square';
import { IdentifiablePiece } from '../../../models/position/IdentifiablePiece';

import { PieceView } from './PieceView';

export type PieceViewType = {
  identifiablePiece: IdentifiablePiece;
  isDraggable: boolean;
};

export type PieceLayerProps = {
  pieces: Map<Square, PieceViewType>;
  onDragStart: (square: Square) => void;
  onDragEnd: (square: Square) => void;
};

export const PieceLayer = ({ pieces, onDragStart, onDragEnd }: PieceLayerProps) => {
  return (
    <g>
      <AnimatePresence>
        {Array.from(pieces).map(([square, { identifiablePiece, isDraggable }]) => (
          <PieceView
            key={identifiablePiece.id}
            square={square}
            identifiablePiece={identifiablePiece}
            isDraggable={isDraggable}
            onDragStart={() => onDragStart(square)}
            onDragEnd={() => onDragEnd(square)}
          />
        ))}
      </AnimatePresence>
    </g>
  );
};
