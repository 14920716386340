/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { SvgIcon } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import * as React from 'react';

import { AppIcon } from '../../../../../components/AppIcon/AppIcon';
import { Link } from '../../../../../components/Link/Link';
import { Container } from '../Container/Container';

const styles = () => ({
  linkWithMargin: css`
    margin-right: 3rem;
  `,

  toolbarWrapper: css`
    & .MuiToolbar-gutters {
      padding-left: 0;
      padding-right: 0;
    }
  `,

  logoContainer: css`
    display: flex;
    align-items: center;
  `,
});

export const Header = () => {
  const classes = styles();

  return (
    <AppBar position="sticky">
      <Container>
        <nav css={classes.toolbarWrapper}>
          <Toolbar variant="dense">
            <Link to="/" design="static" styles={classes.linkWithMargin}>
              <div css={classes.logoContainer}>
                <SvgIcon fontSize="large" sx={{ mr: 1 }}>
                  <AppIcon />
                </SvgIcon>
                King’s Cross
              </div>
            </Link>

            <Link to="/faq" design="underlinedOnHover" styles={classes.linkWithMargin}>
              FAQ
            </Link>

            <Link to="/contact" design="underlinedOnHover">
              Contact
            </Link>
          </Toolbar>
        </nav>
      </Container>
    </AppBar>
  );
};
