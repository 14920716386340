/** @jsxImportSource @emotion/react */
import { css, SerializedStyles } from '@emotion/react';
import { Theme, useMediaQuery, useTheme } from '@mui/material';
import React, { ReactNode } from 'react';

import { Container } from '../../components/Container/Container';

export type ImageWithTextOrder = 'ImageText' | 'TextImage';

export type BillboardStripeProps = {
  text: ReactNode;
  image: ReactNode;
  order: ImageWithTextOrder;
  styles?: SerializedStyles;
};

const styles = (theme: Theme) => ({
  container: css`
    padding-top: 2rem;
    padding-bottom: 2rem;

    ${theme.breakpoints.up('lg')} {
      padding-top: 4rem;
      padding-bottom: 4rem;
    }
  `,
  billboard: css`
    display: flex;
    flex-direction: column;

    ${theme.breakpoints.up('lg')} {
      flex-direction: row;
    }

    img {
      max-width: 100%;
      max-height: 100%;
    }
  `,

  half: css`
    flex: 1;
    overflow: hidden;
  `,
  firstHalf: css`
    margin-right: 0;
    margin-bottom: 1rem;

    ${theme.breakpoints.up('lg')} {
      margin-right: 2rem;
      margin-bottom: 0;
    }
  `,
  secondHalf: css`
    margin-left: 0;
    margin-top: 1rem;

    ${theme.breakpoints.up('lg')} {
      margin-left: 2rem;
      margin-top: 0;
    }
  `,
  imageContainer: css`
    display: flex;
    justify-content: center;
  `,
});

export const BillboardStripe = ({
  text,
  image,
  order,
  styles: propStyles,
}: BillboardStripeProps) => {
  const theme = useTheme();
  const classes = styles(theme);
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'));

  return (
    <div css={[classes.container, propStyles]}>
      <Container>
        <section css={classes.billboard}>
          {order === 'TextImage' || !isLargeScreen ? (
            <>
              <div css={[classes.half, classes.firstHalf]}>{text}</div>
              <div css={[classes.half, classes.secondHalf, classes.imageContainer]}>{image}</div>
            </>
          ) : (
            <>
              <div css={[classes.half, classes.firstHalf, classes.imageContainer]}>{image}</div>
              <div css={[classes.half, classes.secondHalf]}>{text}</div>
            </>
          )}
        </section>
      </Container>
    </div>
  );
};
