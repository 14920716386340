/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { SvgIcon, Theme, Typography, useTheme } from '@mui/material';
import React from 'react';

import { AppIcon } from '../../../../../components/AppIcon/AppIcon';
import { AppStoreBadge } from '../../../../../components/AppStoreBadge/AppStoreBadge';
import { Link } from '../../../../../components/Link/Link';
import { Container } from '../Container/Container';

const styles = (theme: Theme) => ({
  footer: css`
    color: white;
    background-color: #242428;
    padding-top: 3rem;
    padding-bottom: 3rem;
  `,

  container: css`
    display: flex;
    flex-direction: column;

    ${theme.breakpoints.up('sm')} {
      flex-direction: row;
    }
  `,

  column: css`
    flex: 1;
    display: flex;
    flex-direction: column;

    &:not(:first-of-type) {
      margin-top: 2.5rem;
    }

    &:not(:first-of-type) {
      ${theme.breakpoints.up('sm')} {
        margin-top: 0;
      }
    }
  `,

  columnHeader: css`
    height: 3rem;
  `,

  appIconContainer: css`
    display: flex;
    align-items: center;
  `,

  menuContainer: css`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  `,

  menuItem: css`
    margin-bottom: 0.5rem;
  `,

  appStoreContainer: css`
    width: 8rem;
  `,
});

export const Footer = () => {
  const theme = useTheme();
  const classes = styles(theme);

  return (
    <footer css={classes.footer}>
      <Container styles={classes.container}>
        <div css={classes.column}>
          <div css={classes.columnHeader}>
            <Link to="/" design="static" title="Home">
              <div css={classes.appIconContainer}>
                <SvgIcon fontSize="large" sx={{ mr: 1 }}>
                  <AppIcon />
                </SvgIcon>
                <span>King’s Cross</span>
              </div>
            </Link>
          </div>
          <Typography variant="caption" component="small">
            2017–2022 King’s Cross.
          </Typography>
          <Typography variant="caption" component="small">
            Made with ♥ in the{' '}
            <Link
              href="https://www.mannheim.de"
              target="_blank"
              rel="noreferrer"
              design="underlinedOnHover"
              title="City of Mannheim"
            >
              city of the squares
            </Link>
            .
          </Typography>
        </div>
        <div css={classes.column}>
          <div css={classes.menuContainer}>
            <div css={classes.columnHeader}>
              <Typography variant="overline" component="h2">
                Menu
              </Typography>
            </div>
            <Link to="/faq" variant="caption" design="underlinedOnHover" styles={classes.menuItem}>
              FAQ
            </Link>
            <Link
              to="/contact"
              variant="caption"
              design="underlinedOnHover"
              styles={classes.menuItem}
            >
              Contact
            </Link>
            <Link
              to="/privacy-policy"
              variant="caption"
              design="underlinedOnHover"
              styles={classes.menuItem}
            >
              Privacy Policy
            </Link>
            <Link
              to="/terms-of-use"
              variant="caption"
              design="underlinedOnHover"
              styles={classes.menuItem}
            >
              Terms of Use
            </Link>
          </div>
        </div>
        <div css={classes.column}>
          <div css={classes.columnHeader}>
            <Typography variant="overline" component="h2">
              Get Started
            </Typography>
          </div>
          <div css={classes.appStoreContainer}>
            <AppStoreBadge />
          </div>
        </div>
      </Container>
    </footer>
  );
};
