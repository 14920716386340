import { Typography } from '@mui/material';
import React from 'react';

import { useSetDocumentTitle } from '../../../../hooks/useSetDocumentTitle/useSetDocumentTitle';
import { MainContainer } from '../components/Container/MainContainer';

export const PageNotFound = () => {
  useSetDocumentTitle('Page Not Found | King’s Cross');

  return (
    <MainContainer>
      <article>
        <section>
          <Typography variant="h3" component="h1">
            Sorry, the page you requested cannot be found
          </Typography>
          <Typography variant="body1">
            The URL may be misspelled or the page you’re looking for is no longer available.
          </Typography>
        </section>
      </article>
    </MainContainer>
  );
};
