import { Backdrop, CircularProgress } from '@mui/material';
import React from 'react';

export type BackdropLoadingIndicatorProps = {
  isLoading: boolean;
};

export const BackdropLoadingIndicator = ({ isLoading }: BackdropLoadingIndicatorProps) => {
  return (
    <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isLoading}>
      <CircularProgress color="inherit" />
    </Backdrop>
  );
};
