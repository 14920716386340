/** Shared utility functions for numbers. */
export const NumberUtils = {
  /** Percentage if < 0.99 or = 1.00. For values between 0.99 and 1.00, 0.99 is returned. */
  getCappedPercentage(percentage: number): number {
    if (percentage >= 1) {
      return 1;
    } else if (percentage <= 0) {
      return 0;
    } else if (percentage >= 0.99) {
      return 0.99;
    } else {
      return percentage;
    }
  },
};
