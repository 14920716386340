import { Position } from '../position/Position';
import { PositionDbKey } from '../position/PositionDbKey';

/** Database key of a Move. */
export class MoveDbKey {
  constructor(
    public readonly fromPositionDbKey: PositionDbKey,
    public readonly toPositionDbKey: PositionDbKey
  ) {}

  //region BASICS
  static fromPositions(fromPosition: Position, toPosition: Position): MoveDbKey {
    return new MoveDbKey(fromPosition.dbKey, toPosition.dbKey);
  }

  static fromPositionFenStrings(
    fromPositionFenString: string,
    toPositionFenString: string
  ): MoveDbKey | null {
    const fromPosition = Position.fromFenString(fromPositionFenString);
    const toPosition = Position.fromFenString(toPositionFenString);

    if (!fromPosition || !toPosition) {
      return null;
    }

    return new MoveDbKey(fromPosition.dbKey, toPosition.dbKey);
  }

  /** Checks equality of two `MoveDbKey`s.
   *  Two `MoveDbKey`s are considered equal if they have the same properties. */
  equals(other: MoveDbKey | null): boolean {
    return (
      !!other &&
      this.fromPositionDbKey === other.fromPositionDbKey &&
      this.toPositionDbKey === other.toPositionDbKey
    );
  }
  //endregion

  //region DATABASE
  /** Constructs a `MoveDbKey` from its encoding in Firebase. */
  static fromEncoded(encoded: string): MoveDbKey | null {
    const sections = encoded.split('&');
    if (sections.length !== 2) {
      return null;
    }

    return new MoveDbKey(sections[0], sections[1]);
  }

  /** Encoded representation of a `MoveDbKey` in Firebase. */
  get encoded(): string {
    return this.fromPositionDbKey + '&' + this.toPositionDbKey;
  }
  //endregion
}
