import { OpeningId } from '../../../opening/model/OpeningId';

import {
  OpeningInRepertoireData,
  openingInRepertoireDataFromSnapshot,
} from './OpeningInRepertoireData';
import {
  OpeningInRepertoireLastTraining,
  openingInRepertoireLastTrainingFromSnapshot,
} from './OpeningInRepertoireLastTraining';

export type OpeningInRepertoire = {
  id: OpeningId;
  data: OpeningInRepertoireData;
  hasBookmarks: boolean;
  trainingIsOutdated: boolean;
  lastTraining: OpeningInRepertoireLastTraining | null;
};

/**
 * Creates an `OpeningInRepertoire` from a Firebase snapshot.
 * @throws an error if the id is missing, if the opening color is invalid or the data field is missing.
 */
export const openingInRepertoireFromSnapshot = (
  id: OpeningId,
  snapshot: Record<string, any>
): OpeningInRepertoire => {
  if (!id) throw Error('OpeningInRepertoire.id is missing');

  console.log('openingInRepertoireFromSnapshot');
  // Parse the last training and fall back to null
  let lastTraining: OpeningInRepertoireLastTraining | null = null;
  try {
    lastTraining = openingInRepertoireLastTrainingFromSnapshot(snapshot['lastTraining']);
  } catch (e) {
    console.log('E');
    // keep lastTraining as null
  }

  return {
    id,
    data: openingInRepertoireDataFromSnapshot(snapshot['data']),
    lastTraining,
    hasBookmarks: snapshot['hasBookmarks'] ?? false,
    trainingIsOutdated: snapshot['trainingIsOutdated'] ?? false,
  };
};
