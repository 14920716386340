import { NumberUtils } from '../../../../utils/NumberUtils';

export type CompactTrainingResult = {
  totalNumberUnits: number;
  numberRightUnits: number;
  numberWrongUnits: number;
};

export const getNumberTrainedUnits = (trainingResult: CompactTrainingResult): number =>
  trainingResult.numberRightUnits + trainingResult.numberWrongUnits;

export const getNumberUntrainedUnits = (trainingResult: CompactTrainingResult): number =>
  trainingResult.totalNumberUnits -
  trainingResult.numberRightUnits -
  trainingResult.numberWrongUnits;

export const getPercentTrained = (trainingResult: CompactTrainingResult): number =>
  getNumberTrainedUnits(trainingResult) / trainingResult.totalNumberUnits;

export const getPercentUntrained = (trainingResult: CompactTrainingResult): number =>
  getNumberUntrainedUnits(trainingResult) / trainingResult.totalNumberUnits;

export const getTotalPercentageRight = (trainingResult: CompactTrainingResult): number => {
  if (trainingResult.totalNumberUnits === 0) {
    return 1;
  }

  return NumberUtils.getCappedPercentage(
    trainingResult.numberRightUnits / trainingResult.totalNumberUnits
  );
};

export const getRelativePercentageRight = (trainingResult: CompactTrainingResult): number => {
  if (getNumberTrainedUnits(trainingResult) === 0) {
    return 1;
  }

  return NumberUtils.getCappedPercentage(
    trainingResult.numberRightUnits / getNumberTrainedUnits(trainingResult)
  );
};
