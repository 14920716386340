import { CssBaseline } from '@mui/material';
import { setUseWhatChange } from '@simbathesailor/use-what-changed';
import React from 'react';
import ReactDOM from 'react-dom';

import { App } from './App';
import { AppThemeProvider } from './components/AppThemeProvider/AppThemeProvider';
import { FirebaseProvider } from './services/FirebaseProvider/FirebaseProvider';

setUseWhatChange(process.env.NODE_ENV === 'development');

ReactDOM.render(
  <React.StrictMode>
    <FirebaseProvider>
      <AppThemeProvider>
        <CssBaseline />
        <App />
      </AppThemeProvider>
    </FirebaseProvider>
  </React.StrictMode>,

  document.getElementById('root')
);
