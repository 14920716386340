/** @jsxImportSource @emotion/react */
import { css } from '@emotion/react';
import { Theme, Typography, useTheme } from '@mui/material';
import React from 'react';

import { AppStoreBadge } from '../../../../components/AppStoreBadge/AppStoreBadge';
import { useSetDocumentTitle } from '../../../../hooks/useSetDocumentTitle/useSetDocumentTitle';

import { BillboardStripe } from './BillboardStripe/BillboardStripe';
import { WebP } from './WebP/WebP';

const styles = (theme: Theme) => ({
  mainBillboard: css`
    color: white;
    background-image: url('/assets/public/wood.jpg');
    background-color: #39291e;

    picture {
      display: flex;
    }

    // Media query to gain higher CSS priority
    ${theme.breakpoints.up('xs')} {
      padding-bottom: 0;
    }
  `,

  appStoreContainer: css`
    margin-top: 4rem;
    margin-bottom: 0;
    display: flex;
    justify-content: center;

    ${theme.breakpoints.up('lg')} {
      margin-bottom: 4rem;
    }
  `,

  appStore: css`
    width: 200px;
  `,

  oddBillboard: css`
    background-color: #f3f3f3;
  `,
});

export const Home = () => {
  useSetDocumentTitle('King’s Cross');

  const theme = useTheme();
  const classes = styles(theme);

  return (
    <main>
      <BillboardStripe
        text={
          <>
            <Typography variant="h1">Keep your openings in sync</Typography>
            <Typography variant="body1">
              King’s Cross is the easiest way to keep track of your chess openings: Consolidate your
              repertoire in one place, explore new variations, practice your openings and analyze
              your training over time. King’s Cross helps you gain confidence in your openings.
            </Typography>
            <div css={classes.appStoreContainer}>
              <div css={classes.appStore}>
                <AppStoreBadge />
              </div>
            </div>
          </>
        }
        image={
          <WebP filePrefix="/assets/public/intro" alt={'An iPhone and iPad running King’s Cross'} />
        }
        order="TextImage"
        styles={classes.mainBillboard}
      />

      <BillboardStripe
        text={
          <>
            <Typography variant="h2">Your repertoire at a glance</Typography>
            <Typography variant="body1">
              Consolidate your openings in one place which is synchronized across all your Apple
              devices. King’s Cross makes it easy to keep your variations consistent in all of your
              openings and record transpositions without entering moves twice. The Stockfish engine
              helps you explore critical lines.
            </Typography>
          </>
        }
        image={
          <WebP
            filePrefix="/assets/public/repertoire"
            alt={'Screenshot of King’s Cross showing an opening'}
          />
        }
        order="ImageText"
      />

      <BillboardStripe
        text={
          <>
            <Typography variant="h2">Train your openings</Typography>
            <Typography variant="body1">
              Practice all moves in your variations and improve your opening skills. Analyze your
              training progress over time and gain confidence in your repertoire.
            </Typography>
          </>
        }
        image={
          <WebP
            filePrefix="/assets/public/training"
            alt={'Screenshot of King’s Cross showing the training of an opening'}
          />
        }
        order="TextImage"
        styles={classes.oddBillboard}
      />

      <BillboardStripe
        text={
          <>
            <Typography variant="h2">Access from anywhere</Typography>
            <Typography variant="body1">
              King’s Cross is available on iPhone and iPad and kept in sync across all your devices.
              We store your repertoire securely in he cloud, so you’ll always have a backup but
              we’re not locking you in, you can import and export your openings in PGN format any
              time.
            </Typography>
          </>
        }
        image={
          <WebP
            filePrefix="/assets/public/access"
            alt={'An iPad and iPhone running King’s Cross simultaneously'}
          />
        }
        order="ImageText"
      />
    </main>
  );
};
