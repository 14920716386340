import { Square } from '../common/Square';

/**
 * A piece movement on the chess board.
 *  Note: This does not fully describe every possible move because of the missing Pawn promotion.
 */
export class Movement {
  constructor(public readonly from: Square, public readonly to: Square) {}

  /** Checks equality of two `Movement`s.
   *  Two `Movement`s are considered equal if they have the same from and to squares. */
  equals(other: Movement | null): boolean {
    return !!other && this.from === other.from && this.to === other.to;
  }

  /** Overrides the default sort order.
   *  Sort by source square first, if the source square is the same, sort by the target square. */
  valueOf(): number {
    return this.from.file * 1000 + this.from.rank * 100 + this.to.file * 10 + this.to.rank;
  }
}
