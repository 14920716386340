import { CastlingSide } from './CastlingSide';
import { Player } from './Player';

export class CastlingRight {
  private constructor(readonly player: Player, readonly side: CastlingSide) {}

  //region VALUES
  /** White can castle to the kingside (short). */
  static whiteKingside = new CastlingRight(Player.white, CastlingSide.kingside);

  /** White can castle to the queenside (long). */
  static whiteQueenside = new CastlingRight(Player.white, CastlingSide.queenside);

  /** Black can castle to the kingside (short). */
  static blackKingside = new CastlingRight(Player.black, CastlingSide.kingside);

  /** Black can castle to the queenside (long). */
  static blackQueenside = new CastlingRight(Player.black, CastlingSide.queenside);
  //endregion

  //region BASICS
  toString(): string {
    return this.player.toString() + this.side.toString();
  }
  //endregion

  //region NOTATION
  private static readonly fenSymbolWhiteKingside = 'K';
  private static readonly fenSymbolWhiteQueenside = 'Q';
  private static readonly fenSymbolBlackKingside = 'k';
  private static readonly fenSymbolBlackQueenside = 'q';

  /** FEN representation of the `CastlingRight`. */
  get fenSymbol(): string {
    switch (this) {
      case CastlingRight.whiteKingside:
        return CastlingRight.fenSymbolWhiteKingside;
      case CastlingRight.whiteQueenside:
        return CastlingRight.fenSymbolWhiteQueenside;
      case CastlingRight.blackKingside:
        return CastlingRight.fenSymbolBlackKingside;
      case CastlingRight.blackQueenside:
        return CastlingRight.fenSymbolBlackQueenside;
      default:
        throw new Error(`Unknown CastlingRight: '${this.toString()}'.`);
    }
  }

  /**
   * Returns the `CastlingRight` of a given FEN symbol.
   * @returns `null` if the fenSymbol is invalid.
   */
  static fromFenSymbol(fenSymbol: string): CastlingRight | null {
    switch (fenSymbol) {
      case CastlingRight.fenSymbolWhiteKingside:
        return CastlingRight.whiteKingside;
      case CastlingRight.fenSymbolWhiteQueenside:
        return CastlingRight.whiteQueenside;
      case CastlingRight.fenSymbolBlackKingside:
        return CastlingRight.blackKingside;
      case CastlingRight.fenSymbolBlackQueenside:
        return CastlingRight.blackQueenside;
      default:
        return null;
    }
  }
  //endregion
}
